.slider_image {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .slider_image-stage {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;

        scroll-behavior: smooth;
        scroll-snap-type: inline mandatory;
        -ms-overflow-style: none;
        scrollbar-width: none;


        &::-webkit-scrollbar {
            display: none
        }

        >img {
            scroll-snap-align: start;
            object-fit: cover;
        }

    }

    &:not(.is_full) {
        .slider_image-stage {
            gap: 8px;

            img {
                width: 45%;
                aspect-ratio: 150/204;
                max-height: 350px;
                flex: 0 0 auto;
                border-radius: 12px;

                &:last-of-type {
                    margin-right: 16px;
                }
            }
        }
    }

    &.is_full {
        .slider_image-stage img {
            width: 100%;
            flex: 1 0 100%;
            aspect-ratio: 375/258;
        }

        .slider_image-navigation {

            padding-left: 16px;
        }
    }

    .slider_image-navigation {
        display: flex;
        gap: 8px;

        .navigation_item {
            @extend .bg-green-200;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            transition: .2s all;

            &.is_active {
                @extend .bg-green-500;
                width: 31px;
            }
        }
    }
}