.layout {
    display: grid;
    min-height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: max-content auto;

    grid-template-areas:
        "header"
        "content";

    >.navbar {
        grid-area: navbar;
    }

    >.header {
        grid-area: header;
    }

    >.content {
        grid-area: content;
        background-color: map-get($color-variants, "blue-50");

        &:has(.module) {
            background-color: map-get($color-variants, "neutrals-white");
        }
    }
}