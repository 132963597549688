.accordion {
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: map-get($color-variants, "neutrals-white");
    box-shadow: 0px 20px 50px 0px #0000000d;

    .accordion_item {
        .accordion_btn {
            @extend .text-bd2-rg;
            @extend .color-neutrals-black;
            width: 100%;
            padding: 24px;
            border-bottom: 1px solid map-get($color-variants, "neutrals-lighter");
            display: flex;
            justify-content: space-between;
            align-items: center;

            &::after {
                @extend .icon;
                @extend .icon-24;
                @extend .color-blue-500;
                content: $icon-Chevron--down;
            }
        }

        .accordion_body {
            @extend .text-bd2-rg;
            @extend .color-neutrals-dark;
            margin-inline: 24px;
            padding-block: 16px 48px;

            >* {
                margin: 0;
            }
        }

        .accordion_body-wrapper {
            overflow: hidden;
            height: 0px;
            transition: height 0.2s;
        }

        &.is_active,
        &.is_transitioning {
            background-color: #f6f6f6;

            .accordion_btn {
                border: none;

                &::after {
                    content: $icon-Chevron--up;
                }
            }

            .accordion_body-wrapper {
                height: var(--body-height);

                .accordion_body {
                    border-top: 1px solid map-get($color-variants, "neutrals-lighter");
                }
            }
        }

        &:last-of-type .accordion_btn {
            border: none;
        }
    }
}