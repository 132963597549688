.progress {
    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 6px;
        position: relative;
        border: none;
        cursor: pointer;

        @extend .bg-yellow-100;
        border-radius: 6px;

        &::before {
            content: "";
            @extend .bg-yellow-500;
            position: absolute;
            width: calc(var(--value) * 1%);
            left: 0;
            top: 0;
            bottom: 0;
            border-radius: 6px;
        }

        &:hover::-moz-range-thumb {
            display: block;
        }

        &:hover::-webkit-slider-thumb {
            display: block;
        }

        &::-moz-range-thumb {
            -webkit-appearance: none;
            appearance: none;
            display: none;
            background-color: var(--yellow-500);
            border-radius: 100%;
            width: 16px;
            height: 16px;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            display: none;
            background-color: var(--yellow-500);
            border-radius: 100%;
            width: 16px;
            height: 16px;
        }
    }

    .progress_status {
        flex: 0 0 31px;
        text-align: end;
    }

}