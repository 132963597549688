$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-3d-cursor: unquote('"\\e900"');
$icon-3d-cursor--alt: unquote('"\\e901"');
$icon-3d-curve--auto-colon: unquote('"\\e902"');
$icon-3d-curve--auto-vessels: unquote('"\\e903"');
$icon-3d-curve--manual: unquote('"\\e904"');
$icon-3d-ica: unquote('"\\e905"');
$icon-3d-mpr-toggle: unquote('"\\e906"');
$icon-3d-print-mesh: unquote('"\\e907"');
$icon-3d-software: unquote('"\\e908"');
$icon-3rd-party-connected: unquote('"\\e909"');
$icon-4k: unquote('"\\e90a"');
$icon-4k--filled: unquote('"\\e90b"');
$icon-Accessibility: unquote('"\\e90c"');
$icon-Accessibility--alt: unquote('"\\e90d"');
$icon-Accessibility--color: unquote('"\\e90e"');
$icon-Accessibility--color--filled: unquote('"\\e90f"');
$icon-Account: unquote('"\\e910"');
$icon-Accumulation--ice: unquote('"\\e911"');
$icon-Accumulation--precipitation: unquote('"\\e912"');
$icon-Accumulation--rain: unquote('"\\e913"');
$icon-Accumulation--snow: unquote('"\\e914"');
$icon-Activity: unquote('"\\e915"');
$icon-Add: unquote('"\\e916"');
$icon-Add--alt: unquote('"\\e917"');
$icon-Add-comment: unquote('"\\e918"');
$icon-Add--filled: unquote('"\\e919"');
$icon-Agriculture-analytics: unquote('"\\e91a"');
$icon-Ai-results: unquote('"\\e91b"');
$icon-Ai-results--high: unquote('"\\e91c"');
$icon-Ai-results--low: unquote('"\\e91d"');
$icon-Ai-results--medium: unquote('"\\e91e"');
$icon-Ai-results--urgent: unquote('"\\e91f"');
$icon-Ai-results--very-high: unquote('"\\e920"');
$icon-Airline--digital-gate: unquote('"\\e921"');
$icon-Airline--manage-gates: unquote('"\\e922"');
$icon-Airline--passenger-care: unquote('"\\e923"');
$icon-Airline--rapid-board: unquote('"\\e924"');
$icon-Airplay: unquote('"\\e925"');
$icon-Airplay--filled: unquote('"\\e926"');
$icon-Airport--01: unquote('"\\e927"');
$icon-Airport--02: unquote('"\\e928"');
$icon-Airport-location: unquote('"\\e929"');
$icon-Ai-status: unquote('"\\e92a"');
$icon-Ai-status--complete: unquote('"\\e92b"');
$icon-Ai-status--failed: unquote('"\\e92c"');
$icon-Ai-status--in-progress: unquote('"\\e92d"');
$icon-Ai-status--queued: unquote('"\\e92e"');
$icon-Ai-status--rejected: unquote('"\\e92f"');
$icon-Alarm: unquote('"\\e930"');
$icon-Alarm--add: unquote('"\\e931"');
$icon-Alarm--subtract: unquote('"\\e932"');
$icon-Align-box--bottom-center: unquote('"\\e933"');
$icon-Align-box--bottom-left: unquote('"\\e934"');
$icon-Align-box--bottom-right: unquote('"\\e935"');
$icon-Align-box--middle-center: unquote('"\\e936"');
$icon-Align-box--middle-left: unquote('"\\e937"');
$icon-Align-box--middle-right: unquote('"\\e938"');
$icon-Align-box--top-center: unquote('"\\e939"');
$icon-Align-box--top-left: unquote('"\\e93a"');
$icon-Align-box--top-right: unquote('"\\e93b"');
$icon-Align--horizontal-center: unquote('"\\e93c"');
$icon-Align--horizontal-left: unquote('"\\e93d"');
$icon-Align--horizontal-right: unquote('"\\e93e"');
$icon-Align--vertical-bottom: unquote('"\\e93f"');
$icon-Align--vertical-center: unquote('"\\e940"');
$icon-Align--vertical-top: unquote('"\\e941"');
$icon-Analytics: unquote('"\\e942"');
$icon-Analytics--reference: unquote('"\\e943"');
$icon-Angle: unquote('"\\e944"');
$icon-Annotation-visibility: unquote('"\\e945"');
$icon-Aperture: unquote('"\\e946"');
$icon-Api: unquote('"\\e947"');
$icon-Api--1: unquote('"\\e948"');
$icon-App: unquote('"\\e949"');
$icon-App-connectivity: unquote('"\\e94a"');
$icon-Apple: unquote('"\\e94b"');
$icon-Application: unquote('"\\e94c"');
$icon-Application--mobile: unquote('"\\e94d"');
$icon-Application--virtual: unquote('"\\e94e"');
$icon-Application--web: unquote('"\\e94f"');
$icon-Apps: unquote('"\\e950"');
$icon-Archive: unquote('"\\e951"');
$icon-Area: unquote('"\\e952"');
$icon-Area--custom: unquote('"\\e953"');
$icon-Arrival: unquote('"\\e954"');
$icon-Arrow--down: unquote('"\\e955"');
$icon-Arrow--down-left: unquote('"\\e956"');
$icon-Arrow--down-right: unquote('"\\e957"');
$icon-Arrow--left: unquote('"\\e958"');
$icon-Arrow--right: unquote('"\\e959"');
$icon-Arrow-shift-down: unquote('"\\e95a"');
$icon-Arrows--horizontal: unquote('"\\e95b"');
$icon-Arrows--vertical: unquote('"\\e95c"');
$icon-Arrow--up: unquote('"\\e95d"');
$icon-Arrow--up-left: unquote('"\\e95e"');
$icon-Arrow--up-right: unquote('"\\e95f"');
$icon-Asleep: unquote('"\\e960"');
$icon-Asleep--filled: unquote('"\\e961"');
$icon-Asset: unquote('"\\e962"');
$icon-Asset--confirm: unquote('"\\e963"');
$icon-Asset--digital-twin: unquote('"\\e964"');
$icon-Asset--view: unquote('"\\e965"');
$icon-Asterisk: unquote('"\\e966"');
$icon-At: unquote('"\\e967"');
$icon-Attachment: unquote('"\\e968"');
$icon-Audio-console: unquote('"\\e969"');
$icon-Augmented-reality: unquote('"\\e96a"');
$icon-Automatic: unquote('"\\e96b"');
$icon-Autoscaling: unquote('"\\e96c"');
$icon-Auto-scroll: unquote('"\\e96d"');
$icon-Awake: unquote('"\\e96e"');
$icon-Badge: unquote('"\\e96f"');
$icon-Baggage-claim: unquote('"\\e970"');
$icon-Bar: unquote('"\\e971"');
$icon-Barcode: unquote('"\\e972"');
$icon-Bare-metal-server: unquote('"\\e973"');
$icon-Bare-metal-server--01: unquote('"\\e974"');
$icon-Bare-metal-server--02: unquote('"\\e975"');
$icon-Barrier: unquote('"\\e976"');
$icon-Basketball: unquote('"\\e977"');
$icon-Bastion-host: unquote('"\\e978"');
$icon-Bat: unquote('"\\e979"');
$icon-Battery--charging: unquote('"\\e97a"');
$icon-Battery--empty: unquote('"\\e97b"');
$icon-Battery--full: unquote('"\\e97c"');
$icon-Battery--half: unquote('"\\e97d"');
$icon-Battery--low: unquote('"\\e97e"');
$icon-Battery--quarter: unquote('"\\e97f"');
$icon-Bee: unquote('"\\e980"');
$icon-Bee-bat: unquote('"\\e981"');
$icon-Bicycle: unquote('"\\e982"');
$icon-Binoculars: unquote('"\\e983"');
$icon-Bloch-sphere: unquote('"\\e984"');
$icon-Blockchain: unquote('"\\e985"');
$icon-Block-storage: unquote('"\\e986"');
$icon-Block-storage--alt: unquote('"\\e987"');
$icon-Blog: unquote('"\\e988"');
$icon-Bluetooth: unquote('"\\e989"');
$icon-Bluetooth--off: unquote('"\\e98a"');
$icon-Book: unquote('"\\e98b"');
$icon-Bookmark: unquote('"\\e98c"');
$icon-Bookmark--add: unquote('"\\e98d"');
$icon-Bookmark--filled: unquote('"\\e98e"');
$icon-Boolean: unquote('"\\e98f"');
$icon-Boot: unquote('"\\e990"');
$icon-Border--bottom: unquote('"\\e991"');
$icon-Border--full: unquote('"\\e992"');
$icon-Border--left: unquote('"\\e993"');
$icon-Border--none: unquote('"\\e994"');
$icon-Border--right: unquote('"\\e995"');
$icon-Border--top: unquote('"\\e996"');
$icon-Bot: unquote('"\\e997"');
$icon-Box: unquote('"\\e998"');
$icon-Box--extra-large: unquote('"\\e999"');
$icon-Box--large: unquote('"\\e99a"');
$icon-Box--medium: unquote('"\\e99b"');
$icon-Box-plot: unquote('"\\e99c"');
$icon-Box--small: unquote('"\\e99d"');
$icon-Branch: unquote('"\\e99e"');
$icon-Breaking-change: unquote('"\\e99f"');
$icon-Brightness-contrast: unquote('"\\e9a0"');
$icon-Bring-to-front: unquote('"\\e9a1"');
$icon-Brush-freehand: unquote('"\\e9a2"');
$icon-Brush-polygon: unquote('"\\e9a3"');
$icon-Building: unquote('"\\e9a4"');
$icon-Building--insights-1: unquote('"\\e9a5"');
$icon-Building--insights-2: unquote('"\\e9a6"');
$icon-Building--insights-3: unquote('"\\e9a7"');
$icon-Bullhorn: unquote('"\\e9a8"');
$icon-Buoy: unquote('"\\e9a9"');
$icon-Bus: unquote('"\\e9aa"');
$icon-Cabin-care: unquote('"\\e9ab"');
$icon-Cabin-care--alert: unquote('"\\e9ac"');
$icon-Cabin-care--alt: unquote('"\\e9ad"');
$icon-Cad: unquote('"\\e9ae"');
$icon-Cafe: unquote('"\\e9af"');
$icon-Calculation: unquote('"\\e9b0"');
$icon-Calculation--alt: unquote('"\\e9b1"');
$icon-Calculator: unquote('"\\e9b2"');
$icon-Calculator--check: unquote('"\\e9b3"');
$icon-Calendar: unquote('"\\e9b4"');
$icon-Calendar--heat-map: unquote('"\\e9b5"');
$icon-Calendar--settings: unquote('"\\e9b6"');
$icon-Calendar--tools: unquote('"\\e9b7"');
$icon-Calibrate: unquote('"\\e9b8"');
$icon-Camera: unquote('"\\e9b9"');
$icon-Camera--action: unquote('"\\e9ba"');
$icon-Campsite: unquote('"\\e9bb"');
$icon-Car: unquote('"\\e9bc"');
$icon-Carbon: unquote('"\\e9bd"');
$icon-Carbon-accounting: unquote('"\\e9be"');
$icon-Caret--down: unquote('"\\e9bf"');
$icon-Caret--left: unquote('"\\e9c0"');
$icon-Caret--right: unquote('"\\e9c1"');
$icon-Caret--sort: unquote('"\\e9c2"');
$icon-Caret--sort--down: unquote('"\\e9c3"');
$icon-Caret--sort--up: unquote('"\\e9c4"');
$icon-Caret--up: unquote('"\\e9c5"');
$icon-Car--front: unquote('"\\e9c6"');
$icon-Carousel--horizontal: unquote('"\\e9c7"');
$icon-Carousel--vertical: unquote('"\\e9c8"');
$icon-Catalog: unquote('"\\e9c9"');
$icon-Categories: unquote('"\\e9ca"');
$icon-Category: unquote('"\\e9cb"');
$icon-Category--add: unquote('"\\e9cc"');
$icon-Category--and: unquote('"\\e9cd"');
$icon-Category--new: unquote('"\\e9ce"');
$icon-Category--new-each: unquote('"\\e9cf"');
$icon-Caution: unquote('"\\e9d0"');
$icon-Caution-inverted: unquote('"\\e9d1"');
$icon-Ccx: unquote('"\\e9d2"');
$icon-Cda: unquote('"\\e9d3"');
$icon-Cd--archive: unquote('"\\e9d4"');
$icon-Cd--create-archive: unquote('"\\e9d5"');
$icon-Cd--create-exchange: unquote('"\\e9d6"');
$icon-Cell-tower: unquote('"\\e9d7"');
$icon-Center--circle: unquote('"\\e9d8"');
$icon-Center--square: unquote('"\\e9d9"');
$icon-Center-to-fit: unquote('"\\e9da"');
$icon-Certificate: unquote('"\\e9db"');
$icon-Certificate--check: unquote('"\\e9dc"');
$icon-Change-catalog: unquote('"\\e9dd"');
$icon-Character-patterns: unquote('"\\e9de"');
$icon-Charging-station: unquote('"\\e9df"');
$icon-Charging-station--filled: unquote('"\\e9e0"');
$icon-Chart--3d: unquote('"\\e9e1"');
$icon-Chart--area: unquote('"\\e9e2"');
$icon-Chart--area-smooth: unquote('"\\e9e3"');
$icon-Chart--area-stepper: unquote('"\\e9e4"');
$icon-Chart--average: unquote('"\\e9e5"');
$icon-Chart--bar: unquote('"\\e9e6"');
$icon-Chart--bar-floating: unquote('"\\e9e7"');
$icon-Chart--bar-overlay: unquote('"\\e9e8"');
$icon-Chart--bar-stacked: unquote('"\\e9e9"');
$icon-Chart--bar-target: unquote('"\\e9ea"');
$icon-Chart--bubble: unquote('"\\e9eb"');
$icon-Chart--bubble-packed: unquote('"\\e9ec"');
$icon-Chart--bullet: unquote('"\\e9ed"');
$icon-Chart--candlestick: unquote('"\\e9ee"');
$icon-Chart--cluster-bar: unquote('"\\e9ef"');
$icon-Chart--column: unquote('"\\e9f0"');
$icon-Chart--column-floating: unquote('"\\e9f1"');
$icon-Chart--column-target: unquote('"\\e9f2"');
$icon-Chart--combo: unquote('"\\e9f3"');
$icon-Chart--combo-stacked: unquote('"\\e9f4"');
$icon-Chart--custom: unquote('"\\e9f5"');
$icon-Chart--error-bar: unquote('"\\e9f6"');
$icon-Chart--error-bar--alt: unquote('"\\e9f7"');
$icon-Chart--evaluation: unquote('"\\e9f8"');
$icon-Chart--high-low: unquote('"\\e9f9"');
$icon-Chart--histogram: unquote('"\\e9fa"');
$icon-Chart--line: unquote('"\\e9fb"');
$icon-Chart--line--data: unquote('"\\e9fc"');
$icon-Chart--line-smooth: unquote('"\\e9fd"');
$icon-Chart--marimekko: unquote('"\\e9fe"');
$icon-Chart--maximum: unquote('"\\e9ff"');
$icon-Chart--median: unquote('"\\ea00"');
$icon-Chart--minimum: unquote('"\\ea01"');
$icon-Chart--multi-line: unquote('"\\ea02"');
$icon-Chart--multitype: unquote('"\\ea03"');
$icon-Chart--network: unquote('"\\ea04"');
$icon-Chart--parallel: unquote('"\\ea05"');
$icon-Chart--pie: unquote('"\\ea06"');
$icon-Chart--point: unquote('"\\ea07"');
$icon-Chart--population: unquote('"\\ea08"');
$icon-Chart--radar: unquote('"\\ea09"');
$icon-Chart--radial: unquote('"\\ea0a"');
$icon-Chart--relationship: unquote('"\\ea0b"');
$icon-Chart--ring: unquote('"\\ea0c"');
$icon-Chart--river: unquote('"\\ea0d"');
$icon-Chart--rose: unquote('"\\ea0e"');
$icon-Chart--scatter: unquote('"\\ea0f"');
$icon-Chart--spiral: unquote('"\\ea10"');
$icon-Chart--stacked: unquote('"\\ea11"');
$icon-Chart--stepper: unquote('"\\ea12"');
$icon-Chart--sunburst: unquote('"\\ea13"');
$icon-Chart--treemap: unquote('"\\ea14"');
$icon-Chart--t-sne: unquote('"\\ea15"');
$icon-Chart--venn-diagram: unquote('"\\ea16"');
$icon-Chart--violin-plot: unquote('"\\ea17"');
$icon-Chart--waterfall: unquote('"\\ea18"');
$icon-Chart--win-loss: unquote('"\\ea19"');
$icon-Chat: unquote('"\\ea1a"');
$icon-Chat-bot: unquote('"\\ea1b"');
$icon-Chat--launch: unquote('"\\ea1c"');
$icon-Chat--operational: unquote('"\\ea1d"');
$icon-Checkbox: unquote('"\\ea1e"');
$icon-Checkbox--checked: unquote('"\\ea1f"');
$icon-Checkbox--checked--filled: unquote('"\\ea20"');
$icon-Checkbox--indeterminate: unquote('"\\ea21"');
$icon-Checkbox--indeterminate--filled: unquote('"\\ea22"');
$icon-Checkmark: unquote('"\\ea23"');
$icon-Checkmark--filled: unquote('"\\ea24"');
$icon-Checkmark--filled--error: unquote('"\\ea25"');
$icon-Checkmark--filled--warning: unquote('"\\ea26"');
$icon-Checkmark--outline: unquote('"\\ea27"');
$icon-Checkmark--outline--error: unquote('"\\ea28"');
$icon-Checkmark--outline--warning: unquote('"\\ea29"');
$icon-Chemistry: unquote('"\\ea2a"');
$icon-Chemistry--reference: unquote('"\\ea2b"');
$icon-Chevron--down: unquote('"\\ea2c"');
$icon-Chevron--left: unquote('"\\ea2d"');
$icon-Chevron--mini: unquote('"\\ea2e"');
$icon-Chevron--right: unquote('"\\ea2f"');
$icon-Chevron--sort: unquote('"\\ea30"');
$icon-Chevron--sort--down: unquote('"\\ea31"');
$icon-Chevron--sort--up: unquote('"\\ea32"');
$icon-Chevron--up: unquote('"\\ea33"');
$icon-Chip: unquote('"\\ea34"');
$icon-Choices: unquote('"\\ea35"');
$icon-Choose-item: unquote('"\\ea36"');
$icon-Choropleth-map: unquote('"\\ea37"');
$icon-Circle-dash: unquote('"\\ea38"');
$icon-Circle-fill: unquote('"\\ea39"');
$icon-Circle--filled: unquote('"\\ea3a"');
$icon-Circle-measurement: unquote('"\\ea3b"');
$icon-Circle-packing: unquote('"\\ea3c"');
$icon-Circle-stroke: unquote('"\\ea3d"');
$icon-Circuit-composer: unquote('"\\ea3e"');
$icon-Classification: unquote('"\\ea3f"');
$icon-Classifier--language: unquote('"\\ea40"');
$icon-Clean: unquote('"\\ea41"');
$icon-Close: unquote('"\\ea42"');
$icon-Closed-caption: unquote('"\\ea43"');
$icon-Closed-caption--alt: unquote('"\\ea44"');
$icon-Closed-caption--filled: unquote('"\\ea45"');
$icon-Close--filled: unquote('"\\ea46"');
$icon-Close--outline: unquote('"\\ea47"');
$icon-Cloud: unquote('"\\ea48"');
$icon-Cloud--alerting: unquote('"\\ea49"');
$icon-Cloud-app: unquote('"\\ea4a"');
$icon-Cloud--auditing: unquote('"\\ea4b"');
$icon-Cloud-ceiling: unquote('"\\ea4c"');
$icon-Cloud--data-ops: unquote('"\\ea4d"');
$icon-Cloud--download: unquote('"\\ea4e"');
$icon-Cloud-foundry--1: unquote('"\\ea4f"');
$icon-Cloud-foundry--2: unquote('"\\ea50"');
$icon-Cloud--logging: unquote('"\\ea51"');
$icon-Cloud--monitoring: unquote('"\\ea52"');
$icon-Cloud-registry: unquote('"\\ea53"');
$icon-Cloud-satellite: unquote('"\\ea54"');
$icon-Cloud--service-management: unquote('"\\ea55"');
$icon-Cloud-services: unquote('"\\ea56"');
$icon-Cloud--upload: unquote('"\\ea57"');
$icon-Cloudy: unquote('"\\ea58"');
$icon-Cobb-angle: unquote('"\\ea59"');
$icon-Code: unquote('"\\ea5a"');
$icon-Code--hide: unquote('"\\ea5b"');
$icon-Code--reference: unquote('"\\ea5c"');
$icon-Code-signing-service: unquote('"\\ea5d"');
$icon-Cognitive: unquote('"\\ea5e"');
$icon-Collaborate: unquote('"\\ea5f"');
$icon-Collapse-all: unquote('"\\ea60"');
$icon-Collapse-categories: unquote('"\\ea61"');
$icon-Color-palette: unquote('"\\ea62"');
$icon-Color-switch: unquote('"\\ea63"');
$icon-Column: unquote('"\\ea64"');
$icon-Column--delete: unquote('"\\ea65"');
$icon-Column-dependency: unquote('"\\ea66"');
$icon-Column--insert: unquote('"\\ea67"');
$icon-Commit: unquote('"\\ea68"');
$icon-Communication--unified: unquote('"\\ea69"');
$icon-Compare: unquote('"\\ea6a"');
$icon-Compass: unquote('"\\ea6b"');
$icon-Composer-edit: unquote('"\\ea6c"');
$icon-Concept: unquote('"\\ea6d"');
$icon-Condition--point: unquote('"\\ea6e"');
$icon-Condition--wait-point: unquote('"\\ea6f"');
$icon-Connect: unquote('"\\ea70"');
$icon-Connection--receive: unquote('"\\ea71"');
$icon-Connection--send: unquote('"\\ea72"');
$icon-Connection-signal: unquote('"\\ea73"');
$icon-Connection-signal--off: unquote('"\\ea74"');
$icon-Connection--two-way: unquote('"\\ea75"');
$icon-Connect--recursive: unquote('"\\ea76"');
$icon-Connect--source: unquote('"\\ea77"');
$icon-Connect--target: unquote('"\\ea78"');
$icon-Construction: unquote('"\\ea79"');
$icon-Container-registry: unquote('"\\ea7a"');
$icon-Container-services: unquote('"\\ea7b"');
$icon-Container-software: unquote('"\\ea7c"');
$icon-Content-delivery-network: unquote('"\\ea7d"');
$icon-Content-view: unquote('"\\ea7e"');
$icon-Continue: unquote('"\\ea7f"');
$icon-Continue--filled: unquote('"\\ea80"');
$icon-Contour--draw: unquote('"\\ea81"');
$icon-Contour--edit: unquote('"\\ea82"');
$icon-Contour-finding: unquote('"\\ea83"');
$icon-Contrast: unquote('"\\ea84"');
$icon-Convert-to-cloud: unquote('"\\ea85"');
$icon-Copy: unquote('"\\ea86"');
$icon-Copy--file: unquote('"\\ea87"');
$icon-Copy--link: unquote('"\\ea88"');
$icon-Corn: unquote('"\\ea89"');
$icon-Corner: unquote('"\\ea8a"');
$icon-Coronavirus: unquote('"\\ea8b"');
$icon-Cost: unquote('"\\ea8c"');
$icon-Cost--total: unquote('"\\ea8d"');
$icon-Cough: unquote('"\\ea8e"');
$icon-Course: unquote('"\\ea8f"');
$icon-Covariate: unquote('"\\ea90"');
$icon-Credentials: unquote('"\\ea91"');
$icon-Critical: unquote('"\\ea92"');
$icon-Critical-severity: unquote('"\\ea93"');
$icon-Crop: unquote('"\\ea94"');
$icon-Crop-growth: unquote('"\\ea95"');
$icon-Crop-health: unquote('"\\ea96"');
$icon-Cross-reference: unquote('"\\ea97"');
$icon-Crossroads: unquote('"\\ea98"');
$icon-Cross-tab: unquote('"\\ea99"');
$icon-Crowd-report: unquote('"\\ea9a"');
$icon-Crowd-report--filled: unquote('"\\ea9b"');
$icon-Csv: unquote('"\\ea9c"');
$icon-Cu1: unquote('"\\ea9d"');
$icon-Cu3: unquote('"\\ea9e"');
$icon-Cube: unquote('"\\ea9f"');
$icon-Cube-view: unquote('"\\eaa0"');
$icon-Currency: unquote('"\\eaa1"');
$icon-Currency--baht: unquote('"\\eaa2"');
$icon-Currency--dollar: unquote('"\\eaa3"');
$icon-Currency--euro: unquote('"\\eaa4"');
$icon-Currency--lira: unquote('"\\eaa5"');
$icon-Currency--pound: unquote('"\\eaa6"');
$icon-Currency--ruble: unquote('"\\eaa7"');
$icon-Currency--rupee: unquote('"\\eaa8"');
$icon-Currency--shekel: unquote('"\\eaa9"');
$icon-Currency--won: unquote('"\\eaaa"');
$icon-Currency--yen: unquote('"\\eaab"');
$icon-Cursor--1: unquote('"\\eaac"');
$icon-Cursor--2: unquote('"\\eaad"');
$icon-Cut: unquote('"\\eaae"');
$icon-Cut-in-half: unquote('"\\eaaf"');
$icon-Cut-out: unquote('"\\eab0"');
$icon-Cy: unquote('"\\eab1"');
$icon-Cyclist: unquote('"\\eab2"');
$icon-Cz: unquote('"\\eab3"');
$icon-Dashboard: unquote('"\\eab4"');
$icon-Dashboard--reference: unquote('"\\eab5"');
$icon-Data--1: unquote('"\\eab6"');
$icon-Data--2: unquote('"\\eab7"');
$icon-Data-accessor: unquote('"\\eab8"');
$icon-Data-backup: unquote('"\\eab9"');
$icon-Data--base: unquote('"\\eaba"');
$icon-Data--base--alt: unquote('"\\eabb"');
$icon-Database--datastax: unquote('"\\eabc"');
$icon-Database--elastic: unquote('"\\eabd"');
$icon-Database--enterprise-db2: unquote('"\\eabe"');
$icon-Database--etcd: unquote('"\\eabf"');
$icon-Database--mongodb: unquote('"\\eac0"');
$icon-Database--postgresql: unquote('"\\eac1"');
$icon-Database--rabbit: unquote('"\\eac2"');
$icon-Database--redis: unquote('"\\eac3"');
$icon-Data-bin: unquote('"\\eac4"');
$icon-Data-blob: unquote('"\\eac5"');
$icon-Data--center: unquote('"\\eac6"');
$icon-Data--check: unquote('"\\eac7"');
$icon-Data-class: unquote('"\\eac8"');
$icon-Data-collection: unquote('"\\eac9"');
$icon-Data--connected: unquote('"\\eaca"');
$icon-Data-diode: unquote('"\\eacb"');
$icon-Data--error: unquote('"\\eacc"');
$icon-Data--format: unquote('"\\eacd"');
$icon-Data-player: unquote('"\\eace"');
$icon-Data--reference: unquote('"\\eacf"');
$icon-Data-refinery: unquote('"\\ead0"');
$icon-Data-refinery--reference: unquote('"\\ead1"');
$icon-Data--set: unquote('"\\ead2"');
$icon-Data-share: unquote('"\\ead3"');
$icon-Datastore: unquote('"\\ead4"');
$icon-Data--structured: unquote('"\\ead5"');
$icon-Data-table: unquote('"\\ead6"');
$icon-Data-table--reference: unquote('"\\ead7"');
$icon-Data--unstructured: unquote('"\\ead8"');
$icon-Data--view: unquote('"\\ead9"');
$icon-Data--view--alt: unquote('"\\eada"');
$icon-Data-vis--1: unquote('"\\eadb"');
$icon-Data-vis--2: unquote('"\\eadc"');
$icon-Data-vis--3: unquote('"\\eadd"');
$icon-Data-vis--4: unquote('"\\eade"');
$icon-Debug: unquote('"\\eadf"');
$icon-Decision-tree: unquote('"\\eae0"');
$icon-Delivery: unquote('"\\eae1"');
$icon-Delivery--add: unquote('"\\eae2"');
$icon-Delivery--parcel: unquote('"\\eae3"');
$icon-Delivery-truck: unquote('"\\eae4"');
$icon-Denominate: unquote('"\\eae5"');
$icon-Departure: unquote('"\\eae6"');
$icon-Deploy: unquote('"\\eae7"');
$icon-Deployment-pattern: unquote('"\\eae8"');
$icon-Deployment-policy: unquote('"\\eae9"');
$icon-Deployment-unit--data: unquote('"\\eaea"');
$icon-Deployment-unit--execution: unquote('"\\eaeb"');
$icon-Deployment-unit--installation1: unquote('"\\eaec"');
$icon-Deployment-unit--presentation: unquote('"\\eaed"');
$icon-Deployment-unit--technical--data: unquote('"\\eaee"');
$icon-Deployment-unit--technical--execution: unquote('"\\eaef"');
$icon-Deployment-unit--technical--installation: unquote('"\\eaf0"');
$icon-Deployment-unit--technical--presentation: unquote('"\\eaf1"');
$icon-Deploy-rules: unquote('"\\eaf2"');
$icon-Desk--adjustable: unquote('"\\eaf3"');
$icon-Development: unquote('"\\eaf4"');
$icon-Devices: unquote('"\\eaf5"');
$icon-Dew-point: unquote('"\\eaf6"');
$icon-Dew-point--filled: unquote('"\\eaf7"');
$icon-Diagram: unquote('"\\eaf8"');
$icon-Diagram--reference: unquote('"\\eaf9"');
$icon-Dicom--6000: unquote('"\\eafa"');
$icon-Dicom--overlay: unquote('"\\eafb"');
$icon-Direction--bear-right--01: unquote('"\\eafc"');
$icon-Direction--bear-right--01--filled: unquote('"\\eafd"');
$icon-Direction--bear-right--02: unquote('"\\eafe"');
$icon-Direction--bear-right--02--filled: unquote('"\\eaff"');
$icon-Direction--curve: unquote('"\\eb00"');
$icon-Direction--curve--filled: unquote('"\\eb01"');
$icon-Direction--fork: unquote('"\\eb02"');
$icon-Direction--fork--filled: unquote('"\\eb03"');
$icon-Direction--loop-left: unquote('"\\eb04"');
$icon-Direction--loop-left--filled: unquote('"\\eb05"');
$icon-Direction--loop-right: unquote('"\\eb06"');
$icon-Direction--loop-right--filled: unquote('"\\eb07"');
$icon-Direction--merge: unquote('"\\eb08"');
$icon-Direction--merge--filled: unquote('"\\eb09"');
$icon-Direction--right--01: unquote('"\\eb0a"');
$icon-Direction--right--01--filled: unquote('"\\eb0b"');
$icon-Direction--right--02: unquote('"\\eb0c"');
$icon-Direction--right--02--filled: unquote('"\\eb0d"');
$icon-Direction--rotary--first-right: unquote('"\\eb0e"');
$icon-Direction--rotary--first-right--filled: unquote('"\\eb0f"');
$icon-Direction--rotary--right: unquote('"\\eb10"');
$icon-Direction--rotary--right--filled: unquote('"\\eb11"');
$icon-Direction--rotary--straight: unquote('"\\eb12"');
$icon-Direction--rotary--straight--filled: unquote('"\\eb13"');
$icon-Direction--sharp-turn: unquote('"\\eb14"');
$icon-Direction--sharp-turn--filled: unquote('"\\eb15"');
$icon-Direction--straight: unquote('"\\eb16"');
$icon-Direction--straight--filled: unquote('"\\eb17"');
$icon-Direction--straight--right: unquote('"\\eb18"');
$icon-Direction--straight--right--filled: unquote('"\\eb19"');
$icon-Direction--u-turn: unquote('"\\eb1a"');
$icon-Direction--u-turn--filled: unquote('"\\eb1b"');
$icon-Direct-link: unquote('"\\eb1c"');
$icon-Directory-domain: unquote('"\\eb1d"');
$icon-Distribute--horizontal-center: unquote('"\\eb1e"');
$icon-Distribute--horizontal-left: unquote('"\\eb1f"');
$icon-Distribute--horizontal-right: unquote('"\\eb20"');
$icon-Distribute--vertical-bottom: unquote('"\\eb21"');
$icon-Distribute--vertical-center: unquote('"\\eb22"');
$icon-Distribute--vertical-top: unquote('"\\eb23"');
$icon-Dna: unquote('"\\eb24"');
$icon-Dns-services: unquote('"\\eb25"');
$icon-Doc: unquote('"\\eb26"');
$icon-Document: unquote('"\\eb27"');
$icon-Document--add: unquote('"\\eb28"');
$icon-Document--attachment: unquote('"\\eb29"');
$icon-Document--audio: unquote('"\\eb2a"');
$icon-Document--blank: unquote('"\\eb2b"');
$icon-Document--download: unquote('"\\eb2c"');
$icon-Document--epdf: unquote('"\\eb2d"');
$icon-Document--export: unquote('"\\eb2e"');
$icon-Document--horizontal: unquote('"\\eb2f"');
$icon-Document--import: unquote('"\\eb30"');
$icon-Document--pdf: unquote('"\\eb31"');
$icon-Document--protected: unquote('"\\eb32"');
$icon-Document--security: unquote('"\\eb33"');
$icon-Document-sentiment: unquote('"\\eb34"');
$icon-Document--signed: unquote('"\\eb35"');
$icon-Document--sketch: unquote('"\\eb36"');
$icon-Document--subtract: unquote('"\\eb37"');
$icon-Document--tasks: unquote('"\\eb38"');
$icon-Document--unknown: unquote('"\\eb39"');
$icon-Document--unprotected: unquote('"\\eb3a"');
$icon-Document--vertical: unquote('"\\eb3b"');
$icon-Document--video: unquote('"\\eb3c"');
$icon-Document--view: unquote('"\\eb3d"');
$icon-Document--word-processor: unquote('"\\eb3e"');
$icon-Document--word-processor--reference: unquote('"\\eb3f"');
$icon-Dog-walker: unquote('"\\eb40"');
$icon-Dot-mark: unquote('"\\eb41"');
$icon-Double-integer: unquote('"\\eb42"');
$icon-Download: unquote('"\\eb43"');
$icon-Download-study: unquote('"\\eb44"');
$icon-Down-to-bottom: unquote('"\\eb45"');
$icon-Draggable: unquote('"\\eb46"');
$icon-Drag--horizontal: unquote('"\\eb47"');
$icon-Drag--vertical: unquote('"\\eb48"');
$icon-Draw: unquote('"\\eb49"');
$icon-Drill-back: unquote('"\\eb4a"');
$icon-Drill-down: unquote('"\\eb4b"');
$icon-Drill-through: unquote('"\\eb4c"');
$icon-Driver-analysis: unquote('"\\eb4d"');
$icon-Drone: unquote('"\\eb4e"');
$icon-Drone--delivery: unquote('"\\eb4f"');
$icon-Drone--front: unquote('"\\eb50"');
$icon-Drone--video: unquote('"\\eb51"');
$icon-Drop-photo: unquote('"\\eb52"');
$icon-Drop-photo--filled: unquote('"\\eb53"');
$icon-Drought: unquote('"\\eb54"');
$icon-Dvr: unquote('"\\eb55"');
$icon-Earth: unquote('"\\eb56"');
$icon-Earth--americas: unquote('"\\eb57"');
$icon-Earth--americas--filled: unquote('"\\eb58"');
$icon-Earth--europe-africa: unquote('"\\eb59"');
$icon-Earth--europe-africa--filled: unquote('"\\eb5a"');
$icon-Earth--filled: unquote('"\\eb5b"');
$icon-Earthquake: unquote('"\\eb5c"');
$icon-Earth--southeast-asia: unquote('"\\eb5d"');
$icon-Earth--southeast-asia--filled: unquote('"\\eb5e"');
$icon-Edge-cluster: unquote('"\\eb5f"');
$icon-Edge-device: unquote('"\\eb60"');
$icon-Edge-enhancement: unquote('"\\eb61"');
$icon-Edge-enhancement--01: unquote('"\\eb62"');
$icon-Edge-enhancement--02: unquote('"\\eb63"');
$icon-Edge-enhancement--03: unquote('"\\eb64"');
$icon-Edge-node: unquote('"\\eb65"');
$icon-Edge-node--alt: unquote('"\\eb66"');
$icon-Edge-service: unquote('"\\eb67"');
$icon-Edit: unquote('"\\eb68"');
$icon-Edit--off: unquote('"\\eb69"');
$icon-Edt-loop: unquote('"\\eb6a"');
$icon-Education: unquote('"\\eb6b"');
$icon-Email: unquote('"\\eb6c"');
$icon-Email--new: unquote('"\\eb6d"');
$icon-Encryption: unquote('"\\eb6e"');
$icon-Energy--renewable: unquote('"\\eb6f"');
$icon-Enterprise: unquote('"\\eb70"');
$icon-Equalizer: unquote('"\\eb71"');
$icon-Erase: unquote('"\\eb72"');
$icon-Erase--3d: unquote('"\\eb73"');
$icon-Error: unquote('"\\eb74"');
$icon-Error--filled: unquote('"\\eb75"');
$icon-Error--outline: unquote('"\\eb76"');
$icon-Event: unquote('"\\eb77"');
$icon-Events: unquote('"\\eb78"');
$icon-Events--alt: unquote('"\\eb79"');
$icon-Event--schedule: unquote('"\\eb7a"');
$icon-Exam-mode: unquote('"\\eb7b"');
$icon-Exit: unquote('"\\eb7c"');
$icon-Expand-all: unquote('"\\eb7d"');
$icon-Expand-categories: unquote('"\\eb7e"');
$icon-Explore: unquote('"\\eb7f"');
$icon-Export: unquote('"\\eb80"');
$icon-Eyedropper: unquote('"\\eb81"');
$icon-Face--activated: unquote('"\\eb82"');
$icon-Face--activated--add: unquote('"\\eb83"');
$icon-Face--activated--filled: unquote('"\\eb84"');
$icon-Face--add: unquote('"\\eb85"');
$icon-Face--cool: unquote('"\\eb86"');
$icon-Face--dissatisfied: unquote('"\\eb87"');
$icon-Face--dissatisfied--filled: unquote('"\\eb88"');
$icon-Face--dizzy: unquote('"\\eb89"');
$icon-Face--dizzy--filled: unquote('"\\eb8a"');
$icon-Face--mask: unquote('"\\eb8b"');
$icon-Face--neutral: unquote('"\\eb8c"');
$icon-Face--neutral--filled: unquote('"\\eb8d"');
$icon-Face--pending: unquote('"\\eb8e"');
$icon-Face--pending--filled: unquote('"\\eb8f"');
$icon-Face--satisfied: unquote('"\\eb90"');
$icon-Face--satisfied--filled: unquote('"\\eb91"');
$icon-Face--wink: unquote('"\\eb92"');
$icon-Face--wink--filled: unquote('"\\eb93"');
$icon-Factor: unquote('"\\eb94"');
$icon-Fade: unquote('"\\eb95"');
$icon-Favorite: unquote('"\\eb96"');
$icon-Favorite--filled: unquote('"\\eb97"');
$icon-Favorite--half: unquote('"\\eb98"');
$icon-Fetch-upload: unquote('"\\eb99"');
$icon-Fetch-upload--cloud: unquote('"\\eb9a"');
$icon-File-storage: unquote('"\\eb9b"');
$icon-Filter: unquote('"\\eb9c"');
$icon-Filter--edit: unquote('"\\eb9d"');
$icon-Filter--remove: unquote('"\\eb9e"');
$icon-Filter--reset: unquote('"\\eb9f"');
$icon-Finance: unquote('"\\eba0"');
$icon-Fingerprint-recognition: unquote('"\\eba1"');
$icon-Fire: unquote('"\\eba2"');
$icon-Firewall: unquote('"\\eba3"');
$icon-Firewall--classic: unquote('"\\eba4"');
$icon-Fish: unquote('"\\eba5"');
$icon-Fish--multiple: unquote('"\\eba6"');
$icon-Fit-to-height: unquote('"\\eba7"');
$icon-Fit-to-screen: unquote('"\\eba8"');
$icon-Fit-to-width1: unquote('"\\eba9"');
$icon-Flag: unquote('"\\ebaa"');
$icon-Flag--filled: unquote('"\\ebab"');
$icon-Flagging-taxi: unquote('"\\ebac"');
$icon-Flash: unquote('"\\ebad"');
$icon-Flash--filled: unquote('"\\ebae"');
$icon-Flash--off: unquote('"\\ebaf"');
$icon-Flash--off--filled: unquote('"\\ebb0"');
$icon-Flight--international: unquote('"\\ebb1"');
$icon-Flight--roster: unquote('"\\ebb2"');
$icon-Flight--schedule: unquote('"\\ebb3"');
$icon-Floating-ip: unquote('"\\ebb4"');
$icon-Flood: unquote('"\\ebb5"');
$icon-Flood--warning: unquote('"\\ebb6"');
$icon-Flow: unquote('"\\ebb7"');
$icon-Flow--connection: unquote('"\\ebb8"');
$icon-Flow--data: unquote('"\\ebb9"');
$icon-Flow-logs-vpc: unquote('"\\ebba"');
$icon-Flow--modeler: unquote('"\\ebbb"');
$icon-Flow--stream: unquote('"\\ebbc"');
$icon-Flow--stream--reference: unquote('"\\ebbd"');
$icon-Fog: unquote('"\\ebbe"');
$icon-Folder: unquote('"\\ebbf"');
$icon-Folder--add: unquote('"\\ebc0"');
$icon-Folder--details: unquote('"\\ebc1"');
$icon-Folder--details--reference: unquote('"\\ebc2"');
$icon-Folder--move-to: unquote('"\\ebc3"');
$icon-Folder--off: unquote('"\\ebc4"');
$icon-Folder--open: unquote('"\\ebc5"');
$icon-Folder--parent: unquote('"\\ebc6"');
$icon-Folders: unquote('"\\ebc7"');
$icon-Folder--shared: unquote('"\\ebc8"');
$icon-Forecast--hail: unquote('"\\ebc9"');
$icon-Forecast--hail-30: unquote('"\\ebca"');
$icon-Forecast--lightning: unquote('"\\ebcb"');
$icon-Forecast--lightning-30: unquote('"\\ebcc"');
$icon-Fork: unquote('"\\ebcd"');
$icon-Forum: unquote('"\\ebce"');
$icon-Forward--5: unquote('"\\ebcf"');
$icon-Forward--10: unquote('"\\ebd0"');
$icon-Forward--30: unquote('"\\ebd1"');
$icon-Fragile: unquote('"\\ebd2"');
$icon-Friendship: unquote('"\\ebd3"');
$icon-Fruit-bowl: unquote('"\\ebd4"');
$icon-Function: unquote('"\\ebd5"');
$icon-Function-math: unquote('"\\ebd6"');
$icon-Fusion-blender: unquote('"\\ebd7"');
$icon-Game--console: unquote('"\\ebd8"');
$icon-Game--wireless: unquote('"\\ebd9"');
$icon-Gamification: unquote('"\\ebda"');
$icon-Gas-station: unquote('"\\ebdb"');
$icon-Gas-station--filled: unquote('"\\ebdc"');
$icon-Gateway: unquote('"\\ebdd"');
$icon-Gateway--api: unquote('"\\ebde"');
$icon-Gateway--mail: unquote('"\\ebdf"');
$icon-Gateway--public: unquote('"\\ebe0"');
$icon-Gateway--security: unquote('"\\ebe1"');
$icon-Gateway--user-access: unquote('"\\ebe2"');
$icon-Gateway--vpn: unquote('"\\ebe3"');
$icon-Gender--female: unquote('"\\ebe4"');
$icon-Gender--male: unquote('"\\ebe5"');
$icon-Generate-pdf: unquote('"\\ebe6"');
$icon-Gif: unquote('"\\ebe7"');
$icon-Gift: unquote('"\\ebe8"');
$icon-Globe: unquote('"\\ebe9"');
$icon-Gradient: unquote('"\\ebea"');
$icon-Graphical-data-flow: unquote('"\\ebeb"');
$icon-Grid: unquote('"\\ebec"');
$icon-Group: unquote('"\\ebed"');
$icon-Group--access: unquote('"\\ebee"');
$icon-Group--account: unquote('"\\ebef"');
$icon-Group-objects: unquote('"\\ebf0"');
$icon-Group-objects--new: unquote('"\\ebf1"');
$icon-Group-objects--save: unquote('"\\ebf2"');
$icon-Group--presentation: unquote('"\\ebf3"');
$icon-Group--resource: unquote('"\\ebf4"');
$icon-Group--security: unquote('"\\ebf5"');
$icon-Growth: unquote('"\\ebf6"');
$icon-Gui: unquote('"\\ebf7"');
$icon-Gui--management: unquote('"\\ebf8"');
$icon-H: unquote('"\\ebf9"');
$icon-Hail: unquote('"\\ebfa"');
$icon-Hanging-protocol: unquote('"\\ebfb"');
$icon-Harbor: unquote('"\\ebfc"');
$icon-Hardware-security-module: unquote('"\\ebfd"');
$icon-Hashtag: unquote('"\\ebfe"');
$icon-Haze: unquote('"\\ebff"');
$icon-Haze--night: unquote('"\\ec00"');
$icon-Hd: unquote('"\\ec01"');
$icon-Hd--filled: unquote('"\\ec02"');
$icon-Hdr: unquote('"\\ec03"');
$icon-Headphones: unquote('"\\ec04"');
$icon-Headset: unquote('"\\ec05"');
$icon-Health-cross: unquote('"\\ec06"');
$icon-Hearing: unquote('"\\ec07"');
$icon-Heat-map: unquote('"\\ec08"');
$icon-Heat-map--02: unquote('"\\ec09"');
$icon-Heat-map--03: unquote('"\\ec0a"');
$icon-Heat-map--stocks: unquote('"\\ec0b"');
$icon-Helicopter: unquote('"\\ec0c"');
$icon-Help: unquote('"\\ec0d"');
$icon-Help-desk: unquote('"\\ec0e"');
$icon-Help--filled: unquote('"\\ec0f"');
$icon-Hinton-plot: unquote('"\\ec10"');
$icon-Hole-filling: unquote('"\\ec11"');
$icon-Hole-filling--cursor: unquote('"\\ec12"');
$icon-Home: unquote('"\\ec13"');
$icon-Hospital: unquote('"\\ec14"');
$icon-Hospital-bed: unquote('"\\ec15"');
$icon-Hotel: unquote('"\\ec16"');
$icon-Hourglass: unquote('"\\ec17"');
$icon-Html: unquote('"\\ec18"');
$icon-Html--reference: unquote('"\\ec19"');
$icon-Http: unquote('"\\ec1a"');
$icon-Humidity: unquote('"\\ec1b"');
$icon-Humidity--alt: unquote('"\\ec1c"');
$icon-Hurricane: unquote('"\\ec1d"');
$icon-Hybrid-networking: unquote('"\\ec1e"');
$icon-Hybrid-networking--alt: unquote('"\\ec1f"');
$icon-IBM-cloud: unquote('"\\ec20"');
$icon-IBM-cloud--dedicated-host: unquote('"\\ec21"');
$icon-IBM-cloud--internet-services: unquote('"\\ec22"');
$icon-IBM-cloud-pak--applications: unquote('"\\ec23"');
$icon-IBM-cloud-pak--data: unquote('"\\ec24"');
$icon-IBM-cloud-pak--integration: unquote('"\\ec25"');
$icon-IBM-cloud-pak--multicloud-mgmt: unquote('"\\ec26"');
$icon-IBM-cloud-pak--security: unquote('"\\ec27"');
$icon-IBM-cloud-pak--system: unquote('"\\ec28"');
$icon-IBM-cloud--subnets: unquote('"\\ec29"');
$icon-IBM-cloud--vpc-endpoints: unquote('"\\ec2a"');
$icon-IBM-security: unquote('"\\ec2b"');
$icon-IBM-security--services: unquote('"\\ec2c"');
$icon-Ica-2d: unquote('"\\ec2d"');
$icon-Ice--accretion: unquote('"\\ec2e"');
$icon-Ice--vision: unquote('"\\ec2f"');
$icon-Id: unquote('"\\ec30"');
$icon-Idea: unquote('"\\ec31"');
$icon-Identification: unquote('"\\ec32"');
$icon-Id-management: unquote('"\\ec33"');
$icon-Image: unquote('"\\ec34"');
$icon-Image--copy: unquote('"\\ec35"');
$icon-Image--medical: unquote('"\\ec36"');
$icon-Image--reference: unquote('"\\ec37"');
$icon-Image--search: unquote('"\\ec38"');
$icon-Image--search--alt: unquote('"\\ec39"');
$icon-Image-service: unquote('"\\ec3a"');
$icon-Improve-relevance: unquote('"\\ec3b"');
$icon-Incomplete: unquote('"\\ec3c"');
$icon-Incomplete--cancel: unquote('"\\ec3d"');
$icon-Incomplete--error: unquote('"\\ec3e"');
$icon-Incomplete--warning: unquote('"\\ec3f"');
$icon-Increase-level: unquote('"\\ec40"');
$icon-Industry: unquote('"\\ec41"');
$icon-Information: unquote('"\\ec42"');
$icon-Information--disabled: unquote('"\\ec43"');
$icon-Information--filled: unquote('"\\ec44"');
$icon-Information--square: unquote('"\\ec45"');
$icon-Information--square--filled: unquote('"\\ec46"');
$icon-Infrastructure--classic: unquote('"\\ec47"');
$icon-In-progress: unquote('"\\ec48"');
$icon-In-progress--error: unquote('"\\ec49"');
$icon-In-progress--warning: unquote('"\\ec4a"');
$icon-Insert: unquote('"\\ec4b"');
$icon-Insert--page: unquote('"\\ec4c"');
$icon-Insert-syntax: unquote('"\\ec4d"');
$icon-Inspection: unquote('"\\ec4e"');
$icon-Instance--bx: unquote('"\\ec4f"');
$icon-Instance--classic: unquote('"\\ec50"');
$icon-Instance--cx: unquote('"\\ec51"');
$icon-Instance--mx: unquote('"\\ec52"');
$icon-Instance--virtual: unquote('"\\ec53"');
$icon-Integration: unquote('"\\ec54"');
$icon-Interactions: unquote('"\\ec55"');
$icon-Interactive-segmentation-cursor: unquote('"\\ec56"');
$icon-Intersect: unquote('"\\ec57"');
$icon-Intrusion-prevention: unquote('"\\ec58"');
$icon-Inventory-management: unquote('"\\ec59"');
$icon-Iot--connect: unquote('"\\ec5a"');
$icon-Iot--platform: unquote('"\\ec5b"');
$icon-Iso: unquote('"\\ec5c"');
$icon-Iso--filled: unquote('"\\ec5d"');
$icon-Iso--outline: unquote('"\\ec5e"');
$icon-Join--full: unquote('"\\ec5f"');
$icon-Join--inner: unquote('"\\ec60"');
$icon-Join--left: unquote('"\\ec61"');
$icon-Join--outer: unquote('"\\ec62"');
$icon-Join--right: unquote('"\\ec63"');
$icon-Jpg: unquote('"\\ec64"');
$icon-Json: unquote('"\\ec65"');
$icon-Json--reference: unquote('"\\ec66"');
$icon-Jump-link: unquote('"\\ec67"');
$icon-Keep-dry: unquote('"\\ec68"');
$icon-Keyboard: unquote('"\\ec69"');
$icon-Kubernetes: unquote('"\\ec6a"');
$icon-Label: unquote('"\\ec6b"');
$icon-Language: unquote('"\\ec6c"');
$icon-Laptop: unquote('"\\ec6d"');
$icon-Lasso: unquote('"\\ec6e"');
$icon-Lasso--polygon: unquote('"\\ec6f"');
$icon-Launch: unquote('"\\ec70"');
$icon-Launch-study--1: unquote('"\\ec71"');
$icon-Launch-study--2: unquote('"\\ec72"');
$icon-Launch-study--3: unquote('"\\ec73"');
$icon-Layers: unquote('"\\ec74"');
$icon-Legend: unquote('"\\ec75"');
$icon-Letter--aa: unquote('"\\ec76"');
$icon-Letter--bb: unquote('"\\ec77"');
$icon-Letter--cc: unquote('"\\ec78"');
$icon-Letter--dd: unquote('"\\ec79"');
$icon-Letter--ee: unquote('"\\ec7a"');
$icon-Letter--ff: unquote('"\\ec7b"');
$icon-Letter--gg: unquote('"\\ec7c"');
$icon-Letter--hh: unquote('"\\ec7d"');
$icon-Letter--ii: unquote('"\\ec7e"');
$icon-Letter--jj: unquote('"\\ec7f"');
$icon-Letter--kk: unquote('"\\ec80"');
$icon-Letter--ll: unquote('"\\ec81"');
$icon-Letter--mm: unquote('"\\ec82"');
$icon-Letter--nn: unquote('"\\ec83"');
$icon-Letter--oo: unquote('"\\ec84"');
$icon-Letter--pp: unquote('"\\ec85"');
$icon-Letter--qq: unquote('"\\ec86"');
$icon-Letter--rr: unquote('"\\ec87"');
$icon-Letter--ss: unquote('"\\ec88"');
$icon-Letter--tt: unquote('"\\ec89"');
$icon-Letter--uu: unquote('"\\ec8a"');
$icon-Letter--vv: unquote('"\\ec8b"');
$icon-Letter--ww: unquote('"\\ec8c"');
$icon-Letter--xx: unquote('"\\ec8d"');
$icon-Letter--yy: unquote('"\\ec8e"');
$icon-Letter--zz: unquote('"\\ec8f"');
$icon-License: unquote('"\\ec90"');
$icon-License--draft: unquote('"\\ec91"');
$icon-License--global: unquote('"\\ec92"');
$icon-License--maintenance: unquote('"\\ec93"');
$icon-License--maintenance-draft: unquote('"\\ec94"');
$icon-License--third-party: unquote('"\\ec95"');
$icon-License--third-party-draft: unquote('"\\ec96"');
$icon-Lifesaver: unquote('"\\ec97"');
$icon-Light: unquote('"\\ec98"');
$icon-Light--filled: unquote('"\\ec99"');
$icon-Lightning: unquote('"\\ec9a"');
$icon-Link: unquote('"\\ec9b"');
$icon-Linux: unquote('"\\ec9c"');
$icon-Linux--alt: unquote('"\\ec9d"');
$icon-List: unquote('"\\ec9e"');
$icon-List--boxes: unquote('"\\ec9f"');
$icon-List--bulleted: unquote('"\\eca0"');
$icon-List--checked: unquote('"\\eca1"');
$icon-List--dropdown: unquote('"\\eca2"');
$icon-List--numbered: unquote('"\\eca3"');
$icon-Load-balancer--application: unquote('"\\eca4"');
$icon-Load-balancer--classic: unquote('"\\eca5"');
$icon-Load-balancer--global: unquote('"\\eca6"');
$icon-Load-balancer--listener: unquote('"\\eca7"');
$icon-Load-balancer--local: unquote('"\\eca8"');
$icon-Load-balancer--network: unquote('"\\eca9"');
$icon-Load-balancer--pool: unquote('"\\ecaa"');
$icon-Load-balancer--vpc: unquote('"\\ecab"');
$icon-Location: unquote('"\\ecac"');
$icon-Location--company: unquote('"\\ecad"');
$icon-Location--company--filled: unquote('"\\ecae"');
$icon-Location--current: unquote('"\\ecaf"');
$icon-Location--filled: unquote('"\\ecb0"');
$icon-Location--hazard: unquote('"\\ecb1"');
$icon-Location--hazard--filled: unquote('"\\ecb2"');
$icon-Location--heart: unquote('"\\ecb3"');
$icon-Location--heart--filled: unquote('"\\ecb4"');
$icon-Location--person: unquote('"\\ecb5"');
$icon-Location--person--filled: unquote('"\\ecb6"');
$icon-Location--save: unquote('"\\ecb7"');
$icon-Location--star: unquote('"\\ecb8"');
$icon-Location--star--filled: unquote('"\\ecb9"');
$icon-Locked: unquote('"\\ecba"');
$icon-Login: unquote('"\\ecbb"');
$icon-Logo--digg: unquote('"\\ecbc"');
$icon-Logo--discord: unquote('"\\ecbd"');
$icon-Logo--facebook: unquote('"\\ecbe"');
$icon-Logo--flickr: unquote('"\\ecbf"');
$icon-Logo--github: unquote('"\\ecc0"');
$icon-Logo--glassdoor: unquote('"\\ecc1"');
$icon-Logo--instagram: unquote('"\\ecc2"');
$icon-Logo--jupyter: unquote('"\\ecc3"');
$icon-Logo--keybase: unquote('"\\ecc4"');
$icon-Logo--linkedin: unquote('"\\ecc5"');
$icon-Logo--livestream: unquote('"\\ecc6"');
$icon-Logo--medium: unquote('"\\ecc7"');
$icon-Logo--openshift: unquote('"\\ecc8"');
$icon-Logo--pinterest: unquote('"\\ecc9"');
$icon-Logo--python: unquote('"\\ecca"');
$icon-Logo--quora: unquote('"\\eccb"');
$icon-Logo--r-script: unquote('"\\eccc"');
$icon-Logo--skype: unquote('"\\eccd"');
$icon-Logo--slack: unquote('"\\ecce"');
$icon-Logo--snapchat: unquote('"\\eccf"');
$icon-Logo--tumblr: unquote('"\\ecd0"');
$icon-Logo--twitter: unquote('"\\ecd1"');
$icon-Logout: unquote('"\\ecd2"');
$icon-Logo--vmware: unquote('"\\ecd3"');
$icon-Logo--wechat: unquote('"\\ecd4"');
$icon-Logo--xing: unquote('"\\ecd5"');
$icon-Logo--yelp: unquote('"\\ecd6"');
$icon-Logo--youtube: unquote('"\\ecd7"');
$icon-Loop: unquote('"\\ecd8"');
$icon-Low-severity: unquote('"\\ecd9"');
$icon-Mac--command: unquote('"\\ecda"');
$icon-Machine-learning: unquote('"\\ecdb"');
$icon-Machine-learning-model: unquote('"\\ecdc"');
$icon-Mac--option: unquote('"\\ecdd"');
$icon-Mac--shift: unquote('"\\ecde"');
$icon-Magic-wand: unquote('"\\ecdf"');
$icon-Magic-wand--filled: unquote('"\\ece0"');
$icon-Magnify: unquote('"\\ece1"');
$icon-Mail--all: unquote('"\\ece2"');
$icon-Mail--reply: unquote('"\\ece3"');
$icon-Mammogram: unquote('"\\ece4"');
$icon-Managed-solutions: unquote('"\\ece5"');
$icon-Manage-protection: unquote('"\\ece6"');
$icon-Map: unquote('"\\ece7"');
$icon-Map-boundary: unquote('"\\ece8"');
$icon-Map--center: unquote('"\\ece9"');
$icon-Map--identify: unquote('"\\ecea"');
$icon-Marine-warning: unquote('"\\eceb"');
$icon-Math-curve: unquote('"\\ecec"');
$icon-Matrix: unquote('"\\eced"');
$icon-Maximize: unquote('"\\ecee"');
$icon-Media-cast: unquote('"\\ecef"');
$icon-Media--library: unquote('"\\ecf0"');
$icon-Media--library--filled: unquote('"\\ecf1"');
$icon-Medication: unquote('"\\ecf2"');
$icon-Medication--alert: unquote('"\\ecf3"');
$icon-Medication--reminder: unquote('"\\ecf4"');
$icon-Menu: unquote('"\\ecf5"');
$icon-Message-queue: unquote('"\\ecf6"');
$icon-Meter: unquote('"\\ecf7"');
$icon-Meter--alt: unquote('"\\ecf8"');
$icon-Microphone: unquote('"\\ecf9"');
$icon-Microphone--filled: unquote('"\\ecfa"');
$icon-Microphone--off: unquote('"\\ecfb"');
$icon-Microphone--off--filled: unquote('"\\ecfc"');
$icon-Microscope: unquote('"\\ecfd"');
$icon-Migrate: unquote('"\\ecfe"');
$icon-Migrate--alt: unquote('"\\ecff"');
$icon-Milestone: unquote('"\\ed00"');
$icon-Military-camp: unquote('"\\ed01"');
$icon-Minimize: unquote('"\\ed02"');
$icon-Misuse: unquote('"\\ed03"');
$icon-Misuse--outline: unquote('"\\ed04"');
$icon-Mixed-rain-hail: unquote('"\\ed05"');
$icon-Mobile: unquote('"\\ed06"');
$icon-Mobile--add: unquote('"\\ed07"');
$icon-Mobile--audio: unquote('"\\ed08"');
$icon-Mobile--check: unquote('"\\ed09"');
$icon-Mobile--download: unquote('"\\ed0a"');
$icon-Mobile--landscape: unquote('"\\ed0b"');
$icon-Mobility--services: unquote('"\\ed0c"');
$icon-Model: unquote('"\\ed0d"');
$icon-Model--alt: unquote('"\\ed0e"');
$icon-Model-builder: unquote('"\\ed0f"');
$icon-Model-builder--reference: unquote('"\\ed10"');
$icon-Model--reference: unquote('"\\ed11"');
$icon-Money: unquote('"\\ed12"');
$icon-Monster: unquote('"\\ed13"');
$icon-Monument: unquote('"\\ed14"');
$icon-Moon: unquote('"\\ed15"');
$icon-Moonrise: unquote('"\\ed16"');
$icon-Moonset: unquote('"\\ed17"');
$icon-Mostly-cloudy: unquote('"\\ed18"');
$icon-Mostly-cloudy--night: unquote('"\\ed19"');
$icon-Mountain: unquote('"\\ed1a"');
$icon-Mov: unquote('"\\ed1b"');
$icon-Move: unquote('"\\ed1c"');
$icon-Movement: unquote('"\\ed1d"');
$icon-Mp3: unquote('"\\ed1e"');
$icon-Mp4: unquote('"\\ed1f"');
$icon-Mpeg: unquote('"\\ed20"');
$icon-Mpg2: unquote('"\\ed21"');
$icon-Music: unquote('"\\ed22"');
$icon-Music--add: unquote('"\\ed23"');
$icon-Music--remove: unquote('"\\ed24"');
$icon-Name-space: unquote('"\\ed25"');
$icon-Navaid--civil: unquote('"\\ed26"');
$icon-Navaid--dme: unquote('"\\ed27"');
$icon-Navaid--helipad: unquote('"\\ed28"');
$icon-Navaid--military: unquote('"\\ed29"');
$icon-Navaid--military-civil: unquote('"\\ed2a"');
$icon-Navaid--ndb: unquote('"\\ed2b"');
$icon-Navaid--ndb-dme: unquote('"\\ed2c"');
$icon-Navaid--private: unquote('"\\ed2d"');
$icon-Navaid--seaplane: unquote('"\\ed2e"');
$icon-Navaid--tacan: unquote('"\\ed2f"');
$icon-Navaid--vhfor: unquote('"\\ed30"');
$icon-Navaid--vor: unquote('"\\ed31"');
$icon-Navaid--vordme: unquote('"\\ed32"');
$icon-Navaid--vortac: unquote('"\\ed33"');
$icon-Network--1: unquote('"\\ed34"');
$icon-Network--2: unquote('"\\ed35"');
$icon-Network--3: unquote('"\\ed36"');
$icon-Network--3--reference: unquote('"\\ed37"');
$icon-Network--4: unquote('"\\ed38"');
$icon-Network--admin-control: unquote('"\\ed39"');
$icon-Network--enterprise: unquote('"\\ed3a"');
$icon-Network--overlay: unquote('"\\ed3b"');
$icon-Network--public: unquote('"\\ed3c"');
$icon-New-tab: unquote('"\\ed3d"');
$icon-Next--filled: unquote('"\\ed3e"');
$icon-Next--outline: unquote('"\\ed3f"');
$icon-No-image: unquote('"\\ed40"');
$icon-Nominal: unquote('"\\ed41"');
$icon-Nominate: unquote('"\\ed42"');
$icon-Non-certified: unquote('"\\ed43"');
$icon-Noodle-bowl: unquote('"\\ed44"');
$icon-Not-available: unquote('"\\ed45"');
$icon-Notebook: unquote('"\\ed46"');
$icon-Notebook--reference: unquote('"\\ed47"');
$icon-No-ticket: unquote('"\\ed48"');
$icon-Notification: unquote('"\\ed49"');
$icon-Notification--filled: unquote('"\\ed4a"');
$icon-Notification--new: unquote('"\\ed4b"');
$icon-Notification--off: unquote('"\\ed4c"');
$icon-Notification--off--filled: unquote('"\\ed4d"');
$icon-Not-sent: unquote('"\\ed4e"');
$icon-Not-sent--filled: unquote('"\\ed4f"');
$icon-Number--0: unquote('"\\ed50"');
$icon-Number--1: unquote('"\\ed51"');
$icon-Number--2: unquote('"\\ed52"');
$icon-Number--3: unquote('"\\ed53"');
$icon-Number--4: unquote('"\\ed54"');
$icon-Number--5: unquote('"\\ed55"');
$icon-Number--6: unquote('"\\ed56"');
$icon-Number--7: unquote('"\\ed57"');
$icon-Number--8: unquote('"\\ed58"');
$icon-Number--9: unquote('"\\ed59"');
$icon-Number--small--0: unquote('"\\ed5a"');
$icon-Number--small--1: unquote('"\\ed5b"');
$icon-Number--small--2: unquote('"\\ed5c"');
$icon-Number--small--3: unquote('"\\ed5d"');
$icon-Number--small--4: unquote('"\\ed5e"');
$icon-Number--small--5: unquote('"\\ed5f"');
$icon-Number--small--6: unquote('"\\ed60"');
$icon-Number--small--7: unquote('"\\ed61"');
$icon-Number--small--8: unquote('"\\ed62"');
$icon-Number--small--9: unquote('"\\ed63"');
$icon-Object-storage: unquote('"\\ed64"');
$icon-Object-storage--alt: unquote('"\\ed65"');
$icon-Observed--hail: unquote('"\\ed66"');
$icon-Observed--lightning: unquote('"\\ed67"');
$icon-Omega: unquote('"\\ed68"');
$icon-Opacity: unquote('"\\ed69"');
$icon-Open-panel--bottom: unquote('"\\ed6a"');
$icon-Open-panel--filled--bottom: unquote('"\\ed6b"');
$icon-Open-panel--filled--left: unquote('"\\ed6c"');
$icon-Open-panel--filled--right: unquote('"\\ed6d"');
$icon-Open-panel--filled--top: unquote('"\\ed6e"');
$icon-Open-panel--left: unquote('"\\ed6f"');
$icon-Open-panel--right: unquote('"\\ed70"');
$icon-Open-panel--top: unquote('"\\ed71"');
$icon-Operation: unquote('"\\ed72"');
$icon-Operation--gauge: unquote('"\\ed73"');
$icon-Operation--if: unquote('"\\ed74"');
$icon-Operations--field: unquote('"\\ed75"');
$icon-Operations--record: unquote('"\\ed76"');
$icon-Ordinal: unquote('"\\ed77"');
$icon-Outage: unquote('"\\ed78"');
$icon-Outlook-severe: unquote('"\\ed79"');
$icon-Overflow-menu--horizontal: unquote('"\\ed7a"');
$icon-Overflow-menu--vertical: unquote('"\\ed7b"');
$icon-Overlay: unquote('"\\ed7c"');
$icon-Package: unquote('"\\ed7d"');
$icon-Page-break: unquote('"\\ed7e"');
$icon-Page--first: unquote('"\\ed7f"');
$icon-Page--last: unquote('"\\ed80"');
$icon-Page-number: unquote('"\\ed81"');
$icon-Page-scroll: unquote('"\\ed82"');
$icon-Paint-brush: unquote('"\\ed83"');
$icon-Paint-brush--alt: unquote('"\\ed84"');
$icon-Palm-tree: unquote('"\\ed85"');
$icon-Panel-expansion: unquote('"\\ed86"');
$icon-Pan--horizontal: unquote('"\\ed87"');
$icon-Pan--vertical: unquote('"\\ed88"');
$icon-Paragraph: unquote('"\\ed89"');
$icon-Parameter: unquote('"\\ed8a"');
$icon-Parent-child: unquote('"\\ed8b"');
$icon-Partly-cloudy: unquote('"\\ed8c"');
$icon-Partly-cloudy--night: unquote('"\\ed8d"');
$icon-Partnership: unquote('"\\ed8e"');
$icon-Passenger--drinks: unquote('"\\ed8f"');
$icon-Passenger--plus: unquote('"\\ed90"');
$icon-Password: unquote('"\\ed91"');
$icon-Paste: unquote('"\\ed92"');
$icon-Pause: unquote('"\\ed93"');
$icon-Pause--filled: unquote('"\\ed94"');
$icon-Pause-future: unquote('"\\ed95"');
$icon-Pause--outline: unquote('"\\ed96"');
$icon-Pause--outline--filled: unquote('"\\ed97"');
$icon-Pause-past: unquote('"\\ed98"');
$icon-Pcn--e-node: unquote('"\\ed99"');
$icon-Pcn--military: unquote('"\\ed9a"');
$icon-Pcn--p-node: unquote('"\\ed9b"');
$icon-Pcn--z-node: unquote('"\\ed9c"');
$icon-Pdf: unquote('"\\ed9d"');
$icon-Pdf--reference: unquote('"\\ed9e"');
$icon-Pedestrian: unquote('"\\ed9f"');
$icon-Pedestrian-child: unquote('"\\eda0"');
$icon-Pedestrian--family: unquote('"\\eda1"');
$icon-Pen: unquote('"\\eda2"');
$icon-Pending: unquote('"\\eda3"');
$icon-Pending--filled: unquote('"\\eda4"');
$icon-Pen--fountain: unquote('"\\eda5"');
$icon-Percentage: unquote('"\\eda6"');
$icon-Percentage--filled: unquote('"\\eda7"');
$icon-Person: unquote('"\\eda8"');
$icon-Person--favorite: unquote('"\\eda9"');
$icon-Pest: unquote('"\\edaa"');
$icon-Pet-image--b: unquote('"\\edab"');
$icon-Pet-image--o: unquote('"\\edac"');
$icon-Phone: unquote('"\\edad"');
$icon-Phone--application: unquote('"\\edae"');
$icon-Phone--block: unquote('"\\edaf"');
$icon-Phone--block--filled: unquote('"\\edb0"');
$icon-Phone--filled: unquote('"\\edb1"');
$icon-Phone--incoming: unquote('"\\edb2"');
$icon-Phone--incoming--filled: unquote('"\\edb3"');
$icon-Phone--ip: unquote('"\\edb4"');
$icon-Phone--off: unquote('"\\edb5"');
$icon-Phone--off--filled: unquote('"\\edb6"');
$icon-Phone--outgoing: unquote('"\\edb7"');
$icon-Phone--outgoing--filled: unquote('"\\edb8"');
$icon-Phone--settings: unquote('"\\edb9"');
$icon-Phone--voice: unquote('"\\edba"');
$icon-Phone--voice--filled: unquote('"\\edbb"');
$icon-Phrase-sentiment: unquote('"\\edbc"');
$icon-Picnic-area: unquote('"\\edbd"');
$icon-Piggy-bank: unquote('"\\edbe"');
$icon-Piggy-bank--slot: unquote('"\\edbf"');
$icon-Pills: unquote('"\\edc0"');
$icon-Pills--add: unquote('"\\edc1"');
$icon-Pills--subtract: unquote('"\\edc2"');
$icon-Pin: unquote('"\\edc3"');
$icon-Pin--filled: unquote('"\\edc4"');
$icon-Plane: unquote('"\\edc5"');
$icon-Plane--private: unquote('"\\edc6"');
$icon-Plane--sea: unquote('"\\edc7"');
$icon-Play: unquote('"\\edc8"');
$icon-Play--filled: unquote('"\\edc9"');
$icon-Play--filled--alt: unquote('"\\edca"');
$icon-Playlist: unquote('"\\edcb"');
$icon-Play--outline: unquote('"\\edcc"');
$icon-Play--outline--filled: unquote('"\\edcd"');
$icon-Plug: unquote('"\\edce"');
$icon-Plug--filled: unquote('"\\edcf"');
$icon-Png: unquote('"\\edd0"');
$icon-Pointer-text: unquote('"\\edd1"');
$icon-Point-of-presence: unquote('"\\edd2"');
$icon-Police: unquote('"\\edd3"');
$icon-Policy: unquote('"\\edd4"');
$icon-Popup: unquote('"\\edd5"');
$icon-Portfolio: unquote('"\\edd6"');
$icon-Power: unquote('"\\edd7"');
$icon-Ppt: unquote('"\\edd8"');
$icon-Presentation-file: unquote('"\\edd9"');
$icon-Pressure: unquote('"\\edda"');
$icon-Pressure--filled: unquote('"\\eddb"');
$icon-Previous--filled: unquote('"\\eddc"');
$icon-Previous--outline: unquote('"\\eddd"');
$icon-Printer: unquote('"\\edde"');
$icon-Product: unquote('"\\eddf"');
$icon-Progress-bar: unquote('"\\ede0"');
$icon-Progress-bar--round: unquote('"\\ede1"');
$icon-Purchase: unquote('"\\ede2"');
$icon-Qq-plot: unquote('"\\ede3"');
$icon-Qr-code: unquote('"\\ede4"');
$icon-Quadrant-plot: unquote('"\\ede5"');
$icon-Query: unquote('"\\ede6"');
$icon-Query-queue: unquote('"\\ede7"');
$icon-Queued: unquote('"\\ede8"');
$icon-Quotes: unquote('"\\ede9"');
$icon-Radar: unquote('"\\edea"');
$icon-Radar--enhanced: unquote('"\\edeb"');
$icon-Radar--weather: unquote('"\\edec"');
$icon-Radio: unquote('"\\eded"');
$icon-Radio-button: unquote('"\\edee"');
$icon-Radio-button--checked: unquote('"\\edef"');
$icon-Radio--combat: unquote('"\\edf0"');
$icon-Radio--push-to-talk: unquote('"\\edf1"');
$icon-Rain: unquote('"\\edf2"');
$icon-Rain--drizzle: unquote('"\\edf3"');
$icon-Rain-drop: unquote('"\\edf4"');
$icon-Rain--heavy: unquote('"\\edf5"');
$icon-Rain--scattered: unquote('"\\edf6"');
$icon-Rain--scattered--night: unquote('"\\edf7"');
$icon-Raw: unquote('"\\edf8"');
$icon-Receipt: unquote('"\\edf9"');
$icon-Recently-viewed: unquote('"\\edfa"');
$icon-Recommend: unquote('"\\edfb"');
$icon-Recording: unquote('"\\edfc"');
$icon-Recording--filled: unquote('"\\edfd"');
$icon-Recording--filled--alt: unquote('"\\edfe"');
$icon-Redo: unquote('"\\edff"');
$icon-Ref-evapotranspiration: unquote('"\\ee00"');
$icon-Reflect--horizontal: unquote('"\\ee01"');
$icon-Reflect--vertical: unquote('"\\ee02"');
$icon-Region-analysis--area: unquote('"\\ee03"');
$icon-Region-analysis--volume: unquote('"\\ee04"');
$icon-Registration: unquote('"\\ee05"');
$icon-Reminder: unquote('"\\ee06"');
$icon-Reminder--medical: unquote('"\\ee07"');
$icon-Renew: unquote('"\\ee08"');
$icon-Repeat: unquote('"\\ee09"');
$icon-Repeat--one: unquote('"\\ee0a"');
$icon-Replicate: unquote('"\\ee0b"');
$icon-Reply: unquote('"\\ee0c"');
$icon-Reply--all: unquote('"\\ee0d"');
$icon-Report: unquote('"\\ee0e"');
$icon-Report--data: unquote('"\\ee0f"');
$icon-Request-quote: unquote('"\\ee10"');
$icon-Reset: unquote('"\\ee11"');
$icon-Reset--alt: unquote('"\\ee12"');
$icon-Restart: unquote('"\\ee13"');
$icon-Restaurant: unquote('"\\ee14"');
$icon-Restaurant--fine: unquote('"\\ee15"');
$icon-Result: unquote('"\\ee16"');
$icon-Result--new: unquote('"\\ee17"');
$icon-Result--old: unquote('"\\ee18"');
$icon-Rewind--5: unquote('"\\ee19"');
$icon-Rewind--10: unquote('"\\ee1a"');
$icon-Rewind--30: unquote('"\\ee1b"');
$icon-Road: unquote('"\\ee1c"');
$icon-Roadmap: unquote('"\\ee1d"');
$icon-Road--weather: unquote('"\\ee1e"');
$icon-Rocket: unquote('"\\ee1f"');
$icon-Rotate: unquote('"\\ee20"');
$icon-Rotate--180: unquote('"\\ee21"');
$icon-Rotate--360: unquote('"\\ee22"');
$icon-Rotate--clockwise: unquote('"\\ee23"');
$icon-Rotate--clockwise--alt: unquote('"\\ee24"');
$icon-Rotate--clockwise--alt--filled: unquote('"\\ee25"');
$icon-Rotate--clockwise--filled: unquote('"\\ee26"');
$icon-Rotate--counterclockwise: unquote('"\\ee27"');
$icon-Rotate--counterclockwise--alt: unquote('"\\ee28"');
$icon-Rotate--counterclockwise--alt--filled: unquote('"\\ee29"');
$icon-Rotate--counterclockwise--filled: unquote('"\\ee2a"');
$icon-Router: unquote('"\\ee2b"');
$icon-Router--voice: unquote('"\\ee2c"');
$icon-Router--wifi: unquote('"\\ee2d"');
$icon-Row: unquote('"\\ee2e"');
$icon-Row--collapse: unquote('"\\ee2f"');
$icon-Row--delete: unquote('"\\ee30"');
$icon-Row--expand: unquote('"\\ee31"');
$icon-Row--insert: unquote('"\\ee32"');
$icon-Rss: unquote('"\\ee33"');
$icon-Rule: unquote('"\\ee34"');
$icon-Rule--cancelled: unquote('"\\ee35"');
$icon-Rule--draft: unquote('"\\ee36"');
$icon-Rule--filled: unquote('"\\ee37"');
$icon-Ruler: unquote('"\\ee38"');
$icon-Ruler--alt: unquote('"\\ee39"');
$icon-Rule--test: unquote('"\\ee3a"');
$icon-Run: unquote('"\\ee3b"');
$icon-S: unquote('"\\ee3c"');
$icon-Sailboat--coastal: unquote('"\\ee3d"');
$icon-Sailboat--offshore: unquote('"\\ee3e"');
$icon-S--alt: unquote('"\\ee3f"');
$icon-Sankey-diagram: unquote('"\\ee40"');
$icon-Sankey-diagram--alt: unquote('"\\ee41"');
$icon-Satellite: unquote('"\\ee42"');
$icon-Satellite--radar: unquote('"\\ee43"');
$icon-Satellite--weather: unquote('"\\ee44"');
$icon-Save: unquote('"\\ee45"');
$icon-Save--annotation: unquote('"\\ee46"');
$icon-Save--image: unquote('"\\ee47"');
$icon-Save--model: unquote('"\\ee48"');
$icon-Save--series: unquote('"\\ee49"');
$icon-Scale: unquote('"\\ee4a"');
$icon-Scales: unquote('"\\ee4b"');
$icon-Scales--tipped: unquote('"\\ee4c"');
$icon-Scalpel: unquote('"\\ee4d"');
$icon-Scalpel--cursor: unquote('"\\ee4e"');
$icon-Scalpel--lasso: unquote('"\\ee4f"');
$icon-Scalpel--select: unquote('"\\ee50"');
$icon-Scan: unquote('"\\ee51"');
$icon-Scan--alt: unquote('"\\ee52"');
$icon-Scan--disabled: unquote('"\\ee53"');
$icon-Scatter-matrix: unquote('"\\ee54"');
$icon-Schematics: unquote('"\\ee55"');
$icon-Scooter: unquote('"\\ee56"');
$icon-Scooter--front: unquote('"\\ee57"');
$icon-Screen: unquote('"\\ee58"');
$icon-Screen--off: unquote('"\\ee59"');
$icon-Script: unquote('"\\ee5a"');
$icon-Script--reference: unquote('"\\ee5b"');
$icon-Sdk: unquote('"\\ee5c"');
$icon-Search: unquote('"\\ee5d"');
$icon-Search--locate: unquote('"\\ee5e"');
$icon-Security: unquote('"\\ee5f"');
$icon-Security-services: unquote('"\\ee60"');
$icon-Select--01: unquote('"\\ee61"');
$icon-Select--02: unquote('"\\ee62"');
$icon-Select--window: unquote('"\\ee63"');
$icon-Send: unquote('"\\ee64"');
$icon-Send--alt: unquote('"\\ee65"');
$icon-Send--alt--filled: unquote('"\\ee66"');
$icon-Send--filled: unquote('"\\ee67"');
$icon-Send-to-back: unquote('"\\ee68"');
$icon-Server--dns: unquote('"\\ee69"');
$icon-Server--proxy: unquote('"\\ee6a"');
$icon-Server--time: unquote('"\\ee6b"');
$icon-Service-desk: unquote('"\\ee6c"');
$icon-Session-border-control: unquote('"\\ee6d"');
$icon-Settings: unquote('"\\ee6e"');
$icon-Settings--adjust: unquote('"\\ee6f"');
$icon-Settings--check: unquote('"\\ee70"');
$icon-Settings--services: unquote('"\\ee71"');
$icon-Settings--view: unquote('"\\ee72"');
$icon-Shape--except: unquote('"\\ee73"');
$icon-Shape--exclude: unquote('"\\ee74"');
$icon-Shape--intersect: unquote('"\\ee75"');
$icon-Shape--join: unquote('"\\ee76"');
$icon-Shape--unite: unquote('"\\ee77"');
$icon-Share: unquote('"\\ee78"');
$icon-Share-knowledge: unquote('"\\ee79"');
$icon-Shopping--bag: unquote('"\\ee7a"');
$icon-Shopping--cart: unquote('"\\ee7b"');
$icon-Shopping--cart--arrow-down: unquote('"\\ee7c"');
$icon-Shopping--cart--arrow-up: unquote('"\\ee7d"');
$icon-Shopping--cart--clear: unquote('"\\ee7e"');
$icon-Shopping--cart--error: unquote('"\\ee7f"');
$icon-Shopping--cart--minus: unquote('"\\ee80"');
$icon-Shopping--cart--plus: unquote('"\\ee81"');
$icon-Shopping--catalog: unquote('"\\ee82"');
$icon-Show-data--cards: unquote('"\\ee83"');
$icon-Shrink-screen: unquote('"\\ee84"');
$icon-Shrink-screen--filled: unquote('"\\ee85"');
$icon-Shuffle: unquote('"\\ee86"');
$icon-Shuttle: unquote('"\\ee87"');
$icon-Sight: unquote('"\\ee88"');
$icon-Sigma: unquote('"\\ee89"');
$icon-Signal-strength: unquote('"\\ee8a"');
$icon-Sim-card: unquote('"\\ee8b"');
$icon-Skill-level: unquote('"\\ee8c"');
$icon-Skill-level--advanced: unquote('"\\ee8d"');
$icon-Skill-level--basic: unquote('"\\ee8e"');
$icon-Skill-level--intermediate: unquote('"\\ee8f"');
$icon-Skip--back: unquote('"\\ee90"');
$icon-Skip--back--filled: unquote('"\\ee91"');
$icon-Skip--back--outline: unquote('"\\ee92"');
$icon-Skip--back--outline--filled: unquote('"\\ee93"');
$icon-Skip--back--outline--solid: unquote('"\\ee94"');
$icon-Skip--back--solid--filled: unquote('"\\ee95"');
$icon-Skip--forward: unquote('"\\ee96"');
$icon-Skip--forward--filled: unquote('"\\ee97"');
$icon-Skip--forward--outline: unquote('"\\ee98"');
$icon-Skip--forward--outline--filled: unquote('"\\ee99"');
$icon-Skip--forward--outline--solid: unquote('"\\ee9a"');
$icon-Skip--forward--solid--filled: unquote('"\\ee9b"');
$icon-Sleet: unquote('"\\ee9c"');
$icon-Slisor: unquote('"\\ee9d"');
$icon-Smell: unquote('"\\ee9e"');
$icon-Smoke: unquote('"\\ee9f"');
$icon-Smoothing: unquote('"\\eea0"');
$icon-Smoothing--cursor: unquote('"\\eea1"');
$icon-Snooze: unquote('"\\eea2"');
$icon-Snow: unquote('"\\eea3"');
$icon-Snow--blizzard: unquote('"\\eea4"');
$icon-Snow-density: unquote('"\\eea5"');
$icon-Snowflake: unquote('"\\eea6"');
$icon-Snow--heavy: unquote('"\\eea7"');
$icon-Snow--scattered: unquote('"\\eea8"');
$icon-Snow--scattered--night: unquote('"\\eea9"');
$icon-Soccer: unquote('"\\eeaa"');
$icon-Soil-moisture: unquote('"\\eeab"');
$icon-Soil-moisture--field: unquote('"\\eeac"');
$icon-Soil-moisture--global: unquote('"\\eead"');
$icon-Soil-temperature: unquote('"\\eeae"');
$icon-Soil-temperature--field: unquote('"\\eeaf"');
$icon-Soil-temperature--global: unquote('"\\eeb0"');
$icon-Solar-panel: unquote('"\\eeb1"');
$icon-Sort--ascending: unquote('"\\eeb2"');
$icon-Sort--descending: unquote('"\\eeb3"');
$icon-Spell-check: unquote('"\\eeb4"');
$icon-Spine-label: unquote('"\\eeb5"');
$icon-Split: unquote('"\\eeb6"');
$icon-Split--discard: unquote('"\\eeb7"');
$icon-Split-screen: unquote('"\\eeb8"');
$icon-Spray-paint: unquote('"\\eeb9"');
$icon-Sprout: unquote('"\\eeba"');
$icon-Sql: unquote('"\\eebb"');
$icon-Stacked-move: unquote('"\\eebc"');
$icon-Stacked-scrolling--1: unquote('"\\eebd"');
$icon-Stacked-scrolling--2: unquote('"\\eebe"');
$icon-Stack-limitation: unquote('"\\eebf"');
$icon-Stamp: unquote('"\\eec0"');
$icon-Star: unquote('"\\eec1"');
$icon-Star--filled: unquote('"\\eec2"');
$icon-Star--half: unquote('"\\eec3"');
$icon-Star--review: unquote('"\\eec4"');
$icon-Stay-inside: unquote('"\\eec5"');
$icon-Stem-leaf-plot: unquote('"\\eec6"');
$icon-Stethoscope: unquote('"\\eec7"');
$icon-Stop: unquote('"\\eec8"');
$icon-Stop--filled: unquote('"\\eec9"');
$icon-Stop--filled--alt: unquote('"\\eeca"');
$icon-Stop--outline: unquote('"\\eecb"');
$icon-Stop--outline--filled: unquote('"\\eecc"');
$icon-Stop-sign: unquote('"\\eecd"');
$icon-Stop-sign--filled: unquote('"\\eece"');
$icon-Storage-pool: unquote('"\\eecf"');
$icon-Storage-request: unquote('"\\eed0"');
$icon-Store: unquote('"\\eed1"');
$icon-Storm-tracker: unquote('"\\eed2"');
$icon-Strawberry: unquote('"\\eed3"');
$icon-Stress-breath-editor: unquote('"\\eed4"');
$icon-String-integer: unquote('"\\eed5"');
$icon-String-text: unquote('"\\eed6"');
$icon-Study--next: unquote('"\\eed7"');
$icon-Study--previous: unquote('"\\eed8"');
$icon-Study--skip: unquote('"\\eed9"');
$icon-Subnet-acl-rules: unquote('"\\eeda"');
$icon-Subtract: unquote('"\\eedb"');
$icon-Subtract--alt: unquote('"\\eedc"');
$icon-Sub-volume: unquote('"\\eedd"');
$icon-Summary--kpi: unquote('"\\eede"');
$icon-Sun: unquote('"\\eedf"');
$icon-Sunrise: unquote('"\\eee0"');
$icon-Sunset: unquote('"\\eee1"');
$icon-Svg: unquote('"\\eee2"');
$icon-Swim: unquote('"\\eee3"');
$icon-Switcher: unquote('"\\eee4"');
$icon-Switch-layer-2: unquote('"\\eee5"');
$icon-Switch-layer-3: unquote('"\\eee6"');
$icon-Sys-provision: unquote('"\\eee7"');
$icon-T: unquote('"\\eee8"');
$icon-Table: unquote('"\\eee9"');
$icon-Table--built: unquote('"\\eeea"');
$icon-Table-of-contents: unquote('"\\eeeb"');
$icon-Table--split: unquote('"\\eeec"');
$icon-Tablet: unquote('"\\eeed"');
$icon-Tablet--landscape: unquote('"\\eeee"');
$icon-Tag: unquote('"\\eeef"');
$icon-Tag--edit: unquote('"\\eef0"');
$icon-Tag--group: unquote('"\\eef1"');
$icon-T--alt: unquote('"\\eef2"');
$icon-Tank: unquote('"\\eef3"');
$icon-Task: unquote('"\\eef4"');
$icon-Task--add: unquote('"\\eef5"');
$icon-Task--approved: unquote('"\\eef6"');
$icon-Task--asset-view: unquote('"\\eef7"');
$icon-Task--complete: unquote('"\\eef8"');
$icon-Task--location: unquote('"\\eef9"');
$icon-Task--settings: unquote('"\\eefa"');
$icon-Task--star: unquote('"\\eefb"');
$icon-Task--tools: unquote('"\\eefc"');
$icon-Task--view: unquote('"\\eefd"');
$icon-Taste: unquote('"\\eefe"');
$icon-Taxi: unquote('"\\eeff"');
$icon-Temperature: unquote('"\\ef00"');
$icon-Temperature--celsius: unquote('"\\ef01"');
$icon-Temperature--celsius--alt: unquote('"\\ef02"');
$icon-Temperature--fahrenheit: unquote('"\\ef03"');
$icon-Temperature--fahrenheit--alt: unquote('"\\ef04"');
$icon-Temperature--feels-like: unquote('"\\ef05"');
$icon-Temperature--frigid: unquote('"\\ef06"');
$icon-Temperature--hot: unquote('"\\ef07"');
$icon-Temperature--inversion: unquote('"\\ef08"');
$icon-Temperature--max: unquote('"\\ef09"');
$icon-Temperature--min: unquote('"\\ef0a"');
$icon-Temperature--water: unquote('"\\ef0b"');
$icon-Template: unquote('"\\ef0c"');
$icon-Tennis: unquote('"\\ef0d"');
$icon-Tennis-ball: unquote('"\\ef0e"');
$icon-Term: unquote('"\\ef0f"');
$icon-Terminal: unquote('"\\ef10"');
$icon-Text--align--center: unquote('"\\ef11"');
$icon-Text--align--justify: unquote('"\\ef12"');
$icon-Text--align--left: unquote('"\\ef13"');
$icon-Text--align--mixed: unquote('"\\ef14"');
$icon-Text--align--right: unquote('"\\ef15"');
$icon-Text--all-caps: unquote('"\\ef16"');
$icon-Text-annotation-toggle: unquote('"\\ef17"');
$icon-Text--bold: unquote('"\\ef18"');
$icon-Text--clear-format: unquote('"\\ef19"');
$icon-Text--color: unquote('"\\ef1a"');
$icon-Text--creation: unquote('"\\ef1b"');
$icon-Text--fill: unquote('"\\ef1c"');
$icon-Text--font: unquote('"\\ef1d"');
$icon-Text--footnote: unquote('"\\ef1e"');
$icon-Text--highlight: unquote('"\\ef1f"');
$icon-Text--indent: unquote('"\\ef20"');
$icon-Text--indent--less: unquote('"\\ef21"');
$icon-Text--indent--more: unquote('"\\ef22"');
$icon-Text--italic: unquote('"\\ef23"');
$icon-Text--kerning: unquote('"\\ef24"');
$icon-Text--leading: unquote('"\\ef25"');
$icon-Text--line-spacing: unquote('"\\ef26"');
$icon-Text-link: unquote('"\\ef27"');
$icon-Text-link--analysis: unquote('"\\ef28"');
$icon-Text-mining: unquote('"\\ef29"');
$icon-Text-mining--applier: unquote('"\\ef2a"');
$icon-Text--new-line: unquote('"\\ef2b"');
$icon-Text--scale: unquote('"\\ef2c"');
$icon-Text--selection: unquote('"\\ef2d"');
$icon-Text--small-caps: unquote('"\\ef2e"');
$icon-Text--strikethrough: unquote('"\\ef2f"');
$icon-Text--subscript: unquote('"\\ef30"');
$icon-Text--superscript: unquote('"\\ef31"');
$icon-Text--tracking: unquote('"\\ef32"');
$icon-Text--underline: unquote('"\\ef33"');
$icon-Text--vertical-alignment: unquote('"\\ef34"');
$icon-Text--wrap: unquote('"\\ef35"');
$icon-Theater: unquote('"\\ef36"');
$icon-This-side-up: unquote('"\\ef37"');
$icon-Threshold: unquote('"\\ef38"');
$icon-Thumbnail--1: unquote('"\\ef39"');
$icon-Thumbnail--2: unquote('"\\ef3a"');
$icon-Thumbnail-preview: unquote('"\\ef3b"');
$icon-Thumbs-down: unquote('"\\ef3c"');
$icon-Thumbs-down--filled: unquote('"\\ef3d"');
$icon-Thumbs-up: unquote('"\\ef3e"');
$icon-Thumbs-up--filled: unquote('"\\ef3f"');
$icon-Thunderstorm: unquote('"\\ef40"');
$icon-Thunderstorm--scattered: unquote('"\\ef41"');
$icon-Thunderstorm--scattered--night: unquote('"\\ef42"');
$icon-Thunderstorm--severe: unquote('"\\ef43"');
$icon-Thunderstorm--strong: unquote('"\\ef44"');
$icon-Ticket: unquote('"\\ef45"');
$icon-Tides: unquote('"\\ef46"');
$icon-Tif: unquote('"\\ef47"');
$icon-Time: unquote('"\\ef48"');
$icon-Time-plot: unquote('"\\ef49"');
$icon-Timer: unquote('"\\ef4a"');
$icon-Tool-box: unquote('"\\ef4b"');
$icon-Tool-kit: unquote('"\\ef4c"');
$icon-Tools: unquote('"\\ef4d"');
$icon-Tools--alt: unquote('"\\ef4e"');
$icon-Tornado: unquote('"\\ef4f"');
$icon-Tornado-warning: unquote('"\\ef50"');
$icon-Touch--1: unquote('"\\ef51"');
$icon-Touch--1-down: unquote('"\\ef52"');
$icon-Touch--1-down--filled: unquote('"\\ef53"');
$icon-Touch--1--filled: unquote('"\\ef54"');
$icon-Touch--2: unquote('"\\ef55"');
$icon-Touch--2--filled: unquote('"\\ef56"');
$icon-Touch--interaction: unquote('"\\ef57"');
$icon-Traffic-cone: unquote('"\\ef58"');
$icon-Traffic--event: unquote('"\\ef59"');
$icon-Traffic--flow: unquote('"\\ef5a"');
$icon-Traffic--flow-incident: unquote('"\\ef5b"');
$icon-Traffic--incident: unquote('"\\ef5c"');
$icon-Traffic--weather-incident: unquote('"\\ef5d"');
$icon-Train: unquote('"\\ef5e"');
$icon-Train--heart: unquote('"\\ef5f"');
$icon-Train--profile: unquote('"\\ef60"');
$icon-Train--speed: unquote('"\\ef61"');
$icon-Train--ticket: unquote('"\\ef62"');
$icon-Train--time: unquote('"\\ef63"');
$icon-Tram: unquote('"\\ef64"');
$icon-Transgender: unquote('"\\ef65"');
$icon-Translate: unquote('"\\ef66"');
$icon-Transmission-lte: unquote('"\\ef67"');
$icon-Transpose: unquote('"\\ef68"');
$icon-Trash-can: unquote('"\\ef69"');
$icon-Tree: unquote('"\\ef6a"');
$icon-Tree-view: unquote('"\\ef6b"');
$icon-Tree-view--alt: unquote('"\\ef6c"');
$icon-Trophy: unquote('"\\ef6d"');
$icon-Trophy--filled: unquote('"\\ef6e"');
$icon-Tropical-storm: unquote('"\\ef6f"');
$icon-Tropical-storm--model-tracks: unquote('"\\ef70"');
$icon-Tropical-storm--tracks: unquote('"\\ef71"');
$icon-Tropical-warning: unquote('"\\ef72"');
$icon-Tsunami: unquote('"\\ef73"');
$icon-Tsv: unquote('"\\ef74"');
$icon-Two-factor-authentication: unquote('"\\ef75"');
$icon-Two-person-lift: unquote('"\\ef76"');
$icon-Txt: unquote('"\\ef77"');
$icon-Txt--reference: unquote('"\\ef78"');
$icon-Type-pattern: unquote('"\\ef79"');
$icon-Types: unquote('"\\ef7a"');
$icon-U1: unquote('"\\ef7b"');
$icon-U2: unquote('"\\ef7c"');
$icon-U3: unquote('"\\ef7d"');
$icon-Umbrella: unquote('"\\ef7e"');
$icon-Undefined: unquote('"\\ef7f"');
$icon-Undefined--filled: unquote('"\\ef80"');
$icon-Undo: unquote('"\\ef81"');
$icon-Ungroup-objects: unquote('"\\ef82"');
$icon-Unknown: unquote('"\\ef83"');
$icon-Unknown--filled: unquote('"\\ef84"');
$icon-Unlink: unquote('"\\ef85"');
$icon-Unlocked: unquote('"\\ef86"');
$icon-Update-now: unquote('"\\ef87"');
$icon-Upgrade: unquote('"\\ef88"');
$icon-Upload: unquote('"\\ef89"');
$icon-Up-to-top: unquote('"\\ef8a"');
$icon-Usb: unquote('"\\ef8b"');
$icon-User: unquote('"\\ef8c"');
$icon-User--access: unquote('"\\ef8d"');
$icon-User--activity: unquote('"\\ef8e"');
$icon-User--admin: unquote('"\\ef8f"');
$icon-User--avatar: unquote('"\\ef90"');
$icon-User--avatar--filled: unquote('"\\ef91"');
$icon-User--avatar--filled--alt: unquote('"\\ef92"');
$icon-User--certification: unquote('"\\ef93"');
$icon-User--data: unquote('"\\ef94"');
$icon-User--favorite: unquote('"\\ef95"');
$icon-User--favorite--alt: unquote('"\\ef96"');
$icon-User--favorite--alt--filled: unquote('"\\ef97"');
$icon-User--filled: unquote('"\\ef98"');
$icon-User--follow: unquote('"\\ef99"');
$icon-User--identification: unquote('"\\ef9a"');
$icon-User--military: unquote('"\\ef9b"');
$icon-User--multiple: unquote('"\\ef9c"');
$icon-User--online: unquote('"\\ef9d"');
$icon-User--profile: unquote('"\\ef9e"');
$icon-User-profile--alt: unquote('"\\ef9f"');
$icon-User--role: unquote('"\\efa0"');
$icon-User--service-desk: unquote('"\\efa1"');
$icon-User--settings: unquote('"\\efa2"');
$icon-User--simulation: unquote('"\\efa3"');
$icon-User--speaker: unquote('"\\efa4"');
$icon-User--x-ray: unquote('"\\efa5"');
$icon-Uv-index: unquote('"\\efa6"');
$icon-Uv-index--alt: unquote('"\\efa7"');
$icon-Uv-index--filled: unquote('"\\efa8"');
$icon-Van: unquote('"\\efa9"');
$icon-Vehicle--api: unquote('"\\efaa"');
$icon-Vehicle--connected: unquote('"\\efab"');
$icon-Vehicle--insights: unquote('"\\efac"');
$icon-Vehicle--services: unquote('"\\efad"');
$icon-Version: unquote('"\\efae"');
$icon-Video: unquote('"\\efaf"');
$icon-Video--add: unquote('"\\efb0"');
$icon-Video--chat: unquote('"\\efb1"');
$icon-Video--filled: unquote('"\\efb2"');
$icon-Video--off: unquote('"\\efb3"');
$icon-Video--off--filled: unquote('"\\efb4"');
$icon-View: unquote('"\\efb5"');
$icon-View--filled: unquote('"\\efb6"');
$icon-View--mode-1: unquote('"\\efb7"');
$icon-View--mode-2: unquote('"\\efb8"');
$icon-View-next: unquote('"\\efb9"');
$icon-View--off: unquote('"\\efba"');
$icon-View--off--filled: unquote('"\\efbb"');
$icon-Virtual-column: unquote('"\\efbc"');
$icon-Virtual-column--key: unquote('"\\efbd"');
$icon-Virtual-desktop: unquote('"\\efbe"');
$icon-Virtual-machine: unquote('"\\efbf"');
$icon-Virtual-private-cloud: unquote('"\\efc0"');
$icon-Virtual-private-cloud--alt: unquote('"\\efc1"');
$icon-Visual-recognition: unquote('"\\efc2"');
$icon-Vlan: unquote('"\\efc3"');
$icon-Vlan--IBM: unquote('"\\efc4"');
$icon-Vmdk-disk: unquote('"\\efc5"');
$icon-Voice-activate: unquote('"\\efc6"');
$icon-Voicemail: unquote('"\\efc7"');
$icon-Volume--block-storage: unquote('"\\efc8"');
$icon-Volume--down: unquote('"\\efc9"');
$icon-Volume--down--alt: unquote('"\\efca"');
$icon-Volume--down--filled: unquote('"\\efcb"');
$icon-Volume--down--filled--alt: unquote('"\\efcc"');
$icon-Volume--file-storage: unquote('"\\efcd"');
$icon-Volume--mute: unquote('"\\efce"');
$icon-Volume--mute--filled: unquote('"\\efcf"');
$icon-Volume--object-storage: unquote('"\\efd0"');
$icon-Volume--up: unquote('"\\efd1"');
$icon-Volume--up--alt: unquote('"\\efd2"');
$icon-Volume--up--filled: unquote('"\\efd3"');
$icon-Volume--up--filled--alt: unquote('"\\efd4"');
$icon-Vpn: unquote('"\\efd5"');
$icon-Vpn--connection: unquote('"\\efd6"');
$icon-Vpn--policy: unquote('"\\efd7"');
$icon-Wallet: unquote('"\\efd8"');
$icon-Warning: unquote('"\\efd9"');
$icon-Warning--alt: unquote('"\\efda"');
$icon-Warning--alt--filled: unquote('"\\efdb"');
$icon-Warning--alt-inverted: unquote('"\\efdc"');
$icon-Warning--alt-inverted--filled: unquote('"\\efdd"');
$icon-Warning--filled: unquote('"\\efde"');
$icon-Warning--hex: unquote('"\\efdf"');
$icon-Warning--hex--filled: unquote('"\\efe0"');
$icon-Warning--other: unquote('"\\efe1"');
$icon-Warning-square: unquote('"\\efe2"');
$icon-Warning-square--filled: unquote('"\\efe3"');
$icon-Watch: unquote('"\\efe4"');
$icon-Watson: unquote('"\\efe5"');
$icon-Watson--machine-learning: unquote('"\\efe6"');
$icon-Wave-direction: unquote('"\\efe7"');
$icon-Wave-height: unquote('"\\efe8"');
$icon-Wave-period: unquote('"\\efe9"');
$icon-Weather-front--cold: unquote('"\\efea"');
$icon-Weather-front--stationary: unquote('"\\efeb"');
$icon-Weather-front--warm: unquote('"\\efec"');
$icon-Weather-station: unquote('"\\efed"');
$icon-Websheet: unquote('"\\efee"');
$icon-Wheat: unquote('"\\efef"');
$icon-Wifi: unquote('"\\eff0"');
$icon-Wifi-bridge: unquote('"\\eff1"');
$icon-Wifi-bridge--alt: unquote('"\\eff2"');
$icon-Wifi--controller: unquote('"\\eff3"');
$icon-Wifi--not-secure: unquote('"\\eff4"');
$icon-Wifi--off: unquote('"\\eff5"');
$icon-Wifi--secure: unquote('"\\eff6"');
$icon-Wikis: unquote('"\\eff7"');
$icon-Wind-gusts: unquote('"\\eff8"');
$icon-Window--auto: unquote('"\\eff9"');
$icon-Window--base: unquote('"\\effa"');
$icon-Window--black-saturation: unquote('"\\effb"');
$icon-Window--overlay: unquote('"\\effc"');
$icon-Window--preset: unquote('"\\effd"');
$icon-Wind-power: unquote('"\\effe"');
$icon-Wind-stream: unquote('"\\efff"');
$icon-Windy: unquote('"\\f000"');
$icon-Windy--dust: unquote('"\\f001"');
$icon-Windy--snow: unquote('"\\f002"');
$icon-Windy--strong: unquote('"\\f003"');
$icon-Winter-warning: unquote('"\\f004"');
$icon-Wintry-mix: unquote('"\\f005"');
$icon-Wireless-checkout: unquote('"\\f006"');
$icon-Wmv: unquote('"\\f007"');
$icon-Word-cloud: unquote('"\\f008"');
$icon-Workspace: unquote('"\\f009"');
$icon-Workspace--import: unquote('"\\f00a"');
$icon-Worship: unquote('"\\f00b"');
$icon-Worship--christian: unquote('"\\f00c"');
$icon-Worship--jewish: unquote('"\\f00d"');
$icon-Worship--muslim: unquote('"\\f00e"');
$icon-X: unquote('"\\f00f"');
$icon-X-axis: unquote('"\\f010"');
$icon-Xls: unquote('"\\f011"');
$icon-Xml: unquote('"\\f012"');
$icon-Y: unquote('"\\f013"');
$icon-Y-axis: unquote('"\\f014"');
$icon-Z: unquote('"\\f015"');
$icon-Z-axis: unquote('"\\f016"');
$icon-Zip: unquote('"\\f017"');
$icon-Zip--reference: unquote('"\\f018"');
$icon-Zoom--area: unquote('"\\f019"');
$icon-Zoom--fit: unquote('"\\f01a"');
$icon-Zoom--in: unquote('"\\f01b"');
$icon-Zoom--in-area: unquote('"\\f01c"');
$icon-Zoom--out: unquote('"\\f01d"');
$icon-Zoom--out-area: unquote('"\\f01e"');
$icon-Zoom-pan: unquote('"\\f01f"');
$icon-Zoom--reset: unquote('"\\f020"');

