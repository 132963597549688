@mixin scrollbar($trackColor, $thumbColor, $size) {
    --sb-track-color: #{$trackColor};
    --sb-thumb-color: #{$thumbColor};
    --sb-size: #{$size};

    &::-webkit-scrollbar {
        width: var(--sb-size);
    }

    &::-webkit-scrollbar-track {
        background: var(--sb-track-color);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--sb-thumb-color);
        border-radius: 3px;
    }

    @supports not selector(::-webkit-scrollbar) {
        & {
            scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
        }
    }
}
