.modal {
    border: none;
    border-radius: 12px;
    position: fixed;
    inset: 10px;
    margin: 0;
    max-width: unset;
    max-height: unset;
    width: calc(100vw - 10px * 2);
    height: calc(100vh - 10px * 2);

    &::backdrop {
        @extend .bg-neutrals-black;
        opacity: 0.5;
    }

    .modal_header {}
}