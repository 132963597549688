.unit_item {

    margin-block: 16px;

    &.no_margins {
        margin-block: 0;
    }

    &.no_margins+&:not(.no_margins) {
        margin-top: 32px !important;
        margin-block: 16px;
    }

    &:not(.no_margins)+.no_margins {

        margin-top: 16px !important;
        margin-block: 0;
    }
}