// CSS AND SASS VARIABLES

$breakpoints: (
    xs,
    sm,
    md,
    lg,
    xl
);

$enable-negative-margins: true;
$grid-breakpoints: (
    xs: 0,
    sm: 375px,
    md: 768px,
    lg: 1140px,
    xl: 2500px,
);

$container-max-widths: (
    sm: 540px,
    md: 1000px,
    lg: 1320px,
    xl: 1920px,
);

$spacer: 1rem;
$spacers: (
    0: 0,
    "01": $spacer * 0.25,
    "02": $spacer * 0.5,
    "03": $spacer * 0.75,
    1: $spacer,
    11: $spacer * 1.25,
    12: $spacer * 1.5,
    13: $spacer * 1.75,
    2: $spacer * 2,
    21: $spacer * 2.25,
    22: $spacer * 2.5,
    23: $spacer * 2.75,
    3: $spacer * 3,
    31: $spacer * 3.25,
    32: $spacer * 3.5,
    33: $spacer * 3.75,
    4: $spacer * 4,
    41: $spacer * 4.25,
    42: $spacer * 4.50,
    43: $spacer * 4.75,
    5: $spacer * 5,
    51: $spacer * 5.25,
    52: $spacer * 5.5,
    53: $spacer * 5.75,
    6: $spacer * 6,
);

$grid-gutter-width: map-get($spacers, 04);