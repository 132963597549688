// will generate .text-{type}-{font weight}-{breakpoint}

$font-family-dmsans: "DM Sans", sans-serif;

$font-sizes: (
    56: calc(56/16 * 1rem),
    48: calc(48/16 * 1rem),
    28: calc(28/16 * 1rem),
    24: calc(24/16 * 1rem),
    20: calc(20/16 * 1rem),
    18: calc(18/16 * 1rem),
    16: calc(16/16 * 1rem),
    14: calc(14/16 * 1rem),
    12: calc(12/16 * 1rem)
);

$font-weights: (
    100: "thin",
    200: "xlight",
    300: "light",
    400: "rg",
    500: "md",
    600: "smbd",
    700: "bd",
    800: "xbd",
    900: "black"
);

$text-types: (
    h1-big,
    h1,
    h2,
    h3,
    h4,
    h5,
    bd1,
    bd2,
    bd3,
    bd4,
);

$text-breakpoints: (
    h1-big: map-get($font-sizes, 48),
    h1-big-xl: map-get($font-sizes, 56),
    h1: map-get($font-sizes, 28),
    h2: map-get($font-sizes, 24),
    h3: map-get($font-sizes, 20),
    h4: map-get($font-sizes, 18),
    h5: map-get($font-sizes, 16),
    h1-lg: map-get($font-sizes, 28),
    h1-md: map-get($font-sizes, 16),
    h1-xs: map-get($font-sizes, 12),
    bd1: map-get($font-sizes, 18),
    bd2: map-get($font-sizes, 16),
    bd3: map-get($font-sizes, 14),
    bd4: map-get($font-sizes, 12),
    bd1-lg: map-get($font-sizes, 16),
    bd1-md: map-get($font-sizes, 16),
    bd1-xs: map-get($font-sizes, 16),
);

@mixin generate-text-classes($sizes) {
    .text {
        @each $type in $text-types {
            @each $weight, $name in $font-weights {
                &-#{$type}-#{$name} {
                    color: inherit;
                    font-family: $font-family-dmsans;
                    font-size: map-get($text-breakpoints, #{$type});
                    font-weight: $weight;
                    line-height: 1.5;
                }

            }
        }

    }

    @each $size in $sizes {
        @include media-breakpoint-up($size) {
            .text {
                @each $type in $text-types {
                    @each $weight, $name in $font-weights {
                        &-#{$type}-#{$name}-#{$size} {
                            color: inherit;
                            font-family: $font-family-dmsans;
                            font-size: map-get($text-breakpoints, #{$type}-#{$size});
                            font-weight: $weight;
                        }

                    }
                }
            }
        }
    }
}

@include generate-text-classes($breakpoints);

p {
    margin-block: $spacer;
}

strong {
    font-weight: 700;
}