.card.card_module {
    @extend .bg-neutrals-white;
    box-shadow: 0px 20px 50px 0px #1a1a1a0d;

    border-radius: 12px;
    overflow: hidden;

    .card_thumbnail {
        >img {
            width: 100%;
            max-height: 163px;
            object-fit: cover;
            aspect-ratio: 275/173;
        }
    }
}

.card {
    progress.card_progress {
        width: 100%;
        height: 6px;
        border: none;

        &::-webkit-progress-bar {
            @extend .bg-yellow-100;
            border-radius: 6px;
            height: 6px;
        }

        &::-webkit-progress-value {
            @extend .bg-yellow-500;
            border-radius: 6px;
            height: 6px;
        }
    }
}

.card.info_card {
    @extend .bg-neutrals-white;
    border-radius: 12px;
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    box-shadow: 0px 20px 50px 0px #0000000d;

    .info_card-icon {
        @extend .bg-green-600;
        @extend .color-neutrals-white;

        padding: 16px;
        display: inline-block;
        border-radius: 12px;
    }
}

.card.card_component {
    &-app {
        @extend .bg-blue-100;
        width: 75px;
        border-radius: 100%;
        aspect-ratio: 1;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.card.card_unit {
    border-radius: 12px;
    background-color: var(--neutrals-white);
    box-shadow: 0px 6px 16px 0px #0F0F0F14;

    figure.unit_thumbnail {

        position: relative;

        img {
            width: 100%;
            height: 100%;
            max-width: 80px;
            border-radius: 8px;
            object-fit: cover;
        }

        &::after {
            @extend .text-h2-bd;
            color: var(--red-600);
            content: attr(data-index);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--yellow-100);
            border-radius: 100%;
            aspect-ratio: 1;
            width: 40px;
            text-align: center;
            align-content: center;
        }
    }
}

.card.card_audio {
    box-shadow: 0px 6px 16px 0px #0F0F0F14;
    border-radius: 12px;
}

.card.card_quiz_cta{
    border-radius: 12px;
}

.card.card_documents{
    border-radius: 12px;

    .icon{
        color: var(--neutrals-dark);
    }

    li:not(li:last-of-type){
        border-bottom: 1px solid var(--neutrals-lighter);
    }
}