.login {
  position: relative;
  min-height: 100vh;
  height: 100%;  

  .logo {
    position: absolute;
    top: 28px;
    left: 46px;
  }

  .login_form {
    padding-inline: calc(116 / 1920 * 100 * 1vw);
    margin-left: calc(116 / 1920 * 100 * 1vw);
    flex: 0 1 calc(832 / 1920 * 100%);
  }
  .login_thumbnail {
    flex: 1 1 calc(920 / 1920 * 100%);
    padding: 24px 24px 24px 0;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    > img {
      --radius: clamp(2rem, 3.843vw, 3.75rem);
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1;
      border-radius: var(--radius) 0 var(--radius) var(--radius);
    }
  }
}
