.rich_text {
    * {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        line-height: inherit;
        background-color: inherit;
    }

    p {
        @extend .text-bd2-rg;
        margin-block: 16px;
        color: var(--neutrals-dark);
    }

    img{
        width: 100%;
        padding-block: 8px;
    }

    strong {
        font-weight: 700;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .text-h4-smbd;
        margin-block: 40px 16px;
    }



    &>*:first-child {
        margin-top: 0 !important;
        margin-bottom: 16px !important;
    }

    &>*:last-child {
        margin-top: 16px !important;
        margin-bottom: 0 !important;

    }
}