@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wc1173');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wc1173#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wc1173') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wc1173') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wc1173##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-3d-cursor {
  &:before {
    content: $icon-3d-cursor; 
  }
}
.icon-3d-cursor--alt {
  &:before {
    content: $icon-3d-cursor--alt; 
  }
}
.icon-3d-curve--auto-colon {
  &:before {
    content: $icon-3d-curve--auto-colon; 
  }
}
.icon-3d-curve--auto-vessels {
  &:before {
    content: $icon-3d-curve--auto-vessels; 
  }
}
.icon-3d-curve--manual {
  &:before {
    content: $icon-3d-curve--manual; 
  }
}
.icon-3d-ica {
  &:before {
    content: $icon-3d-ica; 
  }
}
.icon-3d-mpr-toggle {
  &:before {
    content: $icon-3d-mpr-toggle; 
  }
}
.icon-3d-print-mesh {
  &:before {
    content: $icon-3d-print-mesh; 
  }
}
.icon-3d-software {
  &:before {
    content: $icon-3d-software; 
  }
}
.icon-3rd-party-connected {
  &:before {
    content: $icon-3rd-party-connected; 
  }
}
.icon-4k {
  &:before {
    content: $icon-4k; 
  }
}
.icon-4k--filled {
  &:before {
    content: $icon-4k--filled; 
  }
}
.icon-Accessibility {
  &:before {
    content: $icon-Accessibility; 
  }
}
.icon-Accessibility--alt {
  &:before {
    content: $icon-Accessibility--alt; 
  }
}
.icon-Accessibility--color {
  &:before {
    content: $icon-Accessibility--color; 
  }
}
.icon-Accessibility--color--filled {
  &:before {
    content: $icon-Accessibility--color--filled; 
  }
}
.icon-Account {
  &:before {
    content: $icon-Account; 
  }
}
.icon-Accumulation--ice {
  &:before {
    content: $icon-Accumulation--ice; 
  }
}
.icon-Accumulation--precipitation {
  &:before {
    content: $icon-Accumulation--precipitation; 
  }
}
.icon-Accumulation--rain {
  &:before {
    content: $icon-Accumulation--rain; 
  }
}
.icon-Accumulation--snow {
  &:before {
    content: $icon-Accumulation--snow; 
  }
}
.icon-Activity {
  &:before {
    content: $icon-Activity; 
  }
}
.icon-Add {
  &:before {
    content: $icon-Add; 
  }
}
.icon-Add--alt {
  &:before {
    content: $icon-Add--alt; 
  }
}
.icon-Add-comment {
  &:before {
    content: $icon-Add-comment; 
  }
}
.icon-Add--filled {
  &:before {
    content: $icon-Add--filled; 
  }
}
.icon-Agriculture-analytics {
  &:before {
    content: $icon-Agriculture-analytics; 
  }
}
.icon-Ai-results {
  &:before {
    content: $icon-Ai-results; 
  }
}
.icon-Ai-results--high {
  &:before {
    content: $icon-Ai-results--high; 
  }
}
.icon-Ai-results--low {
  &:before {
    content: $icon-Ai-results--low; 
  }
}
.icon-Ai-results--medium {
  &:before {
    content: $icon-Ai-results--medium; 
  }
}
.icon-Ai-results--urgent {
  &:before {
    content: $icon-Ai-results--urgent; 
  }
}
.icon-Ai-results--very-high {
  &:before {
    content: $icon-Ai-results--very-high; 
  }
}
.icon-Airline--digital-gate {
  &:before {
    content: $icon-Airline--digital-gate; 
  }
}
.icon-Airline--manage-gates {
  &:before {
    content: $icon-Airline--manage-gates; 
  }
}
.icon-Airline--passenger-care {
  &:before {
    content: $icon-Airline--passenger-care; 
  }
}
.icon-Airline--rapid-board {
  &:before {
    content: $icon-Airline--rapid-board; 
  }
}
.icon-Airplay {
  &:before {
    content: $icon-Airplay; 
  }
}
.icon-Airplay--filled {
  &:before {
    content: $icon-Airplay--filled; 
  }
}
.icon-Airport--01 {
  &:before {
    content: $icon-Airport--01; 
  }
}
.icon-Airport--02 {
  &:before {
    content: $icon-Airport--02; 
  }
}
.icon-Airport-location {
  &:before {
    content: $icon-Airport-location; 
  }
}
.icon-Ai-status {
  &:before {
    content: $icon-Ai-status; 
  }
}
.icon-Ai-status--complete {
  &:before {
    content: $icon-Ai-status--complete; 
  }
}
.icon-Ai-status--failed {
  &:before {
    content: $icon-Ai-status--failed; 
  }
}
.icon-Ai-status--in-progress {
  &:before {
    content: $icon-Ai-status--in-progress; 
  }
}
.icon-Ai-status--queued {
  &:before {
    content: $icon-Ai-status--queued; 
  }
}
.icon-Ai-status--rejected {
  &:before {
    content: $icon-Ai-status--rejected; 
  }
}
.icon-Alarm {
  &:before {
    content: $icon-Alarm; 
  }
}
.icon-Alarm--add {
  &:before {
    content: $icon-Alarm--add; 
  }
}
.icon-Alarm--subtract {
  &:before {
    content: $icon-Alarm--subtract; 
  }
}
.icon-Align-box--bottom-center {
  &:before {
    content: $icon-Align-box--bottom-center; 
  }
}
.icon-Align-box--bottom-left {
  &:before {
    content: $icon-Align-box--bottom-left; 
  }
}
.icon-Align-box--bottom-right {
  &:before {
    content: $icon-Align-box--bottom-right; 
  }
}
.icon-Align-box--middle-center {
  &:before {
    content: $icon-Align-box--middle-center; 
  }
}
.icon-Align-box--middle-left {
  &:before {
    content: $icon-Align-box--middle-left; 
  }
}
.icon-Align-box--middle-right {
  &:before {
    content: $icon-Align-box--middle-right; 
  }
}
.icon-Align-box--top-center {
  &:before {
    content: $icon-Align-box--top-center; 
  }
}
.icon-Align-box--top-left {
  &:before {
    content: $icon-Align-box--top-left; 
  }
}
.icon-Align-box--top-right {
  &:before {
    content: $icon-Align-box--top-right; 
  }
}
.icon-Align--horizontal-center {
  &:before {
    content: $icon-Align--horizontal-center; 
  }
}
.icon-Align--horizontal-left {
  &:before {
    content: $icon-Align--horizontal-left; 
  }
}
.icon-Align--horizontal-right {
  &:before {
    content: $icon-Align--horizontal-right; 
  }
}
.icon-Align--vertical-bottom {
  &:before {
    content: $icon-Align--vertical-bottom; 
  }
}
.icon-Align--vertical-center {
  &:before {
    content: $icon-Align--vertical-center; 
  }
}
.icon-Align--vertical-top {
  &:before {
    content: $icon-Align--vertical-top; 
  }
}
.icon-Analytics {
  &:before {
    content: $icon-Analytics; 
  }
}
.icon-Analytics--reference {
  &:before {
    content: $icon-Analytics--reference; 
  }
}
.icon-Angle {
  &:before {
    content: $icon-Angle; 
  }
}
.icon-Annotation-visibility {
  &:before {
    content: $icon-Annotation-visibility; 
  }
}
.icon-Aperture {
  &:before {
    content: $icon-Aperture; 
  }
}
.icon-Api {
  &:before {
    content: $icon-Api; 
  }
}
.icon-Api--1 {
  &:before {
    content: $icon-Api--1; 
  }
}
.icon-App {
  &:before {
    content: $icon-App; 
  }
}
.icon-App-connectivity {
  &:before {
    content: $icon-App-connectivity; 
  }
}
.icon-Apple {
  &:before {
    content: $icon-Apple; 
  }
}
.icon-Application {
  &:before {
    content: $icon-Application; 
  }
}
.icon-Application--mobile {
  &:before {
    content: $icon-Application--mobile; 
  }
}
.icon-Application--virtual {
  &:before {
    content: $icon-Application--virtual; 
  }
}
.icon-Application--web {
  &:before {
    content: $icon-Application--web; 
  }
}
.icon-Apps {
  &:before {
    content: $icon-Apps; 
  }
}
.icon-Archive {
  &:before {
    content: $icon-Archive; 
  }
}
.icon-Area {
  &:before {
    content: $icon-Area; 
  }
}
.icon-Area--custom {
  &:before {
    content: $icon-Area--custom; 
  }
}
.icon-Arrival {
  &:before {
    content: $icon-Arrival; 
  }
}
.icon-Arrow--down {
  &:before {
    content: $icon-Arrow--down; 
  }
}
.icon-Arrow--down-left {
  &:before {
    content: $icon-Arrow--down-left; 
  }
}
.icon-Arrow--down-right {
  &:before {
    content: $icon-Arrow--down-right; 
  }
}
.icon-Arrow--left {
  &:before {
    content: $icon-Arrow--left; 
  }
}
.icon-Arrow--right {
  &:before {
    content: $icon-Arrow--right; 
  }
}
.icon-Arrow-shift-down {
  &:before {
    content: $icon-Arrow-shift-down; 
  }
}
.icon-Arrows--horizontal {
  &:before {
    content: $icon-Arrows--horizontal; 
  }
}
.icon-Arrows--vertical {
  &:before {
    content: $icon-Arrows--vertical; 
  }
}
.icon-Arrow--up {
  &:before {
    content: $icon-Arrow--up; 
  }
}
.icon-Arrow--up-left {
  &:before {
    content: $icon-Arrow--up-left; 
  }
}
.icon-Arrow--up-right {
  &:before {
    content: $icon-Arrow--up-right; 
  }
}
.icon-Asleep {
  &:before {
    content: $icon-Asleep; 
  }
}
.icon-Asleep--filled {
  &:before {
    content: $icon-Asleep--filled; 
  }
}
.icon-Asset {
  &:before {
    content: $icon-Asset; 
  }
}
.icon-Asset--confirm {
  &:before {
    content: $icon-Asset--confirm; 
  }
}
.icon-Asset--digital-twin {
  &:before {
    content: $icon-Asset--digital-twin; 
  }
}
.icon-Asset--view {
  &:before {
    content: $icon-Asset--view; 
  }
}
.icon-Asterisk {
  &:before {
    content: $icon-Asterisk; 
  }
}
.icon-At {
  &:before {
    content: $icon-At; 
  }
}
.icon-Attachment {
  &:before {
    content: $icon-Attachment; 
  }
}
.icon-Audio-console {
  &:before {
    content: $icon-Audio-console; 
  }
}
.icon-Augmented-reality {
  &:before {
    content: $icon-Augmented-reality; 
  }
}
.icon-Automatic {
  &:before {
    content: $icon-Automatic; 
  }
}
.icon-Autoscaling {
  &:before {
    content: $icon-Autoscaling; 
  }
}
.icon-Auto-scroll {
  &:before {
    content: $icon-Auto-scroll; 
  }
}
.icon-Awake {
  &:before {
    content: $icon-Awake; 
  }
}
.icon-Badge {
  &:before {
    content: $icon-Badge; 
  }
}
.icon-Baggage-claim {
  &:before {
    content: $icon-Baggage-claim; 
  }
}
.icon-Bar {
  &:before {
    content: $icon-Bar; 
  }
}
.icon-Barcode {
  &:before {
    content: $icon-Barcode; 
  }
}
.icon-Bare-metal-server {
  &:before {
    content: $icon-Bare-metal-server; 
  }
}
.icon-Bare-metal-server--01 {
  &:before {
    content: $icon-Bare-metal-server--01; 
  }
}
.icon-Bare-metal-server--02 {
  &:before {
    content: $icon-Bare-metal-server--02; 
  }
}
.icon-Barrier {
  &:before {
    content: $icon-Barrier; 
  }
}
.icon-Basketball {
  &:before {
    content: $icon-Basketball; 
  }
}
.icon-Bastion-host {
  &:before {
    content: $icon-Bastion-host; 
  }
}
.icon-Bat {
  &:before {
    content: $icon-Bat; 
  }
}
.icon-Battery--charging {
  &:before {
    content: $icon-Battery--charging; 
  }
}
.icon-Battery--empty {
  &:before {
    content: $icon-Battery--empty; 
  }
}
.icon-Battery--full {
  &:before {
    content: $icon-Battery--full; 
  }
}
.icon-Battery--half {
  &:before {
    content: $icon-Battery--half; 
  }
}
.icon-Battery--low {
  &:before {
    content: $icon-Battery--low; 
  }
}
.icon-Battery--quarter {
  &:before {
    content: $icon-Battery--quarter; 
  }
}
.icon-Bee {
  &:before {
    content: $icon-Bee; 
  }
}
.icon-Bee-bat {
  &:before {
    content: $icon-Bee-bat; 
  }
}
.icon-Bicycle {
  &:before {
    content: $icon-Bicycle; 
  }
}
.icon-Binoculars {
  &:before {
    content: $icon-Binoculars; 
  }
}
.icon-Bloch-sphere {
  &:before {
    content: $icon-Bloch-sphere; 
  }
}
.icon-Blockchain {
  &:before {
    content: $icon-Blockchain; 
  }
}
.icon-Block-storage {
  &:before {
    content: $icon-Block-storage; 
  }
}
.icon-Block-storage--alt {
  &:before {
    content: $icon-Block-storage--alt; 
  }
}
.icon-Blog {
  &:before {
    content: $icon-Blog; 
  }
}
.icon-Bluetooth {
  &:before {
    content: $icon-Bluetooth; 
  }
}
.icon-Bluetooth--off {
  &:before {
    content: $icon-Bluetooth--off; 
  }
}
.icon-Book {
  &:before {
    content: $icon-Book; 
  }
}
.icon-Bookmark {
  &:before {
    content: $icon-Bookmark; 
  }
}
.icon-Bookmark--add {
  &:before {
    content: $icon-Bookmark--add; 
  }
}
.icon-Bookmark--filled {
  &:before {
    content: $icon-Bookmark--filled; 
  }
}
.icon-Boolean {
  &:before {
    content: $icon-Boolean; 
  }
}
.icon-Boot {
  &:before {
    content: $icon-Boot; 
  }
}
.icon-Border--bottom {
  &:before {
    content: $icon-Border--bottom; 
  }
}
.icon-Border--full {
  &:before {
    content: $icon-Border--full; 
  }
}
.icon-Border--left {
  &:before {
    content: $icon-Border--left; 
  }
}
.icon-Border--none {
  &:before {
    content: $icon-Border--none; 
  }
}
.icon-Border--right {
  &:before {
    content: $icon-Border--right; 
  }
}
.icon-Border--top {
  &:before {
    content: $icon-Border--top; 
  }
}
.icon-Bot {
  &:before {
    content: $icon-Bot; 
  }
}
.icon-Box {
  &:before {
    content: $icon-Box; 
  }
}
.icon-Box--extra-large {
  &:before {
    content: $icon-Box--extra-large; 
  }
}
.icon-Box--large {
  &:before {
    content: $icon-Box--large; 
  }
}
.icon-Box--medium {
  &:before {
    content: $icon-Box--medium; 
  }
}
.icon-Box-plot {
  &:before {
    content: $icon-Box-plot; 
  }
}
.icon-Box--small {
  &:before {
    content: $icon-Box--small; 
  }
}
.icon-Branch {
  &:before {
    content: $icon-Branch; 
  }
}
.icon-Breaking-change {
  &:before {
    content: $icon-Breaking-change; 
  }
}
.icon-Brightness-contrast {
  &:before {
    content: $icon-Brightness-contrast; 
  }
}
.icon-Bring-to-front {
  &:before {
    content: $icon-Bring-to-front; 
  }
}
.icon-Brush-freehand {
  &:before {
    content: $icon-Brush-freehand; 
  }
}
.icon-Brush-polygon {
  &:before {
    content: $icon-Brush-polygon; 
  }
}
.icon-Building {
  &:before {
    content: $icon-Building; 
  }
}
.icon-Building--insights-1 {
  &:before {
    content: $icon-Building--insights-1; 
  }
}
.icon-Building--insights-2 {
  &:before {
    content: $icon-Building--insights-2; 
  }
}
.icon-Building--insights-3 {
  &:before {
    content: $icon-Building--insights-3; 
  }
}
.icon-Bullhorn {
  &:before {
    content: $icon-Bullhorn; 
  }
}
.icon-Buoy {
  &:before {
    content: $icon-Buoy; 
  }
}
.icon-Bus {
  &:before {
    content: $icon-Bus; 
  }
}
.icon-Cabin-care {
  &:before {
    content: $icon-Cabin-care; 
  }
}
.icon-Cabin-care--alert {
  &:before {
    content: $icon-Cabin-care--alert; 
  }
}
.icon-Cabin-care--alt {
  &:before {
    content: $icon-Cabin-care--alt; 
  }
}
.icon-Cad {
  &:before {
    content: $icon-Cad; 
  }
}
.icon-Cafe {
  &:before {
    content: $icon-Cafe; 
  }
}
.icon-Calculation {
  &:before {
    content: $icon-Calculation; 
  }
}
.icon-Calculation--alt {
  &:before {
    content: $icon-Calculation--alt; 
  }
}
.icon-Calculator {
  &:before {
    content: $icon-Calculator; 
  }
}
.icon-Calculator--check {
  &:before {
    content: $icon-Calculator--check; 
  }
}
.icon-Calendar {
  &:before {
    content: $icon-Calendar; 
  }
}
.icon-Calendar--heat-map {
  &:before {
    content: $icon-Calendar--heat-map; 
  }
}
.icon-Calendar--settings {
  &:before {
    content: $icon-Calendar--settings; 
  }
}
.icon-Calendar--tools {
  &:before {
    content: $icon-Calendar--tools; 
  }
}
.icon-Calibrate {
  &:before {
    content: $icon-Calibrate; 
  }
}
.icon-Camera {
  &:before {
    content: $icon-Camera; 
  }
}
.icon-Camera--action {
  &:before {
    content: $icon-Camera--action; 
  }
}
.icon-Campsite {
  &:before {
    content: $icon-Campsite; 
  }
}
.icon-Car {
  &:before {
    content: $icon-Car; 
  }
}
.icon-Carbon {
  &:before {
    content: $icon-Carbon; 
  }
}
.icon-Carbon-accounting {
  &:before {
    content: $icon-Carbon-accounting; 
  }
}
.icon-Caret--down {
  &:before {
    content: $icon-Caret--down; 
  }
}
.icon-Caret--left {
  &:before {
    content: $icon-Caret--left; 
  }
}
.icon-Caret--right {
  &:before {
    content: $icon-Caret--right; 
  }
}
.icon-Caret--sort {
  &:before {
    content: $icon-Caret--sort; 
  }
}
.icon-Caret--sort--down {
  &:before {
    content: $icon-Caret--sort--down; 
  }
}
.icon-Caret--sort--up {
  &:before {
    content: $icon-Caret--sort--up; 
  }
}
.icon-Caret--up {
  &:before {
    content: $icon-Caret--up; 
  }
}
.icon-Car--front {
  &:before {
    content: $icon-Car--front; 
  }
}
.icon-Carousel--horizontal {
  &:before {
    content: $icon-Carousel--horizontal; 
  }
}
.icon-Carousel--vertical {
  &:before {
    content: $icon-Carousel--vertical; 
  }
}
.icon-Catalog {
  &:before {
    content: $icon-Catalog; 
  }
}
.icon-Categories {
  &:before {
    content: $icon-Categories; 
  }
}
.icon-Category {
  &:before {
    content: $icon-Category; 
  }
}
.icon-Category--add {
  &:before {
    content: $icon-Category--add; 
  }
}
.icon-Category--and {
  &:before {
    content: $icon-Category--and; 
  }
}
.icon-Category--new {
  &:before {
    content: $icon-Category--new; 
  }
}
.icon-Category--new-each {
  &:before {
    content: $icon-Category--new-each; 
  }
}
.icon-Caution {
  &:before {
    content: $icon-Caution; 
  }
}
.icon-Caution-inverted {
  &:before {
    content: $icon-Caution-inverted; 
  }
}
.icon-Ccx {
  &:before {
    content: $icon-Ccx; 
  }
}
.icon-Cda {
  &:before {
    content: $icon-Cda; 
  }
}
.icon-Cd--archive {
  &:before {
    content: $icon-Cd--archive; 
  }
}
.icon-Cd--create-archive {
  &:before {
    content: $icon-Cd--create-archive; 
  }
}
.icon-Cd--create-exchange {
  &:before {
    content: $icon-Cd--create-exchange; 
  }
}
.icon-Cell-tower {
  &:before {
    content: $icon-Cell-tower; 
  }
}
.icon-Center--circle {
  &:before {
    content: $icon-Center--circle; 
  }
}
.icon-Center--square {
  &:before {
    content: $icon-Center--square; 
  }
}
.icon-Center-to-fit {
  &:before {
    content: $icon-Center-to-fit; 
  }
}
.icon-Certificate {
  &:before {
    content: $icon-Certificate; 
  }
}
.icon-Certificate--check {
  &:before {
    content: $icon-Certificate--check; 
  }
}
.icon-Change-catalog {
  &:before {
    content: $icon-Change-catalog; 
  }
}
.icon-Character-patterns {
  &:before {
    content: $icon-Character-patterns; 
  }
}
.icon-Charging-station {
  &:before {
    content: $icon-Charging-station; 
  }
}
.icon-Charging-station--filled {
  &:before {
    content: $icon-Charging-station--filled; 
  }
}
.icon-Chart--3d {
  &:before {
    content: $icon-Chart--3d; 
  }
}
.icon-Chart--area {
  &:before {
    content: $icon-Chart--area; 
  }
}
.icon-Chart--area-smooth {
  &:before {
    content: $icon-Chart--area-smooth; 
  }
}
.icon-Chart--area-stepper {
  &:before {
    content: $icon-Chart--area-stepper; 
  }
}
.icon-Chart--average {
  &:before {
    content: $icon-Chart--average; 
  }
}
.icon-Chart--bar {
  &:before {
    content: $icon-Chart--bar; 
  }
}
.icon-Chart--bar-floating {
  &:before {
    content: $icon-Chart--bar-floating; 
  }
}
.icon-Chart--bar-overlay {
  &:before {
    content: $icon-Chart--bar-overlay; 
  }
}
.icon-Chart--bar-stacked {
  &:before {
    content: $icon-Chart--bar-stacked; 
  }
}
.icon-Chart--bar-target {
  &:before {
    content: $icon-Chart--bar-target; 
  }
}
.icon-Chart--bubble {
  &:before {
    content: $icon-Chart--bubble; 
  }
}
.icon-Chart--bubble-packed {
  &:before {
    content: $icon-Chart--bubble-packed; 
  }
}
.icon-Chart--bullet {
  &:before {
    content: $icon-Chart--bullet; 
  }
}
.icon-Chart--candlestick {
  &:before {
    content: $icon-Chart--candlestick; 
  }
}
.icon-Chart--cluster-bar {
  &:before {
    content: $icon-Chart--cluster-bar; 
  }
}
.icon-Chart--column {
  &:before {
    content: $icon-Chart--column; 
  }
}
.icon-Chart--column-floating {
  &:before {
    content: $icon-Chart--column-floating; 
  }
}
.icon-Chart--column-target {
  &:before {
    content: $icon-Chart--column-target; 
  }
}
.icon-Chart--combo {
  &:before {
    content: $icon-Chart--combo; 
  }
}
.icon-Chart--combo-stacked {
  &:before {
    content: $icon-Chart--combo-stacked; 
  }
}
.icon-Chart--custom {
  &:before {
    content: $icon-Chart--custom; 
  }
}
.icon-Chart--error-bar {
  &:before {
    content: $icon-Chart--error-bar; 
  }
}
.icon-Chart--error-bar--alt {
  &:before {
    content: $icon-Chart--error-bar--alt; 
  }
}
.icon-Chart--evaluation {
  &:before {
    content: $icon-Chart--evaluation; 
  }
}
.icon-Chart--high-low {
  &:before {
    content: $icon-Chart--high-low; 
  }
}
.icon-Chart--histogram {
  &:before {
    content: $icon-Chart--histogram; 
  }
}
.icon-Chart--line {
  &:before {
    content: $icon-Chart--line; 
  }
}
.icon-Chart--line--data {
  &:before {
    content: $icon-Chart--line--data; 
  }
}
.icon-Chart--line-smooth {
  &:before {
    content: $icon-Chart--line-smooth; 
  }
}
.icon-Chart--marimekko {
  &:before {
    content: $icon-Chart--marimekko; 
  }
}
.icon-Chart--maximum {
  &:before {
    content: $icon-Chart--maximum; 
  }
}
.icon-Chart--median {
  &:before {
    content: $icon-Chart--median; 
  }
}
.icon-Chart--minimum {
  &:before {
    content: $icon-Chart--minimum; 
  }
}
.icon-Chart--multi-line {
  &:before {
    content: $icon-Chart--multi-line; 
  }
}
.icon-Chart--multitype {
  &:before {
    content: $icon-Chart--multitype; 
  }
}
.icon-Chart--network {
  &:before {
    content: $icon-Chart--network; 
  }
}
.icon-Chart--parallel {
  &:before {
    content: $icon-Chart--parallel; 
  }
}
.icon-Chart--pie {
  &:before {
    content: $icon-Chart--pie; 
  }
}
.icon-Chart--point {
  &:before {
    content: $icon-Chart--point; 
  }
}
.icon-Chart--population {
  &:before {
    content: $icon-Chart--population; 
  }
}
.icon-Chart--radar {
  &:before {
    content: $icon-Chart--radar; 
  }
}
.icon-Chart--radial {
  &:before {
    content: $icon-Chart--radial; 
  }
}
.icon-Chart--relationship {
  &:before {
    content: $icon-Chart--relationship; 
  }
}
.icon-Chart--ring {
  &:before {
    content: $icon-Chart--ring; 
  }
}
.icon-Chart--river {
  &:before {
    content: $icon-Chart--river; 
  }
}
.icon-Chart--rose {
  &:before {
    content: $icon-Chart--rose; 
  }
}
.icon-Chart--scatter {
  &:before {
    content: $icon-Chart--scatter; 
  }
}
.icon-Chart--spiral {
  &:before {
    content: $icon-Chart--spiral; 
  }
}
.icon-Chart--stacked {
  &:before {
    content: $icon-Chart--stacked; 
  }
}
.icon-Chart--stepper {
  &:before {
    content: $icon-Chart--stepper; 
  }
}
.icon-Chart--sunburst {
  &:before {
    content: $icon-Chart--sunburst; 
  }
}
.icon-Chart--treemap {
  &:before {
    content: $icon-Chart--treemap; 
  }
}
.icon-Chart--t-sne {
  &:before {
    content: $icon-Chart--t-sne; 
  }
}
.icon-Chart--venn-diagram {
  &:before {
    content: $icon-Chart--venn-diagram; 
  }
}
.icon-Chart--violin-plot {
  &:before {
    content: $icon-Chart--violin-plot; 
  }
}
.icon-Chart--waterfall {
  &:before {
    content: $icon-Chart--waterfall; 
  }
}
.icon-Chart--win-loss {
  &:before {
    content: $icon-Chart--win-loss; 
  }
}
.icon-Chat {
  &:before {
    content: $icon-Chat; 
  }
}
.icon-Chat-bot {
  &:before {
    content: $icon-Chat-bot; 
  }
}
.icon-Chat--launch {
  &:before {
    content: $icon-Chat--launch; 
  }
}
.icon-Chat--operational {
  &:before {
    content: $icon-Chat--operational; 
  }
}
.icon-Checkbox {
  &:before {
    content: $icon-Checkbox; 
  }
}
.icon-Checkbox--checked {
  &:before {
    content: $icon-Checkbox--checked; 
  }
}
.icon-Checkbox--checked--filled {
  &:before {
    content: $icon-Checkbox--checked--filled; 
  }
}
.icon-Checkbox--indeterminate {
  &:before {
    content: $icon-Checkbox--indeterminate; 
  }
}
.icon-Checkbox--indeterminate--filled {
  &:before {
    content: $icon-Checkbox--indeterminate--filled; 
  }
}
.icon-Checkmark {
  &:before {
    content: $icon-Checkmark; 
  }
}
.icon-Checkmark--filled {
  &:before {
    content: $icon-Checkmark--filled; 
  }
}
.icon-Checkmark--filled--error {
  &:before {
    content: $icon-Checkmark--filled--error; 
  }
}
.icon-Checkmark--filled--warning {
  &:before {
    content: $icon-Checkmark--filled--warning; 
  }
}
.icon-Checkmark--outline {
  &:before {
    content: $icon-Checkmark--outline; 
  }
}
.icon-Checkmark--outline--error {
  &:before {
    content: $icon-Checkmark--outline--error; 
  }
}
.icon-Checkmark--outline--warning {
  &:before {
    content: $icon-Checkmark--outline--warning; 
  }
}
.icon-Chemistry {
  &:before {
    content: $icon-Chemistry; 
  }
}
.icon-Chemistry--reference {
  &:before {
    content: $icon-Chemistry--reference; 
  }
}
.icon-Chevron--down {
  &:before {
    content: $icon-Chevron--down; 
  }
}
.icon-Chevron--left {
  &:before {
    content: $icon-Chevron--left; 
  }
}
.icon-Chevron--mini {
  &:before {
    content: $icon-Chevron--mini; 
  }
}
.icon-Chevron--right {
  &:before {
    content: $icon-Chevron--right; 
  }
}
.icon-Chevron--sort {
  &:before {
    content: $icon-Chevron--sort; 
  }
}
.icon-Chevron--sort--down {
  &:before {
    content: $icon-Chevron--sort--down; 
  }
}
.icon-Chevron--sort--up {
  &:before {
    content: $icon-Chevron--sort--up; 
  }
}
.icon-Chevron--up {
  &:before {
    content: $icon-Chevron--up; 
  }
}
.icon-Chip {
  &:before {
    content: $icon-Chip; 
  }
}
.icon-Choices {
  &:before {
    content: $icon-Choices; 
  }
}
.icon-Choose-item {
  &:before {
    content: $icon-Choose-item; 
  }
}
.icon-Choropleth-map {
  &:before {
    content: $icon-Choropleth-map; 
  }
}
.icon-Circle-dash {
  &:before {
    content: $icon-Circle-dash; 
  }
}
.icon-Circle-fill {
  &:before {
    content: $icon-Circle-fill; 
  }
}
.icon-Circle--filled {
  &:before {
    content: $icon-Circle--filled; 
  }
}
.icon-Circle-measurement {
  &:before {
    content: $icon-Circle-measurement; 
  }
}
.icon-Circle-packing {
  &:before {
    content: $icon-Circle-packing; 
  }
}
.icon-Circle-stroke {
  &:before {
    content: $icon-Circle-stroke; 
  }
}
.icon-Circuit-composer {
  &:before {
    content: $icon-Circuit-composer; 
  }
}
.icon-Classification {
  &:before {
    content: $icon-Classification; 
  }
}
.icon-Classifier--language {
  &:before {
    content: $icon-Classifier--language; 
  }
}
.icon-Clean {
  &:before {
    content: $icon-Clean; 
  }
}
.icon-Close {
  &:before {
    content: $icon-Close; 
  }
}
.icon-Closed-caption {
  &:before {
    content: $icon-Closed-caption; 
  }
}
.icon-Closed-caption--alt {
  &:before {
    content: $icon-Closed-caption--alt; 
  }
}
.icon-Closed-caption--filled {
  &:before {
    content: $icon-Closed-caption--filled; 
  }
}
.icon-Close--filled {
  &:before {
    content: $icon-Close--filled; 
  }
}
.icon-Close--outline {
  &:before {
    content: $icon-Close--outline; 
  }
}
.icon-Cloud {
  &:before {
    content: $icon-Cloud; 
  }
}
.icon-Cloud--alerting {
  &:before {
    content: $icon-Cloud--alerting; 
  }
}
.icon-Cloud-app {
  &:before {
    content: $icon-Cloud-app; 
  }
}
.icon-Cloud--auditing {
  &:before {
    content: $icon-Cloud--auditing; 
  }
}
.icon-Cloud-ceiling {
  &:before {
    content: $icon-Cloud-ceiling; 
  }
}
.icon-Cloud--data-ops {
  &:before {
    content: $icon-Cloud--data-ops; 
  }
}
.icon-Cloud--download {
  &:before {
    content: $icon-Cloud--download; 
  }
}
.icon-Cloud-foundry--1 {
  &:before {
    content: $icon-Cloud-foundry--1; 
  }
}
.icon-Cloud-foundry--2 {
  &:before {
    content: $icon-Cloud-foundry--2; 
  }
}
.icon-Cloud--logging {
  &:before {
    content: $icon-Cloud--logging; 
  }
}
.icon-Cloud--monitoring {
  &:before {
    content: $icon-Cloud--monitoring; 
  }
}
.icon-Cloud-registry {
  &:before {
    content: $icon-Cloud-registry; 
  }
}
.icon-Cloud-satellite {
  &:before {
    content: $icon-Cloud-satellite; 
  }
}
.icon-Cloud--service-management {
  &:before {
    content: $icon-Cloud--service-management; 
  }
}
.icon-Cloud-services {
  &:before {
    content: $icon-Cloud-services; 
  }
}
.icon-Cloud--upload {
  &:before {
    content: $icon-Cloud--upload; 
  }
}
.icon-Cloudy {
  &:before {
    content: $icon-Cloudy; 
  }
}
.icon-Cobb-angle {
  &:before {
    content: $icon-Cobb-angle; 
  }
}
.icon-Code {
  &:before {
    content: $icon-Code; 
  }
}
.icon-Code--hide {
  &:before {
    content: $icon-Code--hide; 
  }
}
.icon-Code--reference {
  &:before {
    content: $icon-Code--reference; 
  }
}
.icon-Code-signing-service {
  &:before {
    content: $icon-Code-signing-service; 
  }
}
.icon-Cognitive {
  &:before {
    content: $icon-Cognitive; 
  }
}
.icon-Collaborate {
  &:before {
    content: $icon-Collaborate; 
  }
}
.icon-Collapse-all {
  &:before {
    content: $icon-Collapse-all; 
  }
}
.icon-Collapse-categories {
  &:before {
    content: $icon-Collapse-categories; 
  }
}
.icon-Color-palette {
  &:before {
    content: $icon-Color-palette; 
  }
}
.icon-Color-switch {
  &:before {
    content: $icon-Color-switch; 
  }
}
.icon-Column {
  &:before {
    content: $icon-Column; 
  }
}
.icon-Column--delete {
  &:before {
    content: $icon-Column--delete; 
  }
}
.icon-Column-dependency {
  &:before {
    content: $icon-Column-dependency; 
  }
}
.icon-Column--insert {
  &:before {
    content: $icon-Column--insert; 
  }
}
.icon-Commit {
  &:before {
    content: $icon-Commit; 
  }
}
.icon-Communication--unified {
  &:before {
    content: $icon-Communication--unified; 
  }
}
.icon-Compare {
  &:before {
    content: $icon-Compare; 
  }
}
.icon-Compass {
  &:before {
    content: $icon-Compass; 
  }
}
.icon-Composer-edit {
  &:before {
    content: $icon-Composer-edit; 
  }
}
.icon-Concept {
  &:before {
    content: $icon-Concept; 
  }
}
.icon-Condition--point {
  &:before {
    content: $icon-Condition--point; 
  }
}
.icon-Condition--wait-point {
  &:before {
    content: $icon-Condition--wait-point; 
  }
}
.icon-Connect {
  &:before {
    content: $icon-Connect; 
  }
}
.icon-Connection--receive {
  &:before {
    content: $icon-Connection--receive; 
  }
}
.icon-Connection--send {
  &:before {
    content: $icon-Connection--send; 
  }
}
.icon-Connection-signal {
  &:before {
    content: $icon-Connection-signal; 
  }
}
.icon-Connection-signal--off {
  &:before {
    content: $icon-Connection-signal--off; 
  }
}
.icon-Connection--two-way {
  &:before {
    content: $icon-Connection--two-way; 
  }
}
.icon-Connect--recursive {
  &:before {
    content: $icon-Connect--recursive; 
  }
}
.icon-Connect--source {
  &:before {
    content: $icon-Connect--source; 
  }
}
.icon-Connect--target {
  &:before {
    content: $icon-Connect--target; 
  }
}
.icon-Construction {
  &:before {
    content: $icon-Construction; 
  }
}
.icon-Container-registry {
  &:before {
    content: $icon-Container-registry; 
  }
}
.icon-Container-services {
  &:before {
    content: $icon-Container-services; 
  }
}
.icon-Container-software {
  &:before {
    content: $icon-Container-software; 
  }
}
.icon-Content-delivery-network {
  &:before {
    content: $icon-Content-delivery-network; 
  }
}
.icon-Content-view {
  &:before {
    content: $icon-Content-view; 
  }
}
.icon-Continue {
  &:before {
    content: $icon-Continue; 
  }
}
.icon-Continue--filled {
  &:before {
    content: $icon-Continue--filled; 
  }
}
.icon-Contour--draw {
  &:before {
    content: $icon-Contour--draw; 
  }
}
.icon-Contour--edit {
  &:before {
    content: $icon-Contour--edit; 
  }
}
.icon-Contour-finding {
  &:before {
    content: $icon-Contour-finding; 
  }
}
.icon-Contrast {
  &:before {
    content: $icon-Contrast; 
  }
}
.icon-Convert-to-cloud {
  &:before {
    content: $icon-Convert-to-cloud; 
  }
}
.icon-Copy {
  &:before {
    content: $icon-Copy; 
  }
}
.icon-Copy--file {
  &:before {
    content: $icon-Copy--file; 
  }
}
.icon-Copy--link {
  &:before {
    content: $icon-Copy--link; 
  }
}
.icon-Corn {
  &:before {
    content: $icon-Corn; 
  }
}
.icon-Corner {
  &:before {
    content: $icon-Corner; 
  }
}
.icon-Coronavirus {
  &:before {
    content: $icon-Coronavirus; 
  }
}
.icon-Cost {
  &:before {
    content: $icon-Cost; 
  }
}
.icon-Cost--total {
  &:before {
    content: $icon-Cost--total; 
  }
}
.icon-Cough {
  &:before {
    content: $icon-Cough; 
  }
}
.icon-Course {
  &:before {
    content: $icon-Course; 
  }
}
.icon-Covariate {
  &:before {
    content: $icon-Covariate; 
  }
}
.icon-Credentials {
  &:before {
    content: $icon-Credentials; 
  }
}
.icon-Critical {
  &:before {
    content: $icon-Critical; 
  }
}
.icon-Critical-severity {
  &:before {
    content: $icon-Critical-severity; 
  }
}
.icon-Crop {
  &:before {
    content: $icon-Crop; 
  }
}
.icon-Crop-growth {
  &:before {
    content: $icon-Crop-growth; 
  }
}
.icon-Crop-health {
  &:before {
    content: $icon-Crop-health; 
  }
}
.icon-Cross-reference {
  &:before {
    content: $icon-Cross-reference; 
  }
}
.icon-Crossroads {
  &:before {
    content: $icon-Crossroads; 
  }
}
.icon-Cross-tab {
  &:before {
    content: $icon-Cross-tab; 
  }
}
.icon-Crowd-report {
  &:before {
    content: $icon-Crowd-report; 
  }
}
.icon-Crowd-report--filled {
  &:before {
    content: $icon-Crowd-report--filled; 
  }
}
.icon-Csv {
  &:before {
    content: $icon-Csv; 
  }
}
.icon-Cu1 {
  &:before {
    content: $icon-Cu1; 
  }
}
.icon-Cu3 {
  &:before {
    content: $icon-Cu3; 
  }
}
.icon-Cube {
  &:before {
    content: $icon-Cube; 
  }
}
.icon-Cube-view {
  &:before {
    content: $icon-Cube-view; 
  }
}
.icon-Currency {
  &:before {
    content: $icon-Currency; 
  }
}
.icon-Currency--baht {
  &:before {
    content: $icon-Currency--baht; 
  }
}
.icon-Currency--dollar {
  &:before {
    content: $icon-Currency--dollar; 
  }
}
.icon-Currency--euro {
  &:before {
    content: $icon-Currency--euro; 
  }
}
.icon-Currency--lira {
  &:before {
    content: $icon-Currency--lira; 
  }
}
.icon-Currency--pound {
  &:before {
    content: $icon-Currency--pound; 
  }
}
.icon-Currency--ruble {
  &:before {
    content: $icon-Currency--ruble; 
  }
}
.icon-Currency--rupee {
  &:before {
    content: $icon-Currency--rupee; 
  }
}
.icon-Currency--shekel {
  &:before {
    content: $icon-Currency--shekel; 
  }
}
.icon-Currency--won {
  &:before {
    content: $icon-Currency--won; 
  }
}
.icon-Currency--yen {
  &:before {
    content: $icon-Currency--yen; 
  }
}
.icon-Cursor--1 {
  &:before {
    content: $icon-Cursor--1; 
  }
}
.icon-Cursor--2 {
  &:before {
    content: $icon-Cursor--2; 
  }
}
.icon-Cut {
  &:before {
    content: $icon-Cut; 
  }
}
.icon-Cut-in-half {
  &:before {
    content: $icon-Cut-in-half; 
  }
}
.icon-Cut-out {
  &:before {
    content: $icon-Cut-out; 
  }
}
.icon-Cy {
  &:before {
    content: $icon-Cy; 
  }
}
.icon-Cyclist {
  &:before {
    content: $icon-Cyclist; 
  }
}
.icon-Cz {
  &:before {
    content: $icon-Cz; 
  }
}
.icon-Dashboard {
  &:before {
    content: $icon-Dashboard; 
  }
}
.icon-Dashboard--reference {
  &:before {
    content: $icon-Dashboard--reference; 
  }
}
.icon-Data--1 {
  &:before {
    content: $icon-Data--1; 
  }
}
.icon-Data--2 {
  &:before {
    content: $icon-Data--2; 
  }
}
.icon-Data-accessor {
  &:before {
    content: $icon-Data-accessor; 
  }
}
.icon-Data-backup {
  &:before {
    content: $icon-Data-backup; 
  }
}
.icon-Data--base {
  &:before {
    content: $icon-Data--base; 
  }
}
.icon-Data--base--alt {
  &:before {
    content: $icon-Data--base--alt; 
  }
}
.icon-Database--datastax {
  &:before {
    content: $icon-Database--datastax; 
  }
}
.icon-Database--elastic {
  &:before {
    content: $icon-Database--elastic; 
  }
}
.icon-Database--enterprise-db2 {
  &:before {
    content: $icon-Database--enterprise-db2; 
  }
}
.icon-Database--etcd {
  &:before {
    content: $icon-Database--etcd; 
  }
}
.icon-Database--mongodb {
  &:before {
    content: $icon-Database--mongodb; 
  }
}
.icon-Database--postgresql {
  &:before {
    content: $icon-Database--postgresql; 
  }
}
.icon-Database--rabbit {
  &:before {
    content: $icon-Database--rabbit; 
  }
}
.icon-Database--redis {
  &:before {
    content: $icon-Database--redis; 
  }
}
.icon-Data-bin {
  &:before {
    content: $icon-Data-bin; 
  }
}
.icon-Data-blob {
  &:before {
    content: $icon-Data-blob; 
  }
}
.icon-Data--center {
  &:before {
    content: $icon-Data--center; 
  }
}
.icon-Data--check {
  &:before {
    content: $icon-Data--check; 
  }
}
.icon-Data-class {
  &:before {
    content: $icon-Data-class; 
  }
}
.icon-Data-collection {
  &:before {
    content: $icon-Data-collection; 
  }
}
.icon-Data--connected {
  &:before {
    content: $icon-Data--connected; 
  }
}
.icon-Data-diode {
  &:before {
    content: $icon-Data-diode; 
  }
}
.icon-Data--error {
  &:before {
    content: $icon-Data--error; 
  }
}
.icon-Data--format {
  &:before {
    content: $icon-Data--format; 
  }
}
.icon-Data-player {
  &:before {
    content: $icon-Data-player; 
  }
}
.icon-Data--reference {
  &:before {
    content: $icon-Data--reference; 
  }
}
.icon-Data-refinery {
  &:before {
    content: $icon-Data-refinery; 
  }
}
.icon-Data-refinery--reference {
  &:before {
    content: $icon-Data-refinery--reference; 
  }
}
.icon-Data--set {
  &:before {
    content: $icon-Data--set; 
  }
}
.icon-Data-share {
  &:before {
    content: $icon-Data-share; 
  }
}
.icon-Datastore {
  &:before {
    content: $icon-Datastore; 
  }
}
.icon-Data--structured {
  &:before {
    content: $icon-Data--structured; 
  }
}
.icon-Data-table {
  &:before {
    content: $icon-Data-table; 
  }
}
.icon-Data-table--reference {
  &:before {
    content: $icon-Data-table--reference; 
  }
}
.icon-Data--unstructured {
  &:before {
    content: $icon-Data--unstructured; 
  }
}
.icon-Data--view {
  &:before {
    content: $icon-Data--view; 
  }
}
.icon-Data--view--alt {
  &:before {
    content: $icon-Data--view--alt; 
  }
}
.icon-Data-vis--1 {
  &:before {
    content: $icon-Data-vis--1; 
  }
}
.icon-Data-vis--2 {
  &:before {
    content: $icon-Data-vis--2; 
  }
}
.icon-Data-vis--3 {
  &:before {
    content: $icon-Data-vis--3; 
  }
}
.icon-Data-vis--4 {
  &:before {
    content: $icon-Data-vis--4; 
  }
}
.icon-Debug {
  &:before {
    content: $icon-Debug; 
  }
}
.icon-Decision-tree {
  &:before {
    content: $icon-Decision-tree; 
  }
}
.icon-Delivery {
  &:before {
    content: $icon-Delivery; 
  }
}
.icon-Delivery--add {
  &:before {
    content: $icon-Delivery--add; 
  }
}
.icon-Delivery--parcel {
  &:before {
    content: $icon-Delivery--parcel; 
  }
}
.icon-Delivery-truck {
  &:before {
    content: $icon-Delivery-truck; 
  }
}
.icon-Denominate {
  &:before {
    content: $icon-Denominate; 
  }
}
.icon-Departure {
  &:before {
    content: $icon-Departure; 
  }
}
.icon-Deploy {
  &:before {
    content: $icon-Deploy; 
  }
}
.icon-Deployment-pattern {
  &:before {
    content: $icon-Deployment-pattern; 
  }
}
.icon-Deployment-policy {
  &:before {
    content: $icon-Deployment-policy; 
  }
}
.icon-Deployment-unit--data {
  &:before {
    content: $icon-Deployment-unit--data; 
  }
}
.icon-Deployment-unit--execution {
  &:before {
    content: $icon-Deployment-unit--execution; 
  }
}
.icon-Deployment-unit--installation1 {
  &:before {
    content: $icon-Deployment-unit--installation1; 
  }
}
.icon-Deployment-unit--presentation {
  &:before {
    content: $icon-Deployment-unit--presentation; 
  }
}
.icon-Deployment-unit--technical--data {
  &:before {
    content: $icon-Deployment-unit--technical--data; 
  }
}
.icon-Deployment-unit--technical--execution {
  &:before {
    content: $icon-Deployment-unit--technical--execution; 
  }
}
.icon-Deployment-unit--technical--installation {
  &:before {
    content: $icon-Deployment-unit--technical--installation; 
  }
}
.icon-Deployment-unit--technical--presentation {
  &:before {
    content: $icon-Deployment-unit--technical--presentation; 
  }
}
.icon-Deploy-rules {
  &:before {
    content: $icon-Deploy-rules; 
  }
}
.icon-Desk--adjustable {
  &:before {
    content: $icon-Desk--adjustable; 
  }
}
.icon-Development {
  &:before {
    content: $icon-Development; 
  }
}
.icon-Devices {
  &:before {
    content: $icon-Devices; 
  }
}
.icon-Dew-point {
  &:before {
    content: $icon-Dew-point; 
  }
}
.icon-Dew-point--filled {
  &:before {
    content: $icon-Dew-point--filled; 
  }
}
.icon-Diagram {
  &:before {
    content: $icon-Diagram; 
  }
}
.icon-Diagram--reference {
  &:before {
    content: $icon-Diagram--reference; 
  }
}
.icon-Dicom--6000 {
  &:before {
    content: $icon-Dicom--6000; 
  }
}
.icon-Dicom--overlay {
  &:before {
    content: $icon-Dicom--overlay; 
  }
}
.icon-Direction--bear-right--01 {
  &:before {
    content: $icon-Direction--bear-right--01; 
  }
}
.icon-Direction--bear-right--01--filled {
  &:before {
    content: $icon-Direction--bear-right--01--filled; 
  }
}
.icon-Direction--bear-right--02 {
  &:before {
    content: $icon-Direction--bear-right--02; 
  }
}
.icon-Direction--bear-right--02--filled {
  &:before {
    content: $icon-Direction--bear-right--02--filled; 
  }
}
.icon-Direction--curve {
  &:before {
    content: $icon-Direction--curve; 
  }
}
.icon-Direction--curve--filled {
  &:before {
    content: $icon-Direction--curve--filled; 
  }
}
.icon-Direction--fork {
  &:before {
    content: $icon-Direction--fork; 
  }
}
.icon-Direction--fork--filled {
  &:before {
    content: $icon-Direction--fork--filled; 
  }
}
.icon-Direction--loop-left {
  &:before {
    content: $icon-Direction--loop-left; 
  }
}
.icon-Direction--loop-left--filled {
  &:before {
    content: $icon-Direction--loop-left--filled; 
  }
}
.icon-Direction--loop-right {
  &:before {
    content: $icon-Direction--loop-right; 
  }
}
.icon-Direction--loop-right--filled {
  &:before {
    content: $icon-Direction--loop-right--filled; 
  }
}
.icon-Direction--merge {
  &:before {
    content: $icon-Direction--merge; 
  }
}
.icon-Direction--merge--filled {
  &:before {
    content: $icon-Direction--merge--filled; 
  }
}
.icon-Direction--right--01 {
  &:before {
    content: $icon-Direction--right--01; 
  }
}
.icon-Direction--right--01--filled {
  &:before {
    content: $icon-Direction--right--01--filled; 
  }
}
.icon-Direction--right--02 {
  &:before {
    content: $icon-Direction--right--02; 
  }
}
.icon-Direction--right--02--filled {
  &:before {
    content: $icon-Direction--right--02--filled; 
  }
}
.icon-Direction--rotary--first-right {
  &:before {
    content: $icon-Direction--rotary--first-right; 
  }
}
.icon-Direction--rotary--first-right--filled {
  &:before {
    content: $icon-Direction--rotary--first-right--filled; 
  }
}
.icon-Direction--rotary--right {
  &:before {
    content: $icon-Direction--rotary--right; 
  }
}
.icon-Direction--rotary--right--filled {
  &:before {
    content: $icon-Direction--rotary--right--filled; 
  }
}
.icon-Direction--rotary--straight {
  &:before {
    content: $icon-Direction--rotary--straight; 
  }
}
.icon-Direction--rotary--straight--filled {
  &:before {
    content: $icon-Direction--rotary--straight--filled; 
  }
}
.icon-Direction--sharp-turn {
  &:before {
    content: $icon-Direction--sharp-turn; 
  }
}
.icon-Direction--sharp-turn--filled {
  &:before {
    content: $icon-Direction--sharp-turn--filled; 
  }
}
.icon-Direction--straight {
  &:before {
    content: $icon-Direction--straight; 
  }
}
.icon-Direction--straight--filled {
  &:before {
    content: $icon-Direction--straight--filled; 
  }
}
.icon-Direction--straight--right {
  &:before {
    content: $icon-Direction--straight--right; 
  }
}
.icon-Direction--straight--right--filled {
  &:before {
    content: $icon-Direction--straight--right--filled; 
  }
}
.icon-Direction--u-turn {
  &:before {
    content: $icon-Direction--u-turn; 
  }
}
.icon-Direction--u-turn--filled {
  &:before {
    content: $icon-Direction--u-turn--filled; 
  }
}
.icon-Direct-link {
  &:before {
    content: $icon-Direct-link; 
  }
}
.icon-Directory-domain {
  &:before {
    content: $icon-Directory-domain; 
  }
}
.icon-Distribute--horizontal-center {
  &:before {
    content: $icon-Distribute--horizontal-center; 
  }
}
.icon-Distribute--horizontal-left {
  &:before {
    content: $icon-Distribute--horizontal-left; 
  }
}
.icon-Distribute--horizontal-right {
  &:before {
    content: $icon-Distribute--horizontal-right; 
  }
}
.icon-Distribute--vertical-bottom {
  &:before {
    content: $icon-Distribute--vertical-bottom; 
  }
}
.icon-Distribute--vertical-center {
  &:before {
    content: $icon-Distribute--vertical-center; 
  }
}
.icon-Distribute--vertical-top {
  &:before {
    content: $icon-Distribute--vertical-top; 
  }
}
.icon-Dna {
  &:before {
    content: $icon-Dna; 
  }
}
.icon-Dns-services {
  &:before {
    content: $icon-Dns-services; 
  }
}
.icon-Doc {
  &:before {
    content: $icon-Doc; 
  }
}
.icon-Document {
  &:before {
    content: $icon-Document; 
  }
}
.icon-Document--add {
  &:before {
    content: $icon-Document--add; 
  }
}
.icon-Document--attachment {
  &:before {
    content: $icon-Document--attachment; 
  }
}
.icon-Document--audio {
  &:before {
    content: $icon-Document--audio; 
  }
}
.icon-Document--blank {
  &:before {
    content: $icon-Document--blank; 
  }
}
.icon-Document--download {
  &:before {
    content: $icon-Document--download; 
  }
}
.icon-Document--epdf {
  &:before {
    content: $icon-Document--epdf; 
  }
}
.icon-Document--export {
  &:before {
    content: $icon-Document--export; 
  }
}
.icon-Document--horizontal {
  &:before {
    content: $icon-Document--horizontal; 
  }
}
.icon-Document--import {
  &:before {
    content: $icon-Document--import; 
  }
}
.icon-Document--pdf {
  &:before {
    content: $icon-Document--pdf; 
  }
}
.icon-Document--protected {
  &:before {
    content: $icon-Document--protected; 
  }
}
.icon-Document--security {
  &:before {
    content: $icon-Document--security; 
  }
}
.icon-Document-sentiment {
  &:before {
    content: $icon-Document-sentiment; 
  }
}
.icon-Document--signed {
  &:before {
    content: $icon-Document--signed; 
  }
}
.icon-Document--sketch {
  &:before {
    content: $icon-Document--sketch; 
  }
}
.icon-Document--subtract {
  &:before {
    content: $icon-Document--subtract; 
  }
}
.icon-Document--tasks {
  &:before {
    content: $icon-Document--tasks; 
  }
}
.icon-Document--unknown {
  &:before {
    content: $icon-Document--unknown; 
  }
}
.icon-Document--unprotected {
  &:before {
    content: $icon-Document--unprotected; 
  }
}
.icon-Document--vertical {
  &:before {
    content: $icon-Document--vertical; 
  }
}
.icon-Document--video {
  &:before {
    content: $icon-Document--video; 
  }
}
.icon-Document--view {
  &:before {
    content: $icon-Document--view; 
  }
}
.icon-Document--word-processor {
  &:before {
    content: $icon-Document--word-processor; 
  }
}
.icon-Document--word-processor--reference {
  &:before {
    content: $icon-Document--word-processor--reference; 
  }
}
.icon-Dog-walker {
  &:before {
    content: $icon-Dog-walker; 
  }
}
.icon-Dot-mark {
  &:before {
    content: $icon-Dot-mark; 
  }
}
.icon-Double-integer {
  &:before {
    content: $icon-Double-integer; 
  }
}
.icon-Download {
  &:before {
    content: $icon-Download; 
  }
}
.icon-Download-study {
  &:before {
    content: $icon-Download-study; 
  }
}
.icon-Down-to-bottom {
  &:before {
    content: $icon-Down-to-bottom; 
  }
}
.icon-Draggable {
  &:before {
    content: $icon-Draggable; 
  }
}
.icon-Drag--horizontal {
  &:before {
    content: $icon-Drag--horizontal; 
  }
}
.icon-Drag--vertical {
  &:before {
    content: $icon-Drag--vertical; 
  }
}
.icon-Draw {
  &:before {
    content: $icon-Draw; 
  }
}
.icon-Drill-back {
  &:before {
    content: $icon-Drill-back; 
  }
}
.icon-Drill-down {
  &:before {
    content: $icon-Drill-down; 
  }
}
.icon-Drill-through {
  &:before {
    content: $icon-Drill-through; 
  }
}
.icon-Driver-analysis {
  &:before {
    content: $icon-Driver-analysis; 
  }
}
.icon-Drone {
  &:before {
    content: $icon-Drone; 
  }
}
.icon-Drone--delivery {
  &:before {
    content: $icon-Drone--delivery; 
  }
}
.icon-Drone--front {
  &:before {
    content: $icon-Drone--front; 
  }
}
.icon-Drone--video {
  &:before {
    content: $icon-Drone--video; 
  }
}
.icon-Drop-photo {
  &:before {
    content: $icon-Drop-photo; 
  }
}
.icon-Drop-photo--filled {
  &:before {
    content: $icon-Drop-photo--filled; 
  }
}
.icon-Drought {
  &:before {
    content: $icon-Drought; 
  }
}
.icon-Dvr {
  &:before {
    content: $icon-Dvr; 
  }
}
.icon-Earth {
  &:before {
    content: $icon-Earth; 
  }
}
.icon-Earth--americas {
  &:before {
    content: $icon-Earth--americas; 
  }
}
.icon-Earth--americas--filled {
  &:before {
    content: $icon-Earth--americas--filled; 
  }
}
.icon-Earth--europe-africa {
  &:before {
    content: $icon-Earth--europe-africa; 
  }
}
.icon-Earth--europe-africa--filled {
  &:before {
    content: $icon-Earth--europe-africa--filled; 
  }
}
.icon-Earth--filled {
  &:before {
    content: $icon-Earth--filled; 
  }
}
.icon-Earthquake {
  &:before {
    content: $icon-Earthquake; 
  }
}
.icon-Earth--southeast-asia {
  &:before {
    content: $icon-Earth--southeast-asia; 
  }
}
.icon-Earth--southeast-asia--filled {
  &:before {
    content: $icon-Earth--southeast-asia--filled; 
  }
}
.icon-Edge-cluster {
  &:before {
    content: $icon-Edge-cluster; 
  }
}
.icon-Edge-device {
  &:before {
    content: $icon-Edge-device; 
  }
}
.icon-Edge-enhancement {
  &:before {
    content: $icon-Edge-enhancement; 
  }
}
.icon-Edge-enhancement--01 {
  &:before {
    content: $icon-Edge-enhancement--01; 
  }
}
.icon-Edge-enhancement--02 {
  &:before {
    content: $icon-Edge-enhancement--02; 
  }
}
.icon-Edge-enhancement--03 {
  &:before {
    content: $icon-Edge-enhancement--03; 
  }
}
.icon-Edge-node {
  &:before {
    content: $icon-Edge-node; 
  }
}
.icon-Edge-node--alt {
  &:before {
    content: $icon-Edge-node--alt; 
  }
}
.icon-Edge-service {
  &:before {
    content: $icon-Edge-service; 
  }
}
.icon-Edit {
  &:before {
    content: $icon-Edit; 
  }
}
.icon-Edit--off {
  &:before {
    content: $icon-Edit--off; 
  }
}
.icon-Edt-loop {
  &:before {
    content: $icon-Edt-loop; 
  }
}
.icon-Education {
  &:before {
    content: $icon-Education; 
  }
}
.icon-Email {
  &:before {
    content: $icon-Email; 
  }
}
.icon-Email--new {
  &:before {
    content: $icon-Email--new; 
  }
}
.icon-Encryption {
  &:before {
    content: $icon-Encryption; 
  }
}
.icon-Energy--renewable {
  &:before {
    content: $icon-Energy--renewable; 
  }
}
.icon-Enterprise {
  &:before {
    content: $icon-Enterprise; 
  }
}
.icon-Equalizer {
  &:before {
    content: $icon-Equalizer; 
  }
}
.icon-Erase {
  &:before {
    content: $icon-Erase; 
  }
}
.icon-Erase--3d {
  &:before {
    content: $icon-Erase--3d; 
  }
}
.icon-Error {
  &:before {
    content: $icon-Error; 
  }
}
.icon-Error--filled {
  &:before {
    content: $icon-Error--filled; 
  }
}
.icon-Error--outline {
  &:before {
    content: $icon-Error--outline; 
  }
}
.icon-Event {
  &:before {
    content: $icon-Event; 
  }
}
.icon-Events {
  &:before {
    content: $icon-Events; 
  }
}
.icon-Events--alt {
  &:before {
    content: $icon-Events--alt; 
  }
}
.icon-Event--schedule {
  &:before {
    content: $icon-Event--schedule; 
  }
}
.icon-Exam-mode {
  &:before {
    content: $icon-Exam-mode; 
  }
}
.icon-Exit {
  &:before {
    content: $icon-Exit; 
  }
}
.icon-Expand-all {
  &:before {
    content: $icon-Expand-all; 
  }
}
.icon-Expand-categories {
  &:before {
    content: $icon-Expand-categories; 
  }
}
.icon-Explore {
  &:before {
    content: $icon-Explore; 
  }
}
.icon-Export {
  &:before {
    content: $icon-Export; 
  }
}
.icon-Eyedropper {
  &:before {
    content: $icon-Eyedropper; 
  }
}
.icon-Face--activated {
  &:before {
    content: $icon-Face--activated; 
  }
}
.icon-Face--activated--add {
  &:before {
    content: $icon-Face--activated--add; 
  }
}
.icon-Face--activated--filled {
  &:before {
    content: $icon-Face--activated--filled; 
  }
}
.icon-Face--add {
  &:before {
    content: $icon-Face--add; 
  }
}
.icon-Face--cool {
  &:before {
    content: $icon-Face--cool; 
  }
}
.icon-Face--dissatisfied {
  &:before {
    content: $icon-Face--dissatisfied; 
  }
}
.icon-Face--dissatisfied--filled {
  &:before {
    content: $icon-Face--dissatisfied--filled; 
  }
}
.icon-Face--dizzy {
  &:before {
    content: $icon-Face--dizzy; 
  }
}
.icon-Face--dizzy--filled {
  &:before {
    content: $icon-Face--dizzy--filled; 
  }
}
.icon-Face--mask {
  &:before {
    content: $icon-Face--mask; 
  }
}
.icon-Face--neutral {
  &:before {
    content: $icon-Face--neutral; 
  }
}
.icon-Face--neutral--filled {
  &:before {
    content: $icon-Face--neutral--filled; 
  }
}
.icon-Face--pending {
  &:before {
    content: $icon-Face--pending; 
  }
}
.icon-Face--pending--filled {
  &:before {
    content: $icon-Face--pending--filled; 
  }
}
.icon-Face--satisfied {
  &:before {
    content: $icon-Face--satisfied; 
  }
}
.icon-Face--satisfied--filled {
  &:before {
    content: $icon-Face--satisfied--filled; 
  }
}
.icon-Face--wink {
  &:before {
    content: $icon-Face--wink; 
  }
}
.icon-Face--wink--filled {
  &:before {
    content: $icon-Face--wink--filled; 
  }
}
.icon-Factor {
  &:before {
    content: $icon-Factor; 
  }
}
.icon-Fade {
  &:before {
    content: $icon-Fade; 
  }
}
.icon-Favorite {
  &:before {
    content: $icon-Favorite; 
  }
}
.icon-Favorite--filled {
  &:before {
    content: $icon-Favorite--filled; 
  }
}
.icon-Favorite--half {
  &:before {
    content: $icon-Favorite--half; 
  }
}
.icon-Fetch-upload {
  &:before {
    content: $icon-Fetch-upload; 
  }
}
.icon-Fetch-upload--cloud {
  &:before {
    content: $icon-Fetch-upload--cloud; 
  }
}
.icon-File-storage {
  &:before {
    content: $icon-File-storage; 
  }
}
.icon-Filter {
  &:before {
    content: $icon-Filter; 
  }
}
.icon-Filter--edit {
  &:before {
    content: $icon-Filter--edit; 
  }
}
.icon-Filter--remove {
  &:before {
    content: $icon-Filter--remove; 
  }
}
.icon-Filter--reset {
  &:before {
    content: $icon-Filter--reset; 
  }
}
.icon-Finance {
  &:before {
    content: $icon-Finance; 
  }
}
.icon-Fingerprint-recognition {
  &:before {
    content: $icon-Fingerprint-recognition; 
  }
}
.icon-Fire {
  &:before {
    content: $icon-Fire; 
  }
}
.icon-Firewall {
  &:before {
    content: $icon-Firewall; 
  }
}
.icon-Firewall--classic {
  &:before {
    content: $icon-Firewall--classic; 
  }
}
.icon-Fish {
  &:before {
    content: $icon-Fish; 
  }
}
.icon-Fish--multiple {
  &:before {
    content: $icon-Fish--multiple; 
  }
}
.icon-Fit-to-height {
  &:before {
    content: $icon-Fit-to-height; 
  }
}
.icon-Fit-to-screen {
  &:before {
    content: $icon-Fit-to-screen; 
  }
}
.icon-Fit-to-width1 {
  &:before {
    content: $icon-Fit-to-width1; 
  }
}
.icon-Flag {
  &:before {
    content: $icon-Flag; 
  }
}
.icon-Flag--filled {
  &:before {
    content: $icon-Flag--filled; 
  }
}
.icon-Flagging-taxi {
  &:before {
    content: $icon-Flagging-taxi; 
  }
}
.icon-Flash {
  &:before {
    content: $icon-Flash; 
  }
}
.icon-Flash--filled {
  &:before {
    content: $icon-Flash--filled; 
  }
}
.icon-Flash--off {
  &:before {
    content: $icon-Flash--off; 
  }
}
.icon-Flash--off--filled {
  &:before {
    content: $icon-Flash--off--filled; 
  }
}
.icon-Flight--international {
  &:before {
    content: $icon-Flight--international; 
  }
}
.icon-Flight--roster {
  &:before {
    content: $icon-Flight--roster; 
  }
}
.icon-Flight--schedule {
  &:before {
    content: $icon-Flight--schedule; 
  }
}
.icon-Floating-ip {
  &:before {
    content: $icon-Floating-ip; 
  }
}
.icon-Flood {
  &:before {
    content: $icon-Flood; 
  }
}
.icon-Flood--warning {
  &:before {
    content: $icon-Flood--warning; 
  }
}
.icon-Flow {
  &:before {
    content: $icon-Flow; 
  }
}
.icon-Flow--connection {
  &:before {
    content: $icon-Flow--connection; 
  }
}
.icon-Flow--data {
  &:before {
    content: $icon-Flow--data; 
  }
}
.icon-Flow-logs-vpc {
  &:before {
    content: $icon-Flow-logs-vpc; 
  }
}
.icon-Flow--modeler {
  &:before {
    content: $icon-Flow--modeler; 
  }
}
.icon-Flow--stream {
  &:before {
    content: $icon-Flow--stream; 
  }
}
.icon-Flow--stream--reference {
  &:before {
    content: $icon-Flow--stream--reference; 
  }
}
.icon-Fog {
  &:before {
    content: $icon-Fog; 
  }
}
.icon-Folder {
  &:before {
    content: $icon-Folder; 
  }
}
.icon-Folder--add {
  &:before {
    content: $icon-Folder--add; 
  }
}
.icon-Folder--details {
  &:before {
    content: $icon-Folder--details; 
  }
}
.icon-Folder--details--reference {
  &:before {
    content: $icon-Folder--details--reference; 
  }
}
.icon-Folder--move-to {
  &:before {
    content: $icon-Folder--move-to; 
  }
}
.icon-Folder--off {
  &:before {
    content: $icon-Folder--off; 
  }
}
.icon-Folder--open {
  &:before {
    content: $icon-Folder--open; 
  }
}
.icon-Folder--parent {
  &:before {
    content: $icon-Folder--parent; 
  }
}
.icon-Folders {
  &:before {
    content: $icon-Folders; 
  }
}
.icon-Folder--shared {
  &:before {
    content: $icon-Folder--shared; 
  }
}
.icon-Forecast--hail {
  &:before {
    content: $icon-Forecast--hail; 
  }
}
.icon-Forecast--hail-30 {
  &:before {
    content: $icon-Forecast--hail-30; 
  }
}
.icon-Forecast--lightning {
  &:before {
    content: $icon-Forecast--lightning; 
  }
}
.icon-Forecast--lightning-30 {
  &:before {
    content: $icon-Forecast--lightning-30; 
  }
}
.icon-Fork {
  &:before {
    content: $icon-Fork; 
  }
}
.icon-Forum {
  &:before {
    content: $icon-Forum; 
  }
}
.icon-Forward--5 {
  &:before {
    content: $icon-Forward--5; 
  }
}
.icon-Forward--10 {
  &:before {
    content: $icon-Forward--10; 
  }
}
.icon-Forward--30 {
  &:before {
    content: $icon-Forward--30; 
  }
}
.icon-Fragile {
  &:before {
    content: $icon-Fragile; 
  }
}
.icon-Friendship {
  &:before {
    content: $icon-Friendship; 
  }
}
.icon-Fruit-bowl {
  &:before {
    content: $icon-Fruit-bowl; 
  }
}
.icon-Function {
  &:before {
    content: $icon-Function; 
  }
}
.icon-Function-math {
  &:before {
    content: $icon-Function-math; 
  }
}
.icon-Fusion-blender {
  &:before {
    content: $icon-Fusion-blender; 
  }
}
.icon-Game--console {
  &:before {
    content: $icon-Game--console; 
  }
}
.icon-Game--wireless {
  &:before {
    content: $icon-Game--wireless; 
  }
}
.icon-Gamification {
  &:before {
    content: $icon-Gamification; 
  }
}
.icon-Gas-station {
  &:before {
    content: $icon-Gas-station; 
  }
}
.icon-Gas-station--filled {
  &:before {
    content: $icon-Gas-station--filled; 
  }
}
.icon-Gateway {
  &:before {
    content: $icon-Gateway; 
  }
}
.icon-Gateway--api {
  &:before {
    content: $icon-Gateway--api; 
  }
}
.icon-Gateway--mail {
  &:before {
    content: $icon-Gateway--mail; 
  }
}
.icon-Gateway--public {
  &:before {
    content: $icon-Gateway--public; 
  }
}
.icon-Gateway--security {
  &:before {
    content: $icon-Gateway--security; 
  }
}
.icon-Gateway--user-access {
  &:before {
    content: $icon-Gateway--user-access; 
  }
}
.icon-Gateway--vpn {
  &:before {
    content: $icon-Gateway--vpn; 
  }
}
.icon-Gender--female {
  &:before {
    content: $icon-Gender--female; 
  }
}
.icon-Gender--male {
  &:before {
    content: $icon-Gender--male; 
  }
}
.icon-Generate-pdf {
  &:before {
    content: $icon-Generate-pdf; 
  }
}
.icon-Gif {
  &:before {
    content: $icon-Gif; 
  }
}
.icon-Gift {
  &:before {
    content: $icon-Gift; 
  }
}
.icon-Globe {
  &:before {
    content: $icon-Globe; 
  }
}
.icon-Gradient {
  &:before {
    content: $icon-Gradient; 
  }
}
.icon-Graphical-data-flow {
  &:before {
    content: $icon-Graphical-data-flow; 
  }
}
.icon-Grid {
  &:before {
    content: $icon-Grid; 
  }
}
.icon-Group {
  &:before {
    content: $icon-Group; 
  }
}
.icon-Group--access {
  &:before {
    content: $icon-Group--access; 
  }
}
.icon-Group--account {
  &:before {
    content: $icon-Group--account; 
  }
}
.icon-Group-objects {
  &:before {
    content: $icon-Group-objects; 
  }
}
.icon-Group-objects--new {
  &:before {
    content: $icon-Group-objects--new; 
  }
}
.icon-Group-objects--save {
  &:before {
    content: $icon-Group-objects--save; 
  }
}
.icon-Group--presentation {
  &:before {
    content: $icon-Group--presentation; 
  }
}
.icon-Group--resource {
  &:before {
    content: $icon-Group--resource; 
  }
}
.icon-Group--security {
  &:before {
    content: $icon-Group--security; 
  }
}
.icon-Growth {
  &:before {
    content: $icon-Growth; 
  }
}
.icon-Gui {
  &:before {
    content: $icon-Gui; 
  }
}
.icon-Gui--management {
  &:before {
    content: $icon-Gui--management; 
  }
}
.icon-H {
  &:before {
    content: $icon-H; 
  }
}
.icon-Hail {
  &:before {
    content: $icon-Hail; 
  }
}
.icon-Hanging-protocol {
  &:before {
    content: $icon-Hanging-protocol; 
  }
}
.icon-Harbor {
  &:before {
    content: $icon-Harbor; 
  }
}
.icon-Hardware-security-module {
  &:before {
    content: $icon-Hardware-security-module; 
  }
}
.icon-Hashtag {
  &:before {
    content: $icon-Hashtag; 
  }
}
.icon-Haze {
  &:before {
    content: $icon-Haze; 
  }
}
.icon-Haze--night {
  &:before {
    content: $icon-Haze--night; 
  }
}
.icon-Hd {
  &:before {
    content: $icon-Hd; 
  }
}
.icon-Hd--filled {
  &:before {
    content: $icon-Hd--filled; 
  }
}
.icon-Hdr {
  &:before {
    content: $icon-Hdr; 
  }
}
.icon-Headphones {
  &:before {
    content: $icon-Headphones; 
  }
}
.icon-Headset {
  &:before {
    content: $icon-Headset; 
  }
}
.icon-Health-cross {
  &:before {
    content: $icon-Health-cross; 
  }
}
.icon-Hearing {
  &:before {
    content: $icon-Hearing; 
  }
}
.icon-Heat-map {
  &:before {
    content: $icon-Heat-map; 
  }
}
.icon-Heat-map--02 {
  &:before {
    content: $icon-Heat-map--02; 
  }
}
.icon-Heat-map--03 {
  &:before {
    content: $icon-Heat-map--03; 
  }
}
.icon-Heat-map--stocks {
  &:before {
    content: $icon-Heat-map--stocks; 
  }
}
.icon-Helicopter {
  &:before {
    content: $icon-Helicopter; 
  }
}
.icon-Help {
  &:before {
    content: $icon-Help; 
  }
}
.icon-Help-desk {
  &:before {
    content: $icon-Help-desk; 
  }
}
.icon-Help--filled {
  &:before {
    content: $icon-Help--filled; 
  }
}
.icon-Hinton-plot {
  &:before {
    content: $icon-Hinton-plot; 
  }
}
.icon-Hole-filling {
  &:before {
    content: $icon-Hole-filling; 
  }
}
.icon-Hole-filling--cursor {
  &:before {
    content: $icon-Hole-filling--cursor; 
  }
}
.icon-Home {
  &:before {
    content: $icon-Home; 
  }
}
.icon-Hospital {
  &:before {
    content: $icon-Hospital; 
  }
}
.icon-Hospital-bed {
  &:before {
    content: $icon-Hospital-bed; 
  }
}
.icon-Hotel {
  &:before {
    content: $icon-Hotel; 
  }
}
.icon-Hourglass {
  &:before {
    content: $icon-Hourglass; 
  }
}
.icon-Html {
  &:before {
    content: $icon-Html; 
  }
}
.icon-Html--reference {
  &:before {
    content: $icon-Html--reference; 
  }
}
.icon-Http {
  &:before {
    content: $icon-Http; 
  }
}
.icon-Humidity {
  &:before {
    content: $icon-Humidity; 
  }
}
.icon-Humidity--alt {
  &:before {
    content: $icon-Humidity--alt; 
  }
}
.icon-Hurricane {
  &:before {
    content: $icon-Hurricane; 
  }
}
.icon-Hybrid-networking {
  &:before {
    content: $icon-Hybrid-networking; 
  }
}
.icon-Hybrid-networking--alt {
  &:before {
    content: $icon-Hybrid-networking--alt; 
  }
}
.icon-IBM-cloud {
  &:before {
    content: $icon-IBM-cloud; 
  }
}
.icon-IBM-cloud--dedicated-host {
  &:before {
    content: $icon-IBM-cloud--dedicated-host; 
  }
}
.icon-IBM-cloud--internet-services {
  &:before {
    content: $icon-IBM-cloud--internet-services; 
  }
}
.icon-IBM-cloud-pak--applications {
  &:before {
    content: $icon-IBM-cloud-pak--applications; 
  }
}
.icon-IBM-cloud-pak--data {
  &:before {
    content: $icon-IBM-cloud-pak--data; 
  }
}
.icon-IBM-cloud-pak--integration {
  &:before {
    content: $icon-IBM-cloud-pak--integration; 
  }
}
.icon-IBM-cloud-pak--multicloud-mgmt {
  &:before {
    content: $icon-IBM-cloud-pak--multicloud-mgmt; 
  }
}
.icon-IBM-cloud-pak--security {
  &:before {
    content: $icon-IBM-cloud-pak--security; 
  }
}
.icon-IBM-cloud-pak--system {
  &:before {
    content: $icon-IBM-cloud-pak--system; 
  }
}
.icon-IBM-cloud--subnets {
  &:before {
    content: $icon-IBM-cloud--subnets; 
  }
}
.icon-IBM-cloud--vpc-endpoints {
  &:before {
    content: $icon-IBM-cloud--vpc-endpoints; 
  }
}
.icon-IBM-security {
  &:before {
    content: $icon-IBM-security; 
  }
}
.icon-IBM-security--services {
  &:before {
    content: $icon-IBM-security--services; 
  }
}
.icon-Ica-2d {
  &:before {
    content: $icon-Ica-2d; 
  }
}
.icon-Ice--accretion {
  &:before {
    content: $icon-Ice--accretion; 
  }
}
.icon-Ice--vision {
  &:before {
    content: $icon-Ice--vision; 
  }
}
.icon-Id {
  &:before {
    content: $icon-Id; 
  }
}
.icon-Idea {
  &:before {
    content: $icon-Idea; 
  }
}
.icon-Identification {
  &:before {
    content: $icon-Identification; 
  }
}
.icon-Id-management {
  &:before {
    content: $icon-Id-management; 
  }
}
.icon-Image {
  &:before {
    content: $icon-Image; 
  }
}
.icon-Image--copy {
  &:before {
    content: $icon-Image--copy; 
  }
}
.icon-Image--medical {
  &:before {
    content: $icon-Image--medical; 
  }
}
.icon-Image--reference {
  &:before {
    content: $icon-Image--reference; 
  }
}
.icon-Image--search {
  &:before {
    content: $icon-Image--search; 
  }
}
.icon-Image--search--alt {
  &:before {
    content: $icon-Image--search--alt; 
  }
}
.icon-Image-service {
  &:before {
    content: $icon-Image-service; 
  }
}
.icon-Improve-relevance {
  &:before {
    content: $icon-Improve-relevance; 
  }
}
.icon-Incomplete {
  &:before {
    content: $icon-Incomplete; 
  }
}
.icon-Incomplete--cancel {
  &:before {
    content: $icon-Incomplete--cancel; 
  }
}
.icon-Incomplete--error {
  &:before {
    content: $icon-Incomplete--error; 
  }
}
.icon-Incomplete--warning {
  &:before {
    content: $icon-Incomplete--warning; 
  }
}
.icon-Increase-level {
  &:before {
    content: $icon-Increase-level; 
  }
}
.icon-Industry {
  &:before {
    content: $icon-Industry; 
  }
}
.icon-Information {
  &:before {
    content: $icon-Information; 
  }
}
.icon-Information--disabled {
  &:before {
    content: $icon-Information--disabled; 
  }
}
.icon-Information--filled {
  &:before {
    content: $icon-Information--filled; 
  }
}
.icon-Information--square {
  &:before {
    content: $icon-Information--square; 
  }
}
.icon-Information--square--filled {
  &:before {
    content: $icon-Information--square--filled; 
  }
}
.icon-Infrastructure--classic {
  &:before {
    content: $icon-Infrastructure--classic; 
  }
}
.icon-In-progress {
  &:before {
    content: $icon-In-progress; 
  }
}
.icon-In-progress--error {
  &:before {
    content: $icon-In-progress--error; 
  }
}
.icon-In-progress--warning {
  &:before {
    content: $icon-In-progress--warning; 
  }
}
.icon-Insert {
  &:before {
    content: $icon-Insert; 
  }
}
.icon-Insert--page {
  &:before {
    content: $icon-Insert--page; 
  }
}
.icon-Insert-syntax {
  &:before {
    content: $icon-Insert-syntax; 
  }
}
.icon-Inspection {
  &:before {
    content: $icon-Inspection; 
  }
}
.icon-Instance--bx {
  &:before {
    content: $icon-Instance--bx; 
  }
}
.icon-Instance--classic {
  &:before {
    content: $icon-Instance--classic; 
  }
}
.icon-Instance--cx {
  &:before {
    content: $icon-Instance--cx; 
  }
}
.icon-Instance--mx {
  &:before {
    content: $icon-Instance--mx; 
  }
}
.icon-Instance--virtual {
  &:before {
    content: $icon-Instance--virtual; 
  }
}
.icon-Integration {
  &:before {
    content: $icon-Integration; 
  }
}
.icon-Interactions {
  &:before {
    content: $icon-Interactions; 
  }
}
.icon-Interactive-segmentation-cursor {
  &:before {
    content: $icon-Interactive-segmentation-cursor; 
  }
}
.icon-Intersect {
  &:before {
    content: $icon-Intersect; 
  }
}
.icon-Intrusion-prevention {
  &:before {
    content: $icon-Intrusion-prevention; 
  }
}
.icon-Inventory-management {
  &:before {
    content: $icon-Inventory-management; 
  }
}
.icon-Iot--connect {
  &:before {
    content: $icon-Iot--connect; 
  }
}
.icon-Iot--platform {
  &:before {
    content: $icon-Iot--platform; 
  }
}
.icon-Iso {
  &:before {
    content: $icon-Iso; 
  }
}
.icon-Iso--filled {
  &:before {
    content: $icon-Iso--filled; 
  }
}
.icon-Iso--outline {
  &:before {
    content: $icon-Iso--outline; 
  }
}
.icon-Join--full {
  &:before {
    content: $icon-Join--full; 
  }
}
.icon-Join--inner {
  &:before {
    content: $icon-Join--inner; 
  }
}
.icon-Join--left {
  &:before {
    content: $icon-Join--left; 
  }
}
.icon-Join--outer {
  &:before {
    content: $icon-Join--outer; 
  }
}
.icon-Join--right {
  &:before {
    content: $icon-Join--right; 
  }
}
.icon-Jpg {
  &:before {
    content: $icon-Jpg; 
  }
}
.icon-Json {
  &:before {
    content: $icon-Json; 
  }
}
.icon-Json--reference {
  &:before {
    content: $icon-Json--reference; 
  }
}
.icon-Jump-link {
  &:before {
    content: $icon-Jump-link; 
  }
}
.icon-Keep-dry {
  &:before {
    content: $icon-Keep-dry; 
  }
}
.icon-Keyboard {
  &:before {
    content: $icon-Keyboard; 
  }
}
.icon-Kubernetes {
  &:before {
    content: $icon-Kubernetes; 
  }
}
.icon-Label {
  &:before {
    content: $icon-Label; 
  }
}
.icon-Language {
  &:before {
    content: $icon-Language; 
  }
}
.icon-Laptop {
  &:before {
    content: $icon-Laptop; 
  }
}
.icon-Lasso {
  &:before {
    content: $icon-Lasso; 
  }
}
.icon-Lasso--polygon {
  &:before {
    content: $icon-Lasso--polygon; 
  }
}
.icon-Launch {
  &:before {
    content: $icon-Launch; 
  }
}
.icon-Launch-study--1 {
  &:before {
    content: $icon-Launch-study--1; 
  }
}
.icon-Launch-study--2 {
  &:before {
    content: $icon-Launch-study--2; 
  }
}
.icon-Launch-study--3 {
  &:before {
    content: $icon-Launch-study--3; 
  }
}
.icon-Layers {
  &:before {
    content: $icon-Layers; 
  }
}
.icon-Legend {
  &:before {
    content: $icon-Legend; 
  }
}
.icon-Letter--aa {
  &:before {
    content: $icon-Letter--aa; 
  }
}
.icon-Letter--bb {
  &:before {
    content: $icon-Letter--bb; 
  }
}
.icon-Letter--cc {
  &:before {
    content: $icon-Letter--cc; 
  }
}
.icon-Letter--dd {
  &:before {
    content: $icon-Letter--dd; 
  }
}
.icon-Letter--ee {
  &:before {
    content: $icon-Letter--ee; 
  }
}
.icon-Letter--ff {
  &:before {
    content: $icon-Letter--ff; 
  }
}
.icon-Letter--gg {
  &:before {
    content: $icon-Letter--gg; 
  }
}
.icon-Letter--hh {
  &:before {
    content: $icon-Letter--hh; 
  }
}
.icon-Letter--ii {
  &:before {
    content: $icon-Letter--ii; 
  }
}
.icon-Letter--jj {
  &:before {
    content: $icon-Letter--jj; 
  }
}
.icon-Letter--kk {
  &:before {
    content: $icon-Letter--kk; 
  }
}
.icon-Letter--ll {
  &:before {
    content: $icon-Letter--ll; 
  }
}
.icon-Letter--mm {
  &:before {
    content: $icon-Letter--mm; 
  }
}
.icon-Letter--nn {
  &:before {
    content: $icon-Letter--nn; 
  }
}
.icon-Letter--oo {
  &:before {
    content: $icon-Letter--oo; 
  }
}
.icon-Letter--pp {
  &:before {
    content: $icon-Letter--pp; 
  }
}
.icon-Letter--qq {
  &:before {
    content: $icon-Letter--qq; 
  }
}
.icon-Letter--rr {
  &:before {
    content: $icon-Letter--rr; 
  }
}
.icon-Letter--ss {
  &:before {
    content: $icon-Letter--ss; 
  }
}
.icon-Letter--tt {
  &:before {
    content: $icon-Letter--tt; 
  }
}
.icon-Letter--uu {
  &:before {
    content: $icon-Letter--uu; 
  }
}
.icon-Letter--vv {
  &:before {
    content: $icon-Letter--vv; 
  }
}
.icon-Letter--ww {
  &:before {
    content: $icon-Letter--ww; 
  }
}
.icon-Letter--xx {
  &:before {
    content: $icon-Letter--xx; 
  }
}
.icon-Letter--yy {
  &:before {
    content: $icon-Letter--yy; 
  }
}
.icon-Letter--zz {
  &:before {
    content: $icon-Letter--zz; 
  }
}
.icon-License {
  &:before {
    content: $icon-License; 
  }
}
.icon-License--draft {
  &:before {
    content: $icon-License--draft; 
  }
}
.icon-License--global {
  &:before {
    content: $icon-License--global; 
  }
}
.icon-License--maintenance {
  &:before {
    content: $icon-License--maintenance; 
  }
}
.icon-License--maintenance-draft {
  &:before {
    content: $icon-License--maintenance-draft; 
  }
}
.icon-License--third-party {
  &:before {
    content: $icon-License--third-party; 
  }
}
.icon-License--third-party-draft {
  &:before {
    content: $icon-License--third-party-draft; 
  }
}
.icon-Lifesaver {
  &:before {
    content: $icon-Lifesaver; 
  }
}
.icon-Light {
  &:before {
    content: $icon-Light; 
  }
}
.icon-Light--filled {
  &:before {
    content: $icon-Light--filled; 
  }
}
.icon-Lightning {
  &:before {
    content: $icon-Lightning; 
  }
}
.icon-Link {
  &:before {
    content: $icon-Link; 
  }
}
.icon-Linux {
  &:before {
    content: $icon-Linux; 
  }
}
.icon-Linux--alt {
  &:before {
    content: $icon-Linux--alt; 
  }
}
.icon-List {
  &:before {
    content: $icon-List; 
  }
}
.icon-List--boxes {
  &:before {
    content: $icon-List--boxes; 
  }
}
.icon-List--bulleted {
  &:before {
    content: $icon-List--bulleted; 
  }
}
.icon-List--checked {
  &:before {
    content: $icon-List--checked; 
  }
}
.icon-List--dropdown {
  &:before {
    content: $icon-List--dropdown; 
  }
}
.icon-List--numbered {
  &:before {
    content: $icon-List--numbered; 
  }
}
.icon-Load-balancer--application {
  &:before {
    content: $icon-Load-balancer--application; 
  }
}
.icon-Load-balancer--classic {
  &:before {
    content: $icon-Load-balancer--classic; 
  }
}
.icon-Load-balancer--global {
  &:before {
    content: $icon-Load-balancer--global; 
  }
}
.icon-Load-balancer--listener {
  &:before {
    content: $icon-Load-balancer--listener; 
  }
}
.icon-Load-balancer--local {
  &:before {
    content: $icon-Load-balancer--local; 
  }
}
.icon-Load-balancer--network {
  &:before {
    content: $icon-Load-balancer--network; 
  }
}
.icon-Load-balancer--pool {
  &:before {
    content: $icon-Load-balancer--pool; 
  }
}
.icon-Load-balancer--vpc {
  &:before {
    content: $icon-Load-balancer--vpc; 
  }
}
.icon-Location {
  &:before {
    content: $icon-Location; 
  }
}
.icon-Location--company {
  &:before {
    content: $icon-Location--company; 
  }
}
.icon-Location--company--filled {
  &:before {
    content: $icon-Location--company--filled; 
  }
}
.icon-Location--current {
  &:before {
    content: $icon-Location--current; 
  }
}
.icon-Location--filled {
  &:before {
    content: $icon-Location--filled; 
  }
}
.icon-Location--hazard {
  &:before {
    content: $icon-Location--hazard; 
  }
}
.icon-Location--hazard--filled {
  &:before {
    content: $icon-Location--hazard--filled; 
  }
}
.icon-Location--heart {
  &:before {
    content: $icon-Location--heart; 
  }
}
.icon-Location--heart--filled {
  &:before {
    content: $icon-Location--heart--filled; 
  }
}
.icon-Location--person {
  &:before {
    content: $icon-Location--person; 
  }
}
.icon-Location--person--filled {
  &:before {
    content: $icon-Location--person--filled; 
  }
}
.icon-Location--save {
  &:before {
    content: $icon-Location--save; 
  }
}
.icon-Location--star {
  &:before {
    content: $icon-Location--star; 
  }
}
.icon-Location--star--filled {
  &:before {
    content: $icon-Location--star--filled; 
  }
}
.icon-Locked {
  &:before {
    content: $icon-Locked; 
  }
}
.icon-Login {
  &:before {
    content: $icon-Login; 
  }
}
.icon-Logo--digg {
  &:before {
    content: $icon-Logo--digg; 
  }
}
.icon-Logo--discord {
  &:before {
    content: $icon-Logo--discord; 
  }
}
.icon-Logo--facebook {
  &:before {
    content: $icon-Logo--facebook; 
  }
}
.icon-Logo--flickr {
  &:before {
    content: $icon-Logo--flickr; 
  }
}
.icon-Logo--github {
  &:before {
    content: $icon-Logo--github; 
  }
}
.icon-Logo--glassdoor {
  &:before {
    content: $icon-Logo--glassdoor; 
  }
}
.icon-Logo--instagram {
  &:before {
    content: $icon-Logo--instagram; 
  }
}
.icon-Logo--jupyter {
  &:before {
    content: $icon-Logo--jupyter; 
  }
}
.icon-Logo--keybase {
  &:before {
    content: $icon-Logo--keybase; 
  }
}
.icon-Logo--linkedin {
  &:before {
    content: $icon-Logo--linkedin; 
  }
}
.icon-Logo--livestream {
  &:before {
    content: $icon-Logo--livestream; 
  }
}
.icon-Logo--medium {
  &:before {
    content: $icon-Logo--medium; 
  }
}
.icon-Logo--openshift {
  &:before {
    content: $icon-Logo--openshift; 
  }
}
.icon-Logo--pinterest {
  &:before {
    content: $icon-Logo--pinterest; 
  }
}
.icon-Logo--python {
  &:before {
    content: $icon-Logo--python; 
  }
}
.icon-Logo--quora {
  &:before {
    content: $icon-Logo--quora; 
  }
}
.icon-Logo--r-script {
  &:before {
    content: $icon-Logo--r-script; 
  }
}
.icon-Logo--skype {
  &:before {
    content: $icon-Logo--skype; 
  }
}
.icon-Logo--slack {
  &:before {
    content: $icon-Logo--slack; 
  }
}
.icon-Logo--snapchat {
  &:before {
    content: $icon-Logo--snapchat; 
  }
}
.icon-Logo--tumblr {
  &:before {
    content: $icon-Logo--tumblr; 
  }
}
.icon-Logo--twitter {
  &:before {
    content: $icon-Logo--twitter; 
  }
}
.icon-Logout {
  &:before {
    content: $icon-Logout; 
  }
}
.icon-Logo--vmware {
  &:before {
    content: $icon-Logo--vmware; 
  }
}
.icon-Logo--wechat {
  &:before {
    content: $icon-Logo--wechat; 
  }
}
.icon-Logo--xing {
  &:before {
    content: $icon-Logo--xing; 
  }
}
.icon-Logo--yelp {
  &:before {
    content: $icon-Logo--yelp; 
  }
}
.icon-Logo--youtube {
  &:before {
    content: $icon-Logo--youtube; 
  }
}
.icon-Loop {
  &:before {
    content: $icon-Loop; 
  }
}
.icon-Low-severity {
  &:before {
    content: $icon-Low-severity; 
  }
}
.icon-Mac--command {
  &:before {
    content: $icon-Mac--command; 
  }
}
.icon-Machine-learning {
  &:before {
    content: $icon-Machine-learning; 
  }
}
.icon-Machine-learning-model {
  &:before {
    content: $icon-Machine-learning-model; 
  }
}
.icon-Mac--option {
  &:before {
    content: $icon-Mac--option; 
  }
}
.icon-Mac--shift {
  &:before {
    content: $icon-Mac--shift; 
  }
}
.icon-Magic-wand {
  &:before {
    content: $icon-Magic-wand; 
  }
}
.icon-Magic-wand--filled {
  &:before {
    content: $icon-Magic-wand--filled; 
  }
}
.icon-Magnify {
  &:before {
    content: $icon-Magnify; 
  }
}
.icon-Mail--all {
  &:before {
    content: $icon-Mail--all; 
  }
}
.icon-Mail--reply {
  &:before {
    content: $icon-Mail--reply; 
  }
}
.icon-Mammogram {
  &:before {
    content: $icon-Mammogram; 
  }
}
.icon-Managed-solutions {
  &:before {
    content: $icon-Managed-solutions; 
  }
}
.icon-Manage-protection {
  &:before {
    content: $icon-Manage-protection; 
  }
}
.icon-Map {
  &:before {
    content: $icon-Map; 
  }
}
.icon-Map-boundary {
  &:before {
    content: $icon-Map-boundary; 
  }
}
.icon-Map--center {
  &:before {
    content: $icon-Map--center; 
  }
}
.icon-Map--identify {
  &:before {
    content: $icon-Map--identify; 
  }
}
.icon-Marine-warning {
  &:before {
    content: $icon-Marine-warning; 
  }
}
.icon-Math-curve {
  &:before {
    content: $icon-Math-curve; 
  }
}
.icon-Matrix {
  &:before {
    content: $icon-Matrix; 
  }
}
.icon-Maximize {
  &:before {
    content: $icon-Maximize; 
  }
}
.icon-Media-cast {
  &:before {
    content: $icon-Media-cast; 
  }
}
.icon-Media--library {
  &:before {
    content: $icon-Media--library; 
  }
}
.icon-Media--library--filled {
  &:before {
    content: $icon-Media--library--filled; 
  }
}
.icon-Medication {
  &:before {
    content: $icon-Medication; 
  }
}
.icon-Medication--alert {
  &:before {
    content: $icon-Medication--alert; 
  }
}
.icon-Medication--reminder {
  &:before {
    content: $icon-Medication--reminder; 
  }
}
.icon-Menu {
  &:before {
    content: $icon-Menu; 
  }
}
.icon-Message-queue {
  &:before {
    content: $icon-Message-queue; 
  }
}
.icon-Meter {
  &:before {
    content: $icon-Meter; 
  }
}
.icon-Meter--alt {
  &:before {
    content: $icon-Meter--alt; 
  }
}
.icon-Microphone {
  &:before {
    content: $icon-Microphone; 
  }
}
.icon-Microphone--filled {
  &:before {
    content: $icon-Microphone--filled; 
  }
}
.icon-Microphone--off {
  &:before {
    content: $icon-Microphone--off; 
  }
}
.icon-Microphone--off--filled {
  &:before {
    content: $icon-Microphone--off--filled; 
  }
}
.icon-Microscope {
  &:before {
    content: $icon-Microscope; 
  }
}
.icon-Migrate {
  &:before {
    content: $icon-Migrate; 
  }
}
.icon-Migrate--alt {
  &:before {
    content: $icon-Migrate--alt; 
  }
}
.icon-Milestone {
  &:before {
    content: $icon-Milestone; 
  }
}
.icon-Military-camp {
  &:before {
    content: $icon-Military-camp; 
  }
}
.icon-Minimize {
  &:before {
    content: $icon-Minimize; 
  }
}
.icon-Misuse {
  &:before {
    content: $icon-Misuse; 
  }
}
.icon-Misuse--outline {
  &:before {
    content: $icon-Misuse--outline; 
  }
}
.icon-Mixed-rain-hail {
  &:before {
    content: $icon-Mixed-rain-hail; 
  }
}
.icon-Mobile {
  &:before {
    content: $icon-Mobile; 
  }
}
.icon-Mobile--add {
  &:before {
    content: $icon-Mobile--add; 
  }
}
.icon-Mobile--audio {
  &:before {
    content: $icon-Mobile--audio; 
  }
}
.icon-Mobile--check {
  &:before {
    content: $icon-Mobile--check; 
  }
}
.icon-Mobile--download {
  &:before {
    content: $icon-Mobile--download; 
  }
}
.icon-Mobile--landscape {
  &:before {
    content: $icon-Mobile--landscape; 
  }
}
.icon-Mobility--services {
  &:before {
    content: $icon-Mobility--services; 
  }
}
.icon-Model {
  &:before {
    content: $icon-Model; 
  }
}
.icon-Model--alt {
  &:before {
    content: $icon-Model--alt; 
  }
}
.icon-Model-builder {
  &:before {
    content: $icon-Model-builder; 
  }
}
.icon-Model-builder--reference {
  &:before {
    content: $icon-Model-builder--reference; 
  }
}
.icon-Model--reference {
  &:before {
    content: $icon-Model--reference; 
  }
}
.icon-Money {
  &:before {
    content: $icon-Money; 
  }
}
.icon-Monster {
  &:before {
    content: $icon-Monster; 
  }
}
.icon-Monument {
  &:before {
    content: $icon-Monument; 
  }
}
.icon-Moon {
  &:before {
    content: $icon-Moon; 
  }
}
.icon-Moonrise {
  &:before {
    content: $icon-Moonrise; 
  }
}
.icon-Moonset {
  &:before {
    content: $icon-Moonset; 
  }
}
.icon-Mostly-cloudy {
  &:before {
    content: $icon-Mostly-cloudy; 
  }
}
.icon-Mostly-cloudy--night {
  &:before {
    content: $icon-Mostly-cloudy--night; 
  }
}
.icon-Mountain {
  &:before {
    content: $icon-Mountain; 
  }
}
.icon-Mov {
  &:before {
    content: $icon-Mov; 
  }
}
.icon-Move {
  &:before {
    content: $icon-Move; 
  }
}
.icon-Movement {
  &:before {
    content: $icon-Movement; 
  }
}
.icon-Mp3 {
  &:before {
    content: $icon-Mp3; 
  }
}
.icon-Mp4 {
  &:before {
    content: $icon-Mp4; 
  }
}
.icon-Mpeg {
  &:before {
    content: $icon-Mpeg; 
  }
}
.icon-Mpg2 {
  &:before {
    content: $icon-Mpg2; 
  }
}
.icon-Music {
  &:before {
    content: $icon-Music; 
  }
}
.icon-Music--add {
  &:before {
    content: $icon-Music--add; 
  }
}
.icon-Music--remove {
  &:before {
    content: $icon-Music--remove; 
  }
}
.icon-Name-space {
  &:before {
    content: $icon-Name-space; 
  }
}
.icon-Navaid--civil {
  &:before {
    content: $icon-Navaid--civil; 
  }
}
.icon-Navaid--dme {
  &:before {
    content: $icon-Navaid--dme; 
  }
}
.icon-Navaid--helipad {
  &:before {
    content: $icon-Navaid--helipad; 
  }
}
.icon-Navaid--military {
  &:before {
    content: $icon-Navaid--military; 
  }
}
.icon-Navaid--military-civil {
  &:before {
    content: $icon-Navaid--military-civil; 
  }
}
.icon-Navaid--ndb {
  &:before {
    content: $icon-Navaid--ndb; 
  }
}
.icon-Navaid--ndb-dme {
  &:before {
    content: $icon-Navaid--ndb-dme; 
  }
}
.icon-Navaid--private {
  &:before {
    content: $icon-Navaid--private; 
  }
}
.icon-Navaid--seaplane {
  &:before {
    content: $icon-Navaid--seaplane; 
  }
}
.icon-Navaid--tacan {
  &:before {
    content: $icon-Navaid--tacan; 
  }
}
.icon-Navaid--vhfor {
  &:before {
    content: $icon-Navaid--vhfor; 
  }
}
.icon-Navaid--vor {
  &:before {
    content: $icon-Navaid--vor; 
  }
}
.icon-Navaid--vordme {
  &:before {
    content: $icon-Navaid--vordme; 
  }
}
.icon-Navaid--vortac {
  &:before {
    content: $icon-Navaid--vortac; 
  }
}
.icon-Network--1 {
  &:before {
    content: $icon-Network--1; 
  }
}
.icon-Network--2 {
  &:before {
    content: $icon-Network--2; 
  }
}
.icon-Network--3 {
  &:before {
    content: $icon-Network--3; 
  }
}
.icon-Network--3--reference {
  &:before {
    content: $icon-Network--3--reference; 
  }
}
.icon-Network--4 {
  &:before {
    content: $icon-Network--4; 
  }
}
.icon-Network--admin-control {
  &:before {
    content: $icon-Network--admin-control; 
  }
}
.icon-Network--enterprise {
  &:before {
    content: $icon-Network--enterprise; 
  }
}
.icon-Network--overlay {
  &:before {
    content: $icon-Network--overlay; 
  }
}
.icon-Network--public {
  &:before {
    content: $icon-Network--public; 
  }
}
.icon-New-tab {
  &:before {
    content: $icon-New-tab; 
  }
}
.icon-Next--filled {
  &:before {
    content: $icon-Next--filled; 
  }
}
.icon-Next--outline {
  &:before {
    content: $icon-Next--outline; 
  }
}
.icon-No-image {
  &:before {
    content: $icon-No-image; 
  }
}
.icon-Nominal {
  &:before {
    content: $icon-Nominal; 
  }
}
.icon-Nominate {
  &:before {
    content: $icon-Nominate; 
  }
}
.icon-Non-certified {
  &:before {
    content: $icon-Non-certified; 
  }
}
.icon-Noodle-bowl {
  &:before {
    content: $icon-Noodle-bowl; 
  }
}
.icon-Not-available {
  &:before {
    content: $icon-Not-available; 
  }
}
.icon-Notebook {
  &:before {
    content: $icon-Notebook; 
  }
}
.icon-Notebook--reference {
  &:before {
    content: $icon-Notebook--reference; 
  }
}
.icon-No-ticket {
  &:before {
    content: $icon-No-ticket; 
  }
}
.icon-Notification {
  &:before {
    content: $icon-Notification; 
  }
}
.icon-Notification--filled {
  &:before {
    content: $icon-Notification--filled; 
  }
}
.icon-Notification--new {
  &:before {
    content: $icon-Notification--new; 
  }
}
.icon-Notification--off {
  &:before {
    content: $icon-Notification--off; 
  }
}
.icon-Notification--off--filled {
  &:before {
    content: $icon-Notification--off--filled; 
  }
}
.icon-Not-sent {
  &:before {
    content: $icon-Not-sent; 
  }
}
.icon-Not-sent--filled {
  &:before {
    content: $icon-Not-sent--filled; 
  }
}
.icon-Number--0 {
  &:before {
    content: $icon-Number--0; 
  }
}
.icon-Number--1 {
  &:before {
    content: $icon-Number--1; 
  }
}
.icon-Number--2 {
  &:before {
    content: $icon-Number--2; 
  }
}
.icon-Number--3 {
  &:before {
    content: $icon-Number--3; 
  }
}
.icon-Number--4 {
  &:before {
    content: $icon-Number--4; 
  }
}
.icon-Number--5 {
  &:before {
    content: $icon-Number--5; 
  }
}
.icon-Number--6 {
  &:before {
    content: $icon-Number--6; 
  }
}
.icon-Number--7 {
  &:before {
    content: $icon-Number--7; 
  }
}
.icon-Number--8 {
  &:before {
    content: $icon-Number--8; 
  }
}
.icon-Number--9 {
  &:before {
    content: $icon-Number--9; 
  }
}
.icon-Number--small--0 {
  &:before {
    content: $icon-Number--small--0; 
  }
}
.icon-Number--small--1 {
  &:before {
    content: $icon-Number--small--1; 
  }
}
.icon-Number--small--2 {
  &:before {
    content: $icon-Number--small--2; 
  }
}
.icon-Number--small--3 {
  &:before {
    content: $icon-Number--small--3; 
  }
}
.icon-Number--small--4 {
  &:before {
    content: $icon-Number--small--4; 
  }
}
.icon-Number--small--5 {
  &:before {
    content: $icon-Number--small--5; 
  }
}
.icon-Number--small--6 {
  &:before {
    content: $icon-Number--small--6; 
  }
}
.icon-Number--small--7 {
  &:before {
    content: $icon-Number--small--7; 
  }
}
.icon-Number--small--8 {
  &:before {
    content: $icon-Number--small--8; 
  }
}
.icon-Number--small--9 {
  &:before {
    content: $icon-Number--small--9; 
  }
}
.icon-Object-storage {
  &:before {
    content: $icon-Object-storage; 
  }
}
.icon-Object-storage--alt {
  &:before {
    content: $icon-Object-storage--alt; 
  }
}
.icon-Observed--hail {
  &:before {
    content: $icon-Observed--hail; 
  }
}
.icon-Observed--lightning {
  &:before {
    content: $icon-Observed--lightning; 
  }
}
.icon-Omega {
  &:before {
    content: $icon-Omega; 
  }
}
.icon-Opacity {
  &:before {
    content: $icon-Opacity; 
  }
}
.icon-Open-panel--bottom {
  &:before {
    content: $icon-Open-panel--bottom; 
  }
}
.icon-Open-panel--filled--bottom {
  &:before {
    content: $icon-Open-panel--filled--bottom; 
  }
}
.icon-Open-panel--filled--left {
  &:before {
    content: $icon-Open-panel--filled--left; 
  }
}
.icon-Open-panel--filled--right {
  &:before {
    content: $icon-Open-panel--filled--right; 
  }
}
.icon-Open-panel--filled--top {
  &:before {
    content: $icon-Open-panel--filled--top; 
  }
}
.icon-Open-panel--left {
  &:before {
    content: $icon-Open-panel--left; 
  }
}
.icon-Open-panel--right {
  &:before {
    content: $icon-Open-panel--right; 
  }
}
.icon-Open-panel--top {
  &:before {
    content: $icon-Open-panel--top; 
  }
}
.icon-Operation {
  &:before {
    content: $icon-Operation; 
  }
}
.icon-Operation--gauge {
  &:before {
    content: $icon-Operation--gauge; 
  }
}
.icon-Operation--if {
  &:before {
    content: $icon-Operation--if; 
  }
}
.icon-Operations--field {
  &:before {
    content: $icon-Operations--field; 
  }
}
.icon-Operations--record {
  &:before {
    content: $icon-Operations--record; 
  }
}
.icon-Ordinal {
  &:before {
    content: $icon-Ordinal; 
  }
}
.icon-Outage {
  &:before {
    content: $icon-Outage; 
  }
}
.icon-Outlook-severe {
  &:before {
    content: $icon-Outlook-severe; 
  }
}
.icon-Overflow-menu--horizontal {
  &:before {
    content: $icon-Overflow-menu--horizontal; 
  }
}
.icon-Overflow-menu--vertical {
  &:before {
    content: $icon-Overflow-menu--vertical; 
  }
}
.icon-Overlay {
  &:before {
    content: $icon-Overlay; 
  }
}
.icon-Package {
  &:before {
    content: $icon-Package; 
  }
}
.icon-Page-break {
  &:before {
    content: $icon-Page-break; 
  }
}
.icon-Page--first {
  &:before {
    content: $icon-Page--first; 
  }
}
.icon-Page--last {
  &:before {
    content: $icon-Page--last; 
  }
}
.icon-Page-number {
  &:before {
    content: $icon-Page-number; 
  }
}
.icon-Page-scroll {
  &:before {
    content: $icon-Page-scroll; 
  }
}
.icon-Paint-brush {
  &:before {
    content: $icon-Paint-brush; 
  }
}
.icon-Paint-brush--alt {
  &:before {
    content: $icon-Paint-brush--alt; 
  }
}
.icon-Palm-tree {
  &:before {
    content: $icon-Palm-tree; 
  }
}
.icon-Panel-expansion {
  &:before {
    content: $icon-Panel-expansion; 
  }
}
.icon-Pan--horizontal {
  &:before {
    content: $icon-Pan--horizontal; 
  }
}
.icon-Pan--vertical {
  &:before {
    content: $icon-Pan--vertical; 
  }
}
.icon-Paragraph {
  &:before {
    content: $icon-Paragraph; 
  }
}
.icon-Parameter {
  &:before {
    content: $icon-Parameter; 
  }
}
.icon-Parent-child {
  &:before {
    content: $icon-Parent-child; 
  }
}
.icon-Partly-cloudy {
  &:before {
    content: $icon-Partly-cloudy; 
  }
}
.icon-Partly-cloudy--night {
  &:before {
    content: $icon-Partly-cloudy--night; 
  }
}
.icon-Partnership {
  &:before {
    content: $icon-Partnership; 
  }
}
.icon-Passenger--drinks {
  &:before {
    content: $icon-Passenger--drinks; 
  }
}
.icon-Passenger--plus {
  &:before {
    content: $icon-Passenger--plus; 
  }
}
.icon-Password {
  &:before {
    content: $icon-Password; 
  }
}
.icon-Paste {
  &:before {
    content: $icon-Paste; 
  }
}
.icon-Pause {
  &:before {
    content: $icon-Pause; 
  }
}
.icon-Pause--filled {
  &:before {
    content: $icon-Pause--filled; 
  }
}
.icon-Pause-future {
  &:before {
    content: $icon-Pause-future; 
  }
}
.icon-Pause--outline {
  &:before {
    content: $icon-Pause--outline; 
  }
}
.icon-Pause--outline--filled {
  &:before {
    content: $icon-Pause--outline--filled; 
  }
}
.icon-Pause-past {
  &:before {
    content: $icon-Pause-past; 
  }
}
.icon-Pcn--e-node {
  &:before {
    content: $icon-Pcn--e-node; 
  }
}
.icon-Pcn--military {
  &:before {
    content: $icon-Pcn--military; 
  }
}
.icon-Pcn--p-node {
  &:before {
    content: $icon-Pcn--p-node; 
  }
}
.icon-Pcn--z-node {
  &:before {
    content: $icon-Pcn--z-node; 
  }
}
.icon-Pdf {
  &:before {
    content: $icon-Pdf; 
  }
}
.icon-Pdf--reference {
  &:before {
    content: $icon-Pdf--reference; 
  }
}
.icon-Pedestrian {
  &:before {
    content: $icon-Pedestrian; 
  }
}
.icon-Pedestrian-child {
  &:before {
    content: $icon-Pedestrian-child; 
  }
}
.icon-Pedestrian--family {
  &:before {
    content: $icon-Pedestrian--family; 
  }
}
.icon-Pen {
  &:before {
    content: $icon-Pen; 
  }
}
.icon-Pending {
  &:before {
    content: $icon-Pending; 
  }
}
.icon-Pending--filled {
  &:before {
    content: $icon-Pending--filled; 
  }
}
.icon-Pen--fountain {
  &:before {
    content: $icon-Pen--fountain; 
  }
}
.icon-Percentage {
  &:before {
    content: $icon-Percentage; 
  }
}
.icon-Percentage--filled {
  &:before {
    content: $icon-Percentage--filled; 
  }
}
.icon-Person {
  &:before {
    content: $icon-Person; 
  }
}
.icon-Person--favorite {
  &:before {
    content: $icon-Person--favorite; 
  }
}
.icon-Pest {
  &:before {
    content: $icon-Pest; 
  }
}
.icon-Pet-image--b {
  &:before {
    content: $icon-Pet-image--b; 
  }
}
.icon-Pet-image--o {
  &:before {
    content: $icon-Pet-image--o; 
  }
}
.icon-Phone {
  &:before {
    content: $icon-Phone; 
  }
}
.icon-Phone--application {
  &:before {
    content: $icon-Phone--application; 
  }
}
.icon-Phone--block {
  &:before {
    content: $icon-Phone--block; 
  }
}
.icon-Phone--block--filled {
  &:before {
    content: $icon-Phone--block--filled; 
  }
}
.icon-Phone--filled {
  &:before {
    content: $icon-Phone--filled; 
  }
}
.icon-Phone--incoming {
  &:before {
    content: $icon-Phone--incoming; 
  }
}
.icon-Phone--incoming--filled {
  &:before {
    content: $icon-Phone--incoming--filled; 
  }
}
.icon-Phone--ip {
  &:before {
    content: $icon-Phone--ip; 
  }
}
.icon-Phone--off {
  &:before {
    content: $icon-Phone--off; 
  }
}
.icon-Phone--off--filled {
  &:before {
    content: $icon-Phone--off--filled; 
  }
}
.icon-Phone--outgoing {
  &:before {
    content: $icon-Phone--outgoing; 
  }
}
.icon-Phone--outgoing--filled {
  &:before {
    content: $icon-Phone--outgoing--filled; 
  }
}
.icon-Phone--settings {
  &:before {
    content: $icon-Phone--settings; 
  }
}
.icon-Phone--voice {
  &:before {
    content: $icon-Phone--voice; 
  }
}
.icon-Phone--voice--filled {
  &:before {
    content: $icon-Phone--voice--filled; 
  }
}
.icon-Phrase-sentiment {
  &:before {
    content: $icon-Phrase-sentiment; 
  }
}
.icon-Picnic-area {
  &:before {
    content: $icon-Picnic-area; 
  }
}
.icon-Piggy-bank {
  &:before {
    content: $icon-Piggy-bank; 
  }
}
.icon-Piggy-bank--slot {
  &:before {
    content: $icon-Piggy-bank--slot; 
  }
}
.icon-Pills {
  &:before {
    content: $icon-Pills; 
  }
}
.icon-Pills--add {
  &:before {
    content: $icon-Pills--add; 
  }
}
.icon-Pills--subtract {
  &:before {
    content: $icon-Pills--subtract; 
  }
}
.icon-Pin {
  &:before {
    content: $icon-Pin; 
  }
}
.icon-Pin--filled {
  &:before {
    content: $icon-Pin--filled; 
  }
}
.icon-Plane {
  &:before {
    content: $icon-Plane; 
  }
}
.icon-Plane--private {
  &:before {
    content: $icon-Plane--private; 
  }
}
.icon-Plane--sea {
  &:before {
    content: $icon-Plane--sea; 
  }
}
.icon-Play {
  &:before {
    content: $icon-Play; 
  }
}
.icon-Play--filled {
  &:before {
    content: $icon-Play--filled; 
  }
}
.icon-Play--filled--alt {
  &:before {
    content: $icon-Play--filled--alt; 
  }
}
.icon-Playlist {
  &:before {
    content: $icon-Playlist; 
  }
}
.icon-Play--outline {
  &:before {
    content: $icon-Play--outline; 
  }
}
.icon-Play--outline--filled {
  &:before {
    content: $icon-Play--outline--filled; 
  }
}
.icon-Plug {
  &:before {
    content: $icon-Plug; 
  }
}
.icon-Plug--filled {
  &:before {
    content: $icon-Plug--filled; 
  }
}
.icon-Png {
  &:before {
    content: $icon-Png; 
  }
}
.icon-Pointer-text {
  &:before {
    content: $icon-Pointer-text; 
  }
}
.icon-Point-of-presence {
  &:before {
    content: $icon-Point-of-presence; 
  }
}
.icon-Police {
  &:before {
    content: $icon-Police; 
  }
}
.icon-Policy {
  &:before {
    content: $icon-Policy; 
  }
}
.icon-Popup {
  &:before {
    content: $icon-Popup; 
  }
}
.icon-Portfolio {
  &:before {
    content: $icon-Portfolio; 
  }
}
.icon-Power {
  &:before {
    content: $icon-Power; 
  }
}
.icon-Ppt {
  &:before {
    content: $icon-Ppt; 
  }
}
.icon-Presentation-file {
  &:before {
    content: $icon-Presentation-file; 
  }
}
.icon-Pressure {
  &:before {
    content: $icon-Pressure; 
  }
}
.icon-Pressure--filled {
  &:before {
    content: $icon-Pressure--filled; 
  }
}
.icon-Previous--filled {
  &:before {
    content: $icon-Previous--filled; 
  }
}
.icon-Previous--outline {
  &:before {
    content: $icon-Previous--outline; 
  }
}
.icon-Printer {
  &:before {
    content: $icon-Printer; 
  }
}
.icon-Product {
  &:before {
    content: $icon-Product; 
  }
}
.icon-Progress-bar {
  &:before {
    content: $icon-Progress-bar; 
  }
}
.icon-Progress-bar--round {
  &:before {
    content: $icon-Progress-bar--round; 
  }
}
.icon-Purchase {
  &:before {
    content: $icon-Purchase; 
  }
}
.icon-Qq-plot {
  &:before {
    content: $icon-Qq-plot; 
  }
}
.icon-Qr-code {
  &:before {
    content: $icon-Qr-code; 
  }
}
.icon-Quadrant-plot {
  &:before {
    content: $icon-Quadrant-plot; 
  }
}
.icon-Query {
  &:before {
    content: $icon-Query; 
  }
}
.icon-Query-queue {
  &:before {
    content: $icon-Query-queue; 
  }
}
.icon-Queued {
  &:before {
    content: $icon-Queued; 
  }
}
.icon-Quotes {
  &:before {
    content: $icon-Quotes; 
  }
}
.icon-Radar {
  &:before {
    content: $icon-Radar; 
  }
}
.icon-Radar--enhanced {
  &:before {
    content: $icon-Radar--enhanced; 
  }
}
.icon-Radar--weather {
  &:before {
    content: $icon-Radar--weather; 
  }
}
.icon-Radio {
  &:before {
    content: $icon-Radio; 
  }
}
.icon-Radio-button {
  &:before {
    content: $icon-Radio-button; 
  }
}
.icon-Radio-button--checked {
  &:before {
    content: $icon-Radio-button--checked; 
  }
}
.icon-Radio--combat {
  &:before {
    content: $icon-Radio--combat; 
  }
}
.icon-Radio--push-to-talk {
  &:before {
    content: $icon-Radio--push-to-talk; 
  }
}
.icon-Rain {
  &:before {
    content: $icon-Rain; 
  }
}
.icon-Rain--drizzle {
  &:before {
    content: $icon-Rain--drizzle; 
  }
}
.icon-Rain-drop {
  &:before {
    content: $icon-Rain-drop; 
  }
}
.icon-Rain--heavy {
  &:before {
    content: $icon-Rain--heavy; 
  }
}
.icon-Rain--scattered {
  &:before {
    content: $icon-Rain--scattered; 
  }
}
.icon-Rain--scattered--night {
  &:before {
    content: $icon-Rain--scattered--night; 
  }
}
.icon-Raw {
  &:before {
    content: $icon-Raw; 
  }
}
.icon-Receipt {
  &:before {
    content: $icon-Receipt; 
  }
}
.icon-Recently-viewed {
  &:before {
    content: $icon-Recently-viewed; 
  }
}
.icon-Recommend {
  &:before {
    content: $icon-Recommend; 
  }
}
.icon-Recording {
  &:before {
    content: $icon-Recording; 
  }
}
.icon-Recording--filled {
  &:before {
    content: $icon-Recording--filled; 
  }
}
.icon-Recording--filled--alt {
  &:before {
    content: $icon-Recording--filled--alt; 
  }
}
.icon-Redo {
  &:before {
    content: $icon-Redo; 
  }
}
.icon-Ref-evapotranspiration {
  &:before {
    content: $icon-Ref-evapotranspiration; 
  }
}
.icon-Reflect--horizontal {
  &:before {
    content: $icon-Reflect--horizontal; 
  }
}
.icon-Reflect--vertical {
  &:before {
    content: $icon-Reflect--vertical; 
  }
}
.icon-Region-analysis--area {
  &:before {
    content: $icon-Region-analysis--area; 
  }
}
.icon-Region-analysis--volume {
  &:before {
    content: $icon-Region-analysis--volume; 
  }
}
.icon-Registration {
  &:before {
    content: $icon-Registration; 
  }
}
.icon-Reminder {
  &:before {
    content: $icon-Reminder; 
  }
}
.icon-Reminder--medical {
  &:before {
    content: $icon-Reminder--medical; 
  }
}
.icon-Renew {
  &:before {
    content: $icon-Renew; 
  }
}
.icon-Repeat {
  &:before {
    content: $icon-Repeat; 
  }
}
.icon-Repeat--one {
  &:before {
    content: $icon-Repeat--one; 
  }
}
.icon-Replicate {
  &:before {
    content: $icon-Replicate; 
  }
}
.icon-Reply {
  &:before {
    content: $icon-Reply; 
  }
}
.icon-Reply--all {
  &:before {
    content: $icon-Reply--all; 
  }
}
.icon-Report {
  &:before {
    content: $icon-Report; 
  }
}
.icon-Report--data {
  &:before {
    content: $icon-Report--data; 
  }
}
.icon-Request-quote {
  &:before {
    content: $icon-Request-quote; 
  }
}
.icon-Reset {
  &:before {
    content: $icon-Reset; 
  }
}
.icon-Reset--alt {
  &:before {
    content: $icon-Reset--alt; 
  }
}
.icon-Restart {
  &:before {
    content: $icon-Restart; 
  }
}
.icon-Restaurant {
  &:before {
    content: $icon-Restaurant; 
  }
}
.icon-Restaurant--fine {
  &:before {
    content: $icon-Restaurant--fine; 
  }
}
.icon-Result {
  &:before {
    content: $icon-Result; 
  }
}
.icon-Result--new {
  &:before {
    content: $icon-Result--new; 
  }
}
.icon-Result--old {
  &:before {
    content: $icon-Result--old; 
  }
}
.icon-Rewind--5 {
  &:before {
    content: $icon-Rewind--5; 
  }
}
.icon-Rewind--10 {
  &:before {
    content: $icon-Rewind--10; 
  }
}
.icon-Rewind--30 {
  &:before {
    content: $icon-Rewind--30; 
  }
}
.icon-Road {
  &:before {
    content: $icon-Road; 
  }
}
.icon-Roadmap {
  &:before {
    content: $icon-Roadmap; 
  }
}
.icon-Road--weather {
  &:before {
    content: $icon-Road--weather; 
  }
}
.icon-Rocket {
  &:before {
    content: $icon-Rocket; 
  }
}
.icon-Rotate {
  &:before {
    content: $icon-Rotate; 
  }
}
.icon-Rotate--180 {
  &:before {
    content: $icon-Rotate--180; 
  }
}
.icon-Rotate--360 {
  &:before {
    content: $icon-Rotate--360; 
  }
}
.icon-Rotate--clockwise {
  &:before {
    content: $icon-Rotate--clockwise; 
  }
}
.icon-Rotate--clockwise--alt {
  &:before {
    content: $icon-Rotate--clockwise--alt; 
  }
}
.icon-Rotate--clockwise--alt--filled {
  &:before {
    content: $icon-Rotate--clockwise--alt--filled; 
  }
}
.icon-Rotate--clockwise--filled {
  &:before {
    content: $icon-Rotate--clockwise--filled; 
  }
}
.icon-Rotate--counterclockwise {
  &:before {
    content: $icon-Rotate--counterclockwise; 
  }
}
.icon-Rotate--counterclockwise--alt {
  &:before {
    content: $icon-Rotate--counterclockwise--alt; 
  }
}
.icon-Rotate--counterclockwise--alt--filled {
  &:before {
    content: $icon-Rotate--counterclockwise--alt--filled; 
  }
}
.icon-Rotate--counterclockwise--filled {
  &:before {
    content: $icon-Rotate--counterclockwise--filled; 
  }
}
.icon-Router {
  &:before {
    content: $icon-Router; 
  }
}
.icon-Router--voice {
  &:before {
    content: $icon-Router--voice; 
  }
}
.icon-Router--wifi {
  &:before {
    content: $icon-Router--wifi; 
  }
}
.icon-Row {
  &:before {
    content: $icon-Row; 
  }
}
.icon-Row--collapse {
  &:before {
    content: $icon-Row--collapse; 
  }
}
.icon-Row--delete {
  &:before {
    content: $icon-Row--delete; 
  }
}
.icon-Row--expand {
  &:before {
    content: $icon-Row--expand; 
  }
}
.icon-Row--insert {
  &:before {
    content: $icon-Row--insert; 
  }
}
.icon-Rss {
  &:before {
    content: $icon-Rss; 
  }
}
.icon-Rule {
  &:before {
    content: $icon-Rule; 
  }
}
.icon-Rule--cancelled {
  &:before {
    content: $icon-Rule--cancelled; 
  }
}
.icon-Rule--draft {
  &:before {
    content: $icon-Rule--draft; 
  }
}
.icon-Rule--filled {
  &:before {
    content: $icon-Rule--filled; 
  }
}
.icon-Ruler {
  &:before {
    content: $icon-Ruler; 
  }
}
.icon-Ruler--alt {
  &:before {
    content: $icon-Ruler--alt; 
  }
}
.icon-Rule--test {
  &:before {
    content: $icon-Rule--test; 
  }
}
.icon-Run {
  &:before {
    content: $icon-Run; 
  }
}
.icon-S {
  &:before {
    content: $icon-S; 
  }
}
.icon-Sailboat--coastal {
  &:before {
    content: $icon-Sailboat--coastal; 
  }
}
.icon-Sailboat--offshore {
  &:before {
    content: $icon-Sailboat--offshore; 
  }
}
.icon-S--alt {
  &:before {
    content: $icon-S--alt; 
  }
}
.icon-Sankey-diagram {
  &:before {
    content: $icon-Sankey-diagram; 
  }
}
.icon-Sankey-diagram--alt {
  &:before {
    content: $icon-Sankey-diagram--alt; 
  }
}
.icon-Satellite {
  &:before {
    content: $icon-Satellite; 
  }
}
.icon-Satellite--radar {
  &:before {
    content: $icon-Satellite--radar; 
  }
}
.icon-Satellite--weather {
  &:before {
    content: $icon-Satellite--weather; 
  }
}
.icon-Save {
  &:before {
    content: $icon-Save; 
  }
}
.icon-Save--annotation {
  &:before {
    content: $icon-Save--annotation; 
  }
}
.icon-Save--image {
  &:before {
    content: $icon-Save--image; 
  }
}
.icon-Save--model {
  &:before {
    content: $icon-Save--model; 
  }
}
.icon-Save--series {
  &:before {
    content: $icon-Save--series; 
  }
}
.icon-Scale {
  &:before {
    content: $icon-Scale; 
  }
}
.icon-Scales {
  &:before {
    content: $icon-Scales; 
  }
}
.icon-Scales--tipped {
  &:before {
    content: $icon-Scales--tipped; 
  }
}
.icon-Scalpel {
  &:before {
    content: $icon-Scalpel; 
  }
}
.icon-Scalpel--cursor {
  &:before {
    content: $icon-Scalpel--cursor; 
  }
}
.icon-Scalpel--lasso {
  &:before {
    content: $icon-Scalpel--lasso; 
  }
}
.icon-Scalpel--select {
  &:before {
    content: $icon-Scalpel--select; 
  }
}
.icon-Scan {
  &:before {
    content: $icon-Scan; 
  }
}
.icon-Scan--alt {
  &:before {
    content: $icon-Scan--alt; 
  }
}
.icon-Scan--disabled {
  &:before {
    content: $icon-Scan--disabled; 
  }
}
.icon-Scatter-matrix {
  &:before {
    content: $icon-Scatter-matrix; 
  }
}
.icon-Schematics {
  &:before {
    content: $icon-Schematics; 
  }
}
.icon-Scooter {
  &:before {
    content: $icon-Scooter; 
  }
}
.icon-Scooter--front {
  &:before {
    content: $icon-Scooter--front; 
  }
}
.icon-Screen {
  &:before {
    content: $icon-Screen; 
  }
}
.icon-Screen--off {
  &:before {
    content: $icon-Screen--off; 
  }
}
.icon-Script {
  &:before {
    content: $icon-Script; 
  }
}
.icon-Script--reference {
  &:before {
    content: $icon-Script--reference; 
  }
}
.icon-Sdk {
  &:before {
    content: $icon-Sdk; 
  }
}
.icon-Search {
  &:before {
    content: $icon-Search; 
  }
}
.icon-Search--locate {
  &:before {
    content: $icon-Search--locate; 
  }
}
.icon-Security {
  &:before {
    content: $icon-Security; 
  }
}
.icon-Security-services {
  &:before {
    content: $icon-Security-services; 
  }
}
.icon-Select--01 {
  &:before {
    content: $icon-Select--01; 
  }
}
.icon-Select--02 {
  &:before {
    content: $icon-Select--02; 
  }
}
.icon-Select--window {
  &:before {
    content: $icon-Select--window; 
  }
}
.icon-Send {
  &:before {
    content: $icon-Send; 
  }
}
.icon-Send--alt {
  &:before {
    content: $icon-Send--alt; 
  }
}
.icon-Send--alt--filled {
  &:before {
    content: $icon-Send--alt--filled; 
  }
}
.icon-Send--filled {
  &:before {
    content: $icon-Send--filled; 
  }
}
.icon-Send-to-back {
  &:before {
    content: $icon-Send-to-back; 
  }
}
.icon-Server--dns {
  &:before {
    content: $icon-Server--dns; 
  }
}
.icon-Server--proxy {
  &:before {
    content: $icon-Server--proxy; 
  }
}
.icon-Server--time {
  &:before {
    content: $icon-Server--time; 
  }
}
.icon-Service-desk {
  &:before {
    content: $icon-Service-desk; 
  }
}
.icon-Session-border-control {
  &:before {
    content: $icon-Session-border-control; 
  }
}
.icon-Settings {
  &:before {
    content: $icon-Settings; 
  }
}
.icon-Settings--adjust {
  &:before {
    content: $icon-Settings--adjust; 
  }
}
.icon-Settings--check {
  &:before {
    content: $icon-Settings--check; 
  }
}
.icon-Settings--services {
  &:before {
    content: $icon-Settings--services; 
  }
}
.icon-Settings--view {
  &:before {
    content: $icon-Settings--view; 
  }
}
.icon-Shape--except {
  &:before {
    content: $icon-Shape--except; 
  }
}
.icon-Shape--exclude {
  &:before {
    content: $icon-Shape--exclude; 
  }
}
.icon-Shape--intersect {
  &:before {
    content: $icon-Shape--intersect; 
  }
}
.icon-Shape--join {
  &:before {
    content: $icon-Shape--join; 
  }
}
.icon-Shape--unite {
  &:before {
    content: $icon-Shape--unite; 
  }
}
.icon-Share {
  &:before {
    content: $icon-Share; 
  }
}
.icon-Share-knowledge {
  &:before {
    content: $icon-Share-knowledge; 
  }
}
.icon-Shopping--bag {
  &:before {
    content: $icon-Shopping--bag; 
  }
}
.icon-Shopping--cart {
  &:before {
    content: $icon-Shopping--cart; 
  }
}
.icon-Shopping--cart--arrow-down {
  &:before {
    content: $icon-Shopping--cart--arrow-down; 
  }
}
.icon-Shopping--cart--arrow-up {
  &:before {
    content: $icon-Shopping--cart--arrow-up; 
  }
}
.icon-Shopping--cart--clear {
  &:before {
    content: $icon-Shopping--cart--clear; 
  }
}
.icon-Shopping--cart--error {
  &:before {
    content: $icon-Shopping--cart--error; 
  }
}
.icon-Shopping--cart--minus {
  &:before {
    content: $icon-Shopping--cart--minus; 
  }
}
.icon-Shopping--cart--plus {
  &:before {
    content: $icon-Shopping--cart--plus; 
  }
}
.icon-Shopping--catalog {
  &:before {
    content: $icon-Shopping--catalog; 
  }
}
.icon-Show-data--cards {
  &:before {
    content: $icon-Show-data--cards; 
  }
}
.icon-Shrink-screen {
  &:before {
    content: $icon-Shrink-screen; 
  }
}
.icon-Shrink-screen--filled {
  &:before {
    content: $icon-Shrink-screen--filled; 
  }
}
.icon-Shuffle {
  &:before {
    content: $icon-Shuffle; 
  }
}
.icon-Shuttle {
  &:before {
    content: $icon-Shuttle; 
  }
}
.icon-Sight {
  &:before {
    content: $icon-Sight; 
  }
}
.icon-Sigma {
  &:before {
    content: $icon-Sigma; 
  }
}
.icon-Signal-strength {
  &:before {
    content: $icon-Signal-strength; 
  }
}
.icon-Sim-card {
  &:before {
    content: $icon-Sim-card; 
  }
}
.icon-Skill-level {
  &:before {
    content: $icon-Skill-level; 
  }
}
.icon-Skill-level--advanced {
  &:before {
    content: $icon-Skill-level--advanced; 
  }
}
.icon-Skill-level--basic {
  &:before {
    content: $icon-Skill-level--basic; 
  }
}
.icon-Skill-level--intermediate {
  &:before {
    content: $icon-Skill-level--intermediate; 
  }
}
.icon-Skip--back {
  &:before {
    content: $icon-Skip--back; 
  }
}
.icon-Skip--back--filled {
  &:before {
    content: $icon-Skip--back--filled; 
  }
}
.icon-Skip--back--outline {
  &:before {
    content: $icon-Skip--back--outline; 
  }
}
.icon-Skip--back--outline--filled {
  &:before {
    content: $icon-Skip--back--outline--filled; 
  }
}
.icon-Skip--back--outline--solid {
  &:before {
    content: $icon-Skip--back--outline--solid; 
  }
}
.icon-Skip--back--solid--filled {
  &:before {
    content: $icon-Skip--back--solid--filled; 
  }
}
.icon-Skip--forward {
  &:before {
    content: $icon-Skip--forward; 
  }
}
.icon-Skip--forward--filled {
  &:before {
    content: $icon-Skip--forward--filled; 
  }
}
.icon-Skip--forward--outline {
  &:before {
    content: $icon-Skip--forward--outline; 
  }
}
.icon-Skip--forward--outline--filled {
  &:before {
    content: $icon-Skip--forward--outline--filled; 
  }
}
.icon-Skip--forward--outline--solid {
  &:before {
    content: $icon-Skip--forward--outline--solid; 
  }
}
.icon-Skip--forward--solid--filled {
  &:before {
    content: $icon-Skip--forward--solid--filled; 
  }
}
.icon-Sleet {
  &:before {
    content: $icon-Sleet; 
  }
}
.icon-Slisor {
  &:before {
    content: $icon-Slisor; 
  }
}
.icon-Smell {
  &:before {
    content: $icon-Smell; 
  }
}
.icon-Smoke {
  &:before {
    content: $icon-Smoke; 
  }
}
.icon-Smoothing {
  &:before {
    content: $icon-Smoothing; 
  }
}
.icon-Smoothing--cursor {
  &:before {
    content: $icon-Smoothing--cursor; 
  }
}
.icon-Snooze {
  &:before {
    content: $icon-Snooze; 
  }
}
.icon-Snow {
  &:before {
    content: $icon-Snow; 
  }
}
.icon-Snow--blizzard {
  &:before {
    content: $icon-Snow--blizzard; 
  }
}
.icon-Snow-density {
  &:before {
    content: $icon-Snow-density; 
  }
}
.icon-Snowflake {
  &:before {
    content: $icon-Snowflake; 
  }
}
.icon-Snow--heavy {
  &:before {
    content: $icon-Snow--heavy; 
  }
}
.icon-Snow--scattered {
  &:before {
    content: $icon-Snow--scattered; 
  }
}
.icon-Snow--scattered--night {
  &:before {
    content: $icon-Snow--scattered--night; 
  }
}
.icon-Soccer {
  &:before {
    content: $icon-Soccer; 
  }
}
.icon-Soil-moisture {
  &:before {
    content: $icon-Soil-moisture; 
  }
}
.icon-Soil-moisture--field {
  &:before {
    content: $icon-Soil-moisture--field; 
  }
}
.icon-Soil-moisture--global {
  &:before {
    content: $icon-Soil-moisture--global; 
  }
}
.icon-Soil-temperature {
  &:before {
    content: $icon-Soil-temperature; 
  }
}
.icon-Soil-temperature--field {
  &:before {
    content: $icon-Soil-temperature--field; 
  }
}
.icon-Soil-temperature--global {
  &:before {
    content: $icon-Soil-temperature--global; 
  }
}
.icon-Solar-panel {
  &:before {
    content: $icon-Solar-panel; 
  }
}
.icon-Sort--ascending {
  &:before {
    content: $icon-Sort--ascending; 
  }
}
.icon-Sort--descending {
  &:before {
    content: $icon-Sort--descending; 
  }
}
.icon-Spell-check {
  &:before {
    content: $icon-Spell-check; 
  }
}
.icon-Spine-label {
  &:before {
    content: $icon-Spine-label; 
  }
}
.icon-Split {
  &:before {
    content: $icon-Split; 
  }
}
.icon-Split--discard {
  &:before {
    content: $icon-Split--discard; 
  }
}
.icon-Split-screen {
  &:before {
    content: $icon-Split-screen; 
  }
}
.icon-Spray-paint {
  &:before {
    content: $icon-Spray-paint; 
  }
}
.icon-Sprout {
  &:before {
    content: $icon-Sprout; 
  }
}
.icon-Sql {
  &:before {
    content: $icon-Sql; 
  }
}
.icon-Stacked-move {
  &:before {
    content: $icon-Stacked-move; 
  }
}
.icon-Stacked-scrolling--1 {
  &:before {
    content: $icon-Stacked-scrolling--1; 
  }
}
.icon-Stacked-scrolling--2 {
  &:before {
    content: $icon-Stacked-scrolling--2; 
  }
}
.icon-Stack-limitation {
  &:before {
    content: $icon-Stack-limitation; 
  }
}
.icon-Stamp {
  &:before {
    content: $icon-Stamp; 
  }
}
.icon-Star {
  &:before {
    content: $icon-Star; 
  }
}
.icon-Star--filled {
  &:before {
    content: $icon-Star--filled; 
  }
}
.icon-Star--half {
  &:before {
    content: $icon-Star--half; 
  }
}
.icon-Star--review {
  &:before {
    content: $icon-Star--review; 
  }
}
.icon-Stay-inside {
  &:before {
    content: $icon-Stay-inside; 
  }
}
.icon-Stem-leaf-plot {
  &:before {
    content: $icon-Stem-leaf-plot; 
  }
}
.icon-Stethoscope {
  &:before {
    content: $icon-Stethoscope; 
  }
}
.icon-Stop {
  &:before {
    content: $icon-Stop; 
  }
}
.icon-Stop--filled {
  &:before {
    content: $icon-Stop--filled; 
  }
}
.icon-Stop--filled--alt {
  &:before {
    content: $icon-Stop--filled--alt; 
  }
}
.icon-Stop--outline {
  &:before {
    content: $icon-Stop--outline; 
  }
}
.icon-Stop--outline--filled {
  &:before {
    content: $icon-Stop--outline--filled; 
  }
}
.icon-Stop-sign {
  &:before {
    content: $icon-Stop-sign; 
  }
}
.icon-Stop-sign--filled {
  &:before {
    content: $icon-Stop-sign--filled; 
  }
}
.icon-Storage-pool {
  &:before {
    content: $icon-Storage-pool; 
  }
}
.icon-Storage-request {
  &:before {
    content: $icon-Storage-request; 
  }
}
.icon-Store {
  &:before {
    content: $icon-Store; 
  }
}
.icon-Storm-tracker {
  &:before {
    content: $icon-Storm-tracker; 
  }
}
.icon-Strawberry {
  &:before {
    content: $icon-Strawberry; 
  }
}
.icon-Stress-breath-editor {
  &:before {
    content: $icon-Stress-breath-editor; 
  }
}
.icon-String-integer {
  &:before {
    content: $icon-String-integer; 
  }
}
.icon-String-text {
  &:before {
    content: $icon-String-text; 
  }
}
.icon-Study--next {
  &:before {
    content: $icon-Study--next; 
  }
}
.icon-Study--previous {
  &:before {
    content: $icon-Study--previous; 
  }
}
.icon-Study--skip {
  &:before {
    content: $icon-Study--skip; 
  }
}
.icon-Subnet-acl-rules {
  &:before {
    content: $icon-Subnet-acl-rules; 
  }
}
.icon-Subtract {
  &:before {
    content: $icon-Subtract; 
  }
}
.icon-Subtract--alt {
  &:before {
    content: $icon-Subtract--alt; 
  }
}
.icon-Sub-volume {
  &:before {
    content: $icon-Sub-volume; 
  }
}
.icon-Summary--kpi {
  &:before {
    content: $icon-Summary--kpi; 
  }
}
.icon-Sun {
  &:before {
    content: $icon-Sun; 
  }
}
.icon-Sunrise {
  &:before {
    content: $icon-Sunrise; 
  }
}
.icon-Sunset {
  &:before {
    content: $icon-Sunset; 
  }
}
.icon-Svg {
  &:before {
    content: $icon-Svg; 
  }
}
.icon-Swim {
  &:before {
    content: $icon-Swim; 
  }
}
.icon-Switcher {
  &:before {
    content: $icon-Switcher; 
  }
}
.icon-Switch-layer-2 {
  &:before {
    content: $icon-Switch-layer-2; 
  }
}
.icon-Switch-layer-3 {
  &:before {
    content: $icon-Switch-layer-3; 
  }
}
.icon-Sys-provision {
  &:before {
    content: $icon-Sys-provision; 
  }
}
.icon-T {
  &:before {
    content: $icon-T; 
  }
}
.icon-Table {
  &:before {
    content: $icon-Table; 
  }
}
.icon-Table--built {
  &:before {
    content: $icon-Table--built; 
  }
}
.icon-Table-of-contents {
  &:before {
    content: $icon-Table-of-contents; 
  }
}
.icon-Table--split {
  &:before {
    content: $icon-Table--split; 
  }
}
.icon-Tablet {
  &:before {
    content: $icon-Tablet; 
  }
}
.icon-Tablet--landscape {
  &:before {
    content: $icon-Tablet--landscape; 
  }
}
.icon-Tag {
  &:before {
    content: $icon-Tag; 
  }
}
.icon-Tag--edit {
  &:before {
    content: $icon-Tag--edit; 
  }
}
.icon-Tag--group {
  &:before {
    content: $icon-Tag--group; 
  }
}
.icon-T--alt {
  &:before {
    content: $icon-T--alt; 
  }
}
.icon-Tank {
  &:before {
    content: $icon-Tank; 
  }
}
.icon-Task {
  &:before {
    content: $icon-Task; 
  }
}
.icon-Task--add {
  &:before {
    content: $icon-Task--add; 
  }
}
.icon-Task--approved {
  &:before {
    content: $icon-Task--approved; 
  }
}
.icon-Task--asset-view {
  &:before {
    content: $icon-Task--asset-view; 
  }
}
.icon-Task--complete {
  &:before {
    content: $icon-Task--complete; 
  }
}
.icon-Task--location {
  &:before {
    content: $icon-Task--location; 
  }
}
.icon-Task--settings {
  &:before {
    content: $icon-Task--settings; 
  }
}
.icon-Task--star {
  &:before {
    content: $icon-Task--star; 
  }
}
.icon-Task--tools {
  &:before {
    content: $icon-Task--tools; 
  }
}
.icon-Task--view {
  &:before {
    content: $icon-Task--view; 
  }
}
.icon-Taste {
  &:before {
    content: $icon-Taste; 
  }
}
.icon-Taxi {
  &:before {
    content: $icon-Taxi; 
  }
}
.icon-Temperature {
  &:before {
    content: $icon-Temperature; 
  }
}
.icon-Temperature--celsius {
  &:before {
    content: $icon-Temperature--celsius; 
  }
}
.icon-Temperature--celsius--alt {
  &:before {
    content: $icon-Temperature--celsius--alt; 
  }
}
.icon-Temperature--fahrenheit {
  &:before {
    content: $icon-Temperature--fahrenheit; 
  }
}
.icon-Temperature--fahrenheit--alt {
  &:before {
    content: $icon-Temperature--fahrenheit--alt; 
  }
}
.icon-Temperature--feels-like {
  &:before {
    content: $icon-Temperature--feels-like; 
  }
}
.icon-Temperature--frigid {
  &:before {
    content: $icon-Temperature--frigid; 
  }
}
.icon-Temperature--hot {
  &:before {
    content: $icon-Temperature--hot; 
  }
}
.icon-Temperature--inversion {
  &:before {
    content: $icon-Temperature--inversion; 
  }
}
.icon-Temperature--max {
  &:before {
    content: $icon-Temperature--max; 
  }
}
.icon-Temperature--min {
  &:before {
    content: $icon-Temperature--min; 
  }
}
.icon-Temperature--water {
  &:before {
    content: $icon-Temperature--water; 
  }
}
.icon-Template {
  &:before {
    content: $icon-Template; 
  }
}
.icon-Tennis {
  &:before {
    content: $icon-Tennis; 
  }
}
.icon-Tennis-ball {
  &:before {
    content: $icon-Tennis-ball; 
  }
}
.icon-Term {
  &:before {
    content: $icon-Term; 
  }
}
.icon-Terminal {
  &:before {
    content: $icon-Terminal; 
  }
}
.icon-Text--align--center {
  &:before {
    content: $icon-Text--align--center; 
  }
}
.icon-Text--align--justify {
  &:before {
    content: $icon-Text--align--justify; 
  }
}
.icon-Text--align--left {
  &:before {
    content: $icon-Text--align--left; 
  }
}
.icon-Text--align--mixed {
  &:before {
    content: $icon-Text--align--mixed; 
  }
}
.icon-Text--align--right {
  &:before {
    content: $icon-Text--align--right; 
  }
}
.icon-Text--all-caps {
  &:before {
    content: $icon-Text--all-caps; 
  }
}
.icon-Text-annotation-toggle {
  &:before {
    content: $icon-Text-annotation-toggle; 
  }
}
.icon-Text--bold {
  &:before {
    content: $icon-Text--bold; 
  }
}
.icon-Text--clear-format {
  &:before {
    content: $icon-Text--clear-format; 
  }
}
.icon-Text--color {
  &:before {
    content: $icon-Text--color; 
  }
}
.icon-Text--creation {
  &:before {
    content: $icon-Text--creation; 
  }
}
.icon-Text--fill {
  &:before {
    content: $icon-Text--fill; 
  }
}
.icon-Text--font {
  &:before {
    content: $icon-Text--font; 
  }
}
.icon-Text--footnote {
  &:before {
    content: $icon-Text--footnote; 
  }
}
.icon-Text--highlight {
  &:before {
    content: $icon-Text--highlight; 
  }
}
.icon-Text--indent {
  &:before {
    content: $icon-Text--indent; 
  }
}
.icon-Text--indent--less {
  &:before {
    content: $icon-Text--indent--less; 
  }
}
.icon-Text--indent--more {
  &:before {
    content: $icon-Text--indent--more; 
  }
}
.icon-Text--italic {
  &:before {
    content: $icon-Text--italic; 
  }
}
.icon-Text--kerning {
  &:before {
    content: $icon-Text--kerning; 
  }
}
.icon-Text--leading {
  &:before {
    content: $icon-Text--leading; 
  }
}
.icon-Text--line-spacing {
  &:before {
    content: $icon-Text--line-spacing; 
  }
}
.icon-Text-link {
  &:before {
    content: $icon-Text-link; 
  }
}
.icon-Text-link--analysis {
  &:before {
    content: $icon-Text-link--analysis; 
  }
}
.icon-Text-mining {
  &:before {
    content: $icon-Text-mining; 
  }
}
.icon-Text-mining--applier {
  &:before {
    content: $icon-Text-mining--applier; 
  }
}
.icon-Text--new-line {
  &:before {
    content: $icon-Text--new-line; 
  }
}
.icon-Text--scale {
  &:before {
    content: $icon-Text--scale; 
  }
}
.icon-Text--selection {
  &:before {
    content: $icon-Text--selection; 
  }
}
.icon-Text--small-caps {
  &:before {
    content: $icon-Text--small-caps; 
  }
}
.icon-Text--strikethrough {
  &:before {
    content: $icon-Text--strikethrough; 
  }
}
.icon-Text--subscript {
  &:before {
    content: $icon-Text--subscript; 
  }
}
.icon-Text--superscript {
  &:before {
    content: $icon-Text--superscript; 
  }
}
.icon-Text--tracking {
  &:before {
    content: $icon-Text--tracking; 
  }
}
.icon-Text--underline {
  &:before {
    content: $icon-Text--underline; 
  }
}
.icon-Text--vertical-alignment {
  &:before {
    content: $icon-Text--vertical-alignment; 
  }
}
.icon-Text--wrap {
  &:before {
    content: $icon-Text--wrap; 
  }
}
.icon-Theater {
  &:before {
    content: $icon-Theater; 
  }
}
.icon-This-side-up {
  &:before {
    content: $icon-This-side-up; 
  }
}
.icon-Threshold {
  &:before {
    content: $icon-Threshold; 
  }
}
.icon-Thumbnail--1 {
  &:before {
    content: $icon-Thumbnail--1; 
  }
}
.icon-Thumbnail--2 {
  &:before {
    content: $icon-Thumbnail--2; 
  }
}
.icon-Thumbnail-preview {
  &:before {
    content: $icon-Thumbnail-preview; 
  }
}
.icon-Thumbs-down {
  &:before {
    content: $icon-Thumbs-down; 
  }
}
.icon-Thumbs-down--filled {
  &:before {
    content: $icon-Thumbs-down--filled; 
  }
}
.icon-Thumbs-up {
  &:before {
    content: $icon-Thumbs-up; 
  }
}
.icon-Thumbs-up--filled {
  &:before {
    content: $icon-Thumbs-up--filled; 
  }
}
.icon-Thunderstorm {
  &:before {
    content: $icon-Thunderstorm; 
  }
}
.icon-Thunderstorm--scattered {
  &:before {
    content: $icon-Thunderstorm--scattered; 
  }
}
.icon-Thunderstorm--scattered--night {
  &:before {
    content: $icon-Thunderstorm--scattered--night; 
  }
}
.icon-Thunderstorm--severe {
  &:before {
    content: $icon-Thunderstorm--severe; 
  }
}
.icon-Thunderstorm--strong {
  &:before {
    content: $icon-Thunderstorm--strong; 
  }
}
.icon-Ticket {
  &:before {
    content: $icon-Ticket; 
  }
}
.icon-Tides {
  &:before {
    content: $icon-Tides; 
  }
}
.icon-Tif {
  &:before {
    content: $icon-Tif; 
  }
}
.icon-Time {
  &:before {
    content: $icon-Time; 
  }
}
.icon-Time-plot {
  &:before {
    content: $icon-Time-plot; 
  }
}
.icon-Timer {
  &:before {
    content: $icon-Timer; 
  }
}
.icon-Tool-box {
  &:before {
    content: $icon-Tool-box; 
  }
}
.icon-Tool-kit {
  &:before {
    content: $icon-Tool-kit; 
  }
}
.icon-Tools {
  &:before {
    content: $icon-Tools; 
  }
}
.icon-Tools--alt {
  &:before {
    content: $icon-Tools--alt; 
  }
}
.icon-Tornado {
  &:before {
    content: $icon-Tornado; 
  }
}
.icon-Tornado-warning {
  &:before {
    content: $icon-Tornado-warning; 
  }
}
.icon-Touch--1 {
  &:before {
    content: $icon-Touch--1; 
  }
}
.icon-Touch--1-down {
  &:before {
    content: $icon-Touch--1-down; 
  }
}
.icon-Touch--1-down--filled {
  &:before {
    content: $icon-Touch--1-down--filled; 
  }
}
.icon-Touch--1--filled {
  &:before {
    content: $icon-Touch--1--filled; 
  }
}
.icon-Touch--2 {
  &:before {
    content: $icon-Touch--2; 
  }
}
.icon-Touch--2--filled {
  &:before {
    content: $icon-Touch--2--filled; 
  }
}
.icon-Touch--interaction {
  &:before {
    content: $icon-Touch--interaction; 
  }
}
.icon-Traffic-cone {
  &:before {
    content: $icon-Traffic-cone; 
  }
}
.icon-Traffic--event {
  &:before {
    content: $icon-Traffic--event; 
  }
}
.icon-Traffic--flow {
  &:before {
    content: $icon-Traffic--flow; 
  }
}
.icon-Traffic--flow-incident {
  &:before {
    content: $icon-Traffic--flow-incident; 
  }
}
.icon-Traffic--incident {
  &:before {
    content: $icon-Traffic--incident; 
  }
}
.icon-Traffic--weather-incident {
  &:before {
    content: $icon-Traffic--weather-incident; 
  }
}
.icon-Train {
  &:before {
    content: $icon-Train; 
  }
}
.icon-Train--heart {
  &:before {
    content: $icon-Train--heart; 
  }
}
.icon-Train--profile {
  &:before {
    content: $icon-Train--profile; 
  }
}
.icon-Train--speed {
  &:before {
    content: $icon-Train--speed; 
  }
}
.icon-Train--ticket {
  &:before {
    content: $icon-Train--ticket; 
  }
}
.icon-Train--time {
  &:before {
    content: $icon-Train--time; 
  }
}
.icon-Tram {
  &:before {
    content: $icon-Tram; 
  }
}
.icon-Transgender {
  &:before {
    content: $icon-Transgender; 
  }
}
.icon-Translate {
  &:before {
    content: $icon-Translate; 
  }
}
.icon-Transmission-lte {
  &:before {
    content: $icon-Transmission-lte; 
  }
}
.icon-Transpose {
  &:before {
    content: $icon-Transpose; 
  }
}
.icon-Trash-can {
  &:before {
    content: $icon-Trash-can; 
  }
}
.icon-Tree {
  &:before {
    content: $icon-Tree; 
  }
}
.icon-Tree-view {
  &:before {
    content: $icon-Tree-view; 
  }
}
.icon-Tree-view--alt {
  &:before {
    content: $icon-Tree-view--alt; 
  }
}
.icon-Trophy {
  &:before {
    content: $icon-Trophy; 
  }
}
.icon-Trophy--filled {
  &:before {
    content: $icon-Trophy--filled; 
  }
}
.icon-Tropical-storm {
  &:before {
    content: $icon-Tropical-storm; 
  }
}
.icon-Tropical-storm--model-tracks {
  &:before {
    content: $icon-Tropical-storm--model-tracks; 
  }
}
.icon-Tropical-storm--tracks {
  &:before {
    content: $icon-Tropical-storm--tracks; 
  }
}
.icon-Tropical-warning {
  &:before {
    content: $icon-Tropical-warning; 
  }
}
.icon-Tsunami {
  &:before {
    content: $icon-Tsunami; 
  }
}
.icon-Tsv {
  &:before {
    content: $icon-Tsv; 
  }
}
.icon-Two-factor-authentication {
  &:before {
    content: $icon-Two-factor-authentication; 
  }
}
.icon-Two-person-lift {
  &:before {
    content: $icon-Two-person-lift; 
  }
}
.icon-Txt {
  &:before {
    content: $icon-Txt; 
  }
}
.icon-Txt--reference {
  &:before {
    content: $icon-Txt--reference; 
  }
}
.icon-Type-pattern {
  &:before {
    content: $icon-Type-pattern; 
  }
}
.icon-Types {
  &:before {
    content: $icon-Types; 
  }
}
.icon-U1 {
  &:before {
    content: $icon-U1; 
  }
}
.icon-U2 {
  &:before {
    content: $icon-U2; 
  }
}
.icon-U3 {
  &:before {
    content: $icon-U3; 
  }
}
.icon-Umbrella {
  &:before {
    content: $icon-Umbrella; 
  }
}
.icon-Undefined {
  &:before {
    content: $icon-Undefined; 
  }
}
.icon-Undefined--filled {
  &:before {
    content: $icon-Undefined--filled; 
  }
}
.icon-Undo {
  &:before {
    content: $icon-Undo; 
  }
}
.icon-Ungroup-objects {
  &:before {
    content: $icon-Ungroup-objects; 
  }
}
.icon-Unknown {
  &:before {
    content: $icon-Unknown; 
  }
}
.icon-Unknown--filled {
  &:before {
    content: $icon-Unknown--filled; 
  }
}
.icon-Unlink {
  &:before {
    content: $icon-Unlink; 
  }
}
.icon-Unlocked {
  &:before {
    content: $icon-Unlocked; 
  }
}
.icon-Update-now {
  &:before {
    content: $icon-Update-now; 
  }
}
.icon-Upgrade {
  &:before {
    content: $icon-Upgrade; 
  }
}
.icon-Upload {
  &:before {
    content: $icon-Upload; 
  }
}
.icon-Up-to-top {
  &:before {
    content: $icon-Up-to-top; 
  }
}
.icon-Usb {
  &:before {
    content: $icon-Usb; 
  }
}
.icon-User {
  &:before {
    content: $icon-User; 
  }
}
.icon-User--access {
  &:before {
    content: $icon-User--access; 
  }
}
.icon-User--activity {
  &:before {
    content: $icon-User--activity; 
  }
}
.icon-User--admin {
  &:before {
    content: $icon-User--admin; 
  }
}
.icon-User--avatar {
  &:before {
    content: $icon-User--avatar; 
  }
}
.icon-User--avatar--filled {
  &:before {
    content: $icon-User--avatar--filled; 
  }
}
.icon-User--avatar--filled--alt {
  &:before {
    content: $icon-User--avatar--filled--alt; 
  }
}
.icon-User--certification {
  &:before {
    content: $icon-User--certification; 
  }
}
.icon-User--data {
  &:before {
    content: $icon-User--data; 
  }
}
.icon-User--favorite {
  &:before {
    content: $icon-User--favorite; 
  }
}
.icon-User--favorite--alt {
  &:before {
    content: $icon-User--favorite--alt; 
  }
}
.icon-User--favorite--alt--filled {
  &:before {
    content: $icon-User--favorite--alt--filled; 
  }
}
.icon-User--filled {
  &:before {
    content: $icon-User--filled; 
  }
}
.icon-User--follow {
  &:before {
    content: $icon-User--follow; 
  }
}
.icon-User--identification {
  &:before {
    content: $icon-User--identification; 
  }
}
.icon-User--military {
  &:before {
    content: $icon-User--military; 
  }
}
.icon-User--multiple {
  &:before {
    content: $icon-User--multiple; 
  }
}
.icon-User--online {
  &:before {
    content: $icon-User--online; 
  }
}
.icon-User--profile {
  &:before {
    content: $icon-User--profile; 
  }
}
.icon-User-profile--alt {
  &:before {
    content: $icon-User-profile--alt; 
  }
}
.icon-User--role {
  &:before {
    content: $icon-User--role; 
  }
}
.icon-User--service-desk {
  &:before {
    content: $icon-User--service-desk; 
  }
}
.icon-User--settings {
  &:before {
    content: $icon-User--settings; 
  }
}
.icon-User--simulation {
  &:before {
    content: $icon-User--simulation; 
  }
}
.icon-User--speaker {
  &:before {
    content: $icon-User--speaker; 
  }
}
.icon-User--x-ray {
  &:before {
    content: $icon-User--x-ray; 
  }
}
.icon-Uv-index {
  &:before {
    content: $icon-Uv-index; 
  }
}
.icon-Uv-index--alt {
  &:before {
    content: $icon-Uv-index--alt; 
  }
}
.icon-Uv-index--filled {
  &:before {
    content: $icon-Uv-index--filled; 
  }
}
.icon-Van {
  &:before {
    content: $icon-Van; 
  }
}
.icon-Vehicle--api {
  &:before {
    content: $icon-Vehicle--api; 
  }
}
.icon-Vehicle--connected {
  &:before {
    content: $icon-Vehicle--connected; 
  }
}
.icon-Vehicle--insights {
  &:before {
    content: $icon-Vehicle--insights; 
  }
}
.icon-Vehicle--services {
  &:before {
    content: $icon-Vehicle--services; 
  }
}
.icon-Version {
  &:before {
    content: $icon-Version; 
  }
}
.icon-Video {
  &:before {
    content: $icon-Video; 
  }
}
.icon-Video--add {
  &:before {
    content: $icon-Video--add; 
  }
}
.icon-Video--chat {
  &:before {
    content: $icon-Video--chat; 
  }
}
.icon-Video--filled {
  &:before {
    content: $icon-Video--filled; 
  }
}
.icon-Video--off {
  &:before {
    content: $icon-Video--off; 
  }
}
.icon-Video--off--filled {
  &:before {
    content: $icon-Video--off--filled; 
  }
}
.icon-View {
  &:before {
    content: $icon-View; 
  }
}
.icon-View--filled {
  &:before {
    content: $icon-View--filled; 
  }
}
.icon-View--mode-1 {
  &:before {
    content: $icon-View--mode-1; 
  }
}
.icon-View--mode-2 {
  &:before {
    content: $icon-View--mode-2; 
  }
}
.icon-View-next {
  &:before {
    content: $icon-View-next; 
  }
}
.icon-View--off {
  &:before {
    content: $icon-View--off; 
  }
}
.icon-View--off--filled {
  &:before {
    content: $icon-View--off--filled; 
  }
}
.icon-Virtual-column {
  &:before {
    content: $icon-Virtual-column; 
  }
}
.icon-Virtual-column--key {
  &:before {
    content: $icon-Virtual-column--key; 
  }
}
.icon-Virtual-desktop {
  &:before {
    content: $icon-Virtual-desktop; 
  }
}
.icon-Virtual-machine {
  &:before {
    content: $icon-Virtual-machine; 
  }
}
.icon-Virtual-private-cloud {
  &:before {
    content: $icon-Virtual-private-cloud; 
  }
}
.icon-Virtual-private-cloud--alt {
  &:before {
    content: $icon-Virtual-private-cloud--alt; 
  }
}
.icon-Visual-recognition {
  &:before {
    content: $icon-Visual-recognition; 
  }
}
.icon-Vlan {
  &:before {
    content: $icon-Vlan; 
  }
}
.icon-Vlan--IBM {
  &:before {
    content: $icon-Vlan--IBM; 
  }
}
.icon-Vmdk-disk {
  &:before {
    content: $icon-Vmdk-disk; 
  }
}
.icon-Voice-activate {
  &:before {
    content: $icon-Voice-activate; 
  }
}
.icon-Voicemail {
  &:before {
    content: $icon-Voicemail; 
  }
}
.icon-Volume--block-storage {
  &:before {
    content: $icon-Volume--block-storage; 
  }
}
.icon-Volume--down {
  &:before {
    content: $icon-Volume--down; 
  }
}
.icon-Volume--down--alt {
  &:before {
    content: $icon-Volume--down--alt; 
  }
}
.icon-Volume--down--filled {
  &:before {
    content: $icon-Volume--down--filled; 
  }
}
.icon-Volume--down--filled--alt {
  &:before {
    content: $icon-Volume--down--filled--alt; 
  }
}
.icon-Volume--file-storage {
  &:before {
    content: $icon-Volume--file-storage; 
  }
}
.icon-Volume--mute {
  &:before {
    content: $icon-Volume--mute; 
  }
}
.icon-Volume--mute--filled {
  &:before {
    content: $icon-Volume--mute--filled; 
  }
}
.icon-Volume--object-storage {
  &:before {
    content: $icon-Volume--object-storage; 
  }
}
.icon-Volume--up {
  &:before {
    content: $icon-Volume--up; 
  }
}
.icon-Volume--up--alt {
  &:before {
    content: $icon-Volume--up--alt; 
  }
}
.icon-Volume--up--filled {
  &:before {
    content: $icon-Volume--up--filled; 
  }
}
.icon-Volume--up--filled--alt {
  &:before {
    content: $icon-Volume--up--filled--alt; 
  }
}
.icon-Vpn {
  &:before {
    content: $icon-Vpn; 
  }
}
.icon-Vpn--connection {
  &:before {
    content: $icon-Vpn--connection; 
  }
}
.icon-Vpn--policy {
  &:before {
    content: $icon-Vpn--policy; 
  }
}
.icon-Wallet {
  &:before {
    content: $icon-Wallet; 
  }
}
.icon-Warning {
  &:before {
    content: $icon-Warning; 
  }
}
.icon-Warning--alt {
  &:before {
    content: $icon-Warning--alt; 
  }
}
.icon-Warning--alt--filled {
  &:before {
    content: $icon-Warning--alt--filled; 
  }
}
.icon-Warning--alt-inverted {
  &:before {
    content: $icon-Warning--alt-inverted; 
  }
}
.icon-Warning--alt-inverted--filled {
  &:before {
    content: $icon-Warning--alt-inverted--filled; 
  }
}
.icon-Warning--filled {
  &:before {
    content: $icon-Warning--filled; 
  }
}
.icon-Warning--hex {
  &:before {
    content: $icon-Warning--hex; 
  }
}
.icon-Warning--hex--filled {
  &:before {
    content: $icon-Warning--hex--filled; 
  }
}
.icon-Warning--other {
  &:before {
    content: $icon-Warning--other; 
  }
}
.icon-Warning-square {
  &:before {
    content: $icon-Warning-square; 
  }
}
.icon-Warning-square--filled {
  &:before {
    content: $icon-Warning-square--filled; 
  }
}
.icon-Watch {
  &:before {
    content: $icon-Watch; 
  }
}
.icon-Watson {
  &:before {
    content: $icon-Watson; 
  }
}
.icon-Watson--machine-learning {
  &:before {
    content: $icon-Watson--machine-learning; 
  }
}
.icon-Wave-direction {
  &:before {
    content: $icon-Wave-direction; 
  }
}
.icon-Wave-height {
  &:before {
    content: $icon-Wave-height; 
  }
}
.icon-Wave-period {
  &:before {
    content: $icon-Wave-period; 
  }
}
.icon-Weather-front--cold {
  &:before {
    content: $icon-Weather-front--cold; 
  }
}
.icon-Weather-front--stationary {
  &:before {
    content: $icon-Weather-front--stationary; 
  }
}
.icon-Weather-front--warm {
  &:before {
    content: $icon-Weather-front--warm; 
  }
}
.icon-Weather-station {
  &:before {
    content: $icon-Weather-station; 
  }
}
.icon-Websheet {
  &:before {
    content: $icon-Websheet; 
  }
}
.icon-Wheat {
  &:before {
    content: $icon-Wheat; 
  }
}
.icon-Wifi {
  &:before {
    content: $icon-Wifi; 
  }
}
.icon-Wifi-bridge {
  &:before {
    content: $icon-Wifi-bridge; 
  }
}
.icon-Wifi-bridge--alt {
  &:before {
    content: $icon-Wifi-bridge--alt; 
  }
}
.icon-Wifi--controller {
  &:before {
    content: $icon-Wifi--controller; 
  }
}
.icon-Wifi--not-secure {
  &:before {
    content: $icon-Wifi--not-secure; 
  }
}
.icon-Wifi--off {
  &:before {
    content: $icon-Wifi--off; 
  }
}
.icon-Wifi--secure {
  &:before {
    content: $icon-Wifi--secure; 
  }
}
.icon-Wikis {
  &:before {
    content: $icon-Wikis; 
  }
}
.icon-Wind-gusts {
  &:before {
    content: $icon-Wind-gusts; 
  }
}
.icon-Window--auto {
  &:before {
    content: $icon-Window--auto; 
  }
}
.icon-Window--base {
  &:before {
    content: $icon-Window--base; 
  }
}
.icon-Window--black-saturation {
  &:before {
    content: $icon-Window--black-saturation; 
  }
}
.icon-Window--overlay {
  &:before {
    content: $icon-Window--overlay; 
  }
}
.icon-Window--preset {
  &:before {
    content: $icon-Window--preset; 
  }
}
.icon-Wind-power {
  &:before {
    content: $icon-Wind-power; 
  }
}
.icon-Wind-stream {
  &:before {
    content: $icon-Wind-stream; 
  }
}
.icon-Windy {
  &:before {
    content: $icon-Windy; 
  }
}
.icon-Windy--dust {
  &:before {
    content: $icon-Windy--dust; 
  }
}
.icon-Windy--snow {
  &:before {
    content: $icon-Windy--snow; 
  }
}
.icon-Windy--strong {
  &:before {
    content: $icon-Windy--strong; 
  }
}
.icon-Winter-warning {
  &:before {
    content: $icon-Winter-warning; 
  }
}
.icon-Wintry-mix {
  &:before {
    content: $icon-Wintry-mix; 
  }
}
.icon-Wireless-checkout {
  &:before {
    content: $icon-Wireless-checkout; 
  }
}
.icon-Wmv {
  &:before {
    content: $icon-Wmv; 
  }
}
.icon-Word-cloud {
  &:before {
    content: $icon-Word-cloud; 
  }
}
.icon-Workspace {
  &:before {
    content: $icon-Workspace; 
  }
}
.icon-Workspace--import {
  &:before {
    content: $icon-Workspace--import; 
  }
}
.icon-Worship {
  &:before {
    content: $icon-Worship; 
  }
}
.icon-Worship--christian {
  &:before {
    content: $icon-Worship--christian; 
  }
}
.icon-Worship--jewish {
  &:before {
    content: $icon-Worship--jewish; 
  }
}
.icon-Worship--muslim {
  &:before {
    content: $icon-Worship--muslim; 
  }
}
.icon-X {
  &:before {
    content: $icon-X; 
  }
}
.icon-X-axis {
  &:before {
    content: $icon-X-axis; 
  }
}
.icon-Xls {
  &:before {
    content: $icon-Xls; 
  }
}
.icon-Xml {
  &:before {
    content: $icon-Xml; 
  }
}
.icon-Y {
  &:before {
    content: $icon-Y; 
  }
}
.icon-Y-axis {
  &:before {
    content: $icon-Y-axis; 
  }
}
.icon-Z {
  &:before {
    content: $icon-Z; 
  }
}
.icon-Z-axis {
  &:before {
    content: $icon-Z-axis; 
  }
}
.icon-Zip {
  &:before {
    content: $icon-Zip; 
  }
}
.icon-Zip--reference {
  &:before {
    content: $icon-Zip--reference; 
  }
}
.icon-Zoom--area {
  &:before {
    content: $icon-Zoom--area; 
  }
}
.icon-Zoom--fit {
  &:before {
    content: $icon-Zoom--fit; 
  }
}
.icon-Zoom--in {
  &:before {
    content: $icon-Zoom--in; 
  }
}
.icon-Zoom--in-area {
  &:before {
    content: $icon-Zoom--in-area; 
  }
}
.icon-Zoom--out {
  &:before {
    content: $icon-Zoom--out; 
  }
}
.icon-Zoom--out-area {
  &:before {
    content: $icon-Zoom--out-area; 
  }
}
.icon-Zoom-pan {
  &:before {
    content: $icon-Zoom-pan; 
  }
}
.icon-Zoom--reset {
  &:before {
    content: $icon-Zoom--reset; 
  }
}

