// COLORS
// .bg-{color_name}-{variant}
// .color-{color_name}-{variant}

$color-variants: (
  "blue-50": #eaecf9,
  "blue-100": #bdc4ed,
  "blue-200": #9da8e4,
  "blue-300": #7180d7,
  "blue-400": #5568d0,
  "blue-500": #2b42c4,
  "blue-600": #273cb2,
  "blue-700": #1f2f8b,
  "blue-800": #18246c,
  "blue-900": #121c52,
  "yellow-50": #fff8ed,
  "yellow-100": #ffe8c7,
  "yellow-200": #ffddab,
  "yellow-300": #fecd85,
  "yellow-400": #fec36d,
  "yellow-500": #feb449,
  "yellow-600": #e7a442,
  "yellow-700": #b48034,
  "yellow-800": #8c6328,
  "yellow-900": #6b4c1f,
  "red-50": #fcece9,
  "red-100": #f5c4b9,
  "red-200": #f0a898,
  "red-300": #e98068,
  "red-400": #e5684b,
  "red-500": #de421e,
  "red-600": #ca3c1b,
  "red-700": #9e2f15,
  "red-800": #7a2411,
  "red-900": #5d1c0d,
  "green-50": #f2f9f7,
  "green-100": #d5ece5,
  "green-200": #c1e3d8,
  "green-300": #a5d7c6,
  "green-400": #94cfbb,
  "green-500": #79c3aa,
  "green-600": #6eb19b,
  "green-700": #568a79,
  "green-800": #436b5e,
  "green-900": #335247,
  "neutrals-white": #ffffff,
  "neutrals-lighter": #e8e8e8,
  "neutrals-light": #d1d1d1,
  "neutrals-semilight": #d9d9d9,
  "neutrals-neutral": #8c8c8c,
  "neutrals-dark": #5e5e5e,
  "neutrals-darker": #303030,
  "neutrals-black": #1a1a1a,
  "bg-light-grey": #f6f9fe,
  "bg-neutral-grey": #eceef7,
);

@mixin generate-color-classes($color-variants) {
  .color {
    @each $name, $color in $color-variants {
      &-#{$name} {
        color: $color !important;
      }
    }
  }
  .bg {
    @each $name, $color in $color-variants {
      &-#{$name} {
        background-color: $color !important;
      }
    }
  }
}

:root{
  @each $name, $color in $color-variants {
    --#{$name}: #{$color};
  }
}

@include generate-color-classes($color-variants);
