.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    padding: 16px;
    color: var(--text-color);

    // VARIANTS

    &.is_solid {
        background-color: var(--main-color);

        &.is_disabled {
            background-color: map-get($color-variants, "green-500");
            opacity: .5;
        }
    }

    &.is_outlined {
        border: 1px solid var(--main-color);

        &.is_disabled {
            background-color: hex-to-rgba(map-get($color-variants, "neutrals-lighter"), .5);
        }
    }

    &.is_link {
        padding: 0;
        margin: 0;

        &.is_disabled {
            opacity: .7;
        }
    }
    
}

.button_icon{
    border-radius: 100%;
    background-color: var(--neutrals-white);
    padding: 20px;
}